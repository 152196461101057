import { TextField, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

export const useTextfieldStyles = makeStyles((theme) => ({
  root: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
    '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'black',
      borderWidth: '2px',
    },
  },
  input: {
    borderRadius: 0,
  },
}));

export function Textfield({ className, InputProps, InputLabelProps, ...props }: TextFieldProps) {
  const classes = useTextfieldStyles();

  return (
    <TextField
      className={clsx([classes.root, className])}
      InputProps={{ className: clsx([classes.input, InputProps?.className]), ...InputProps }}
      // have to inline the styles here for MUI to respect them
      // className selectors aren't enough to override the styles
      InputLabelProps={{ style: { color: 'black' }, ...InputLabelProps }}
      variant='outlined'
      margin='normal'
      color='primary'
      fullWidth
      {...props}
    />
  );
}
