import React, { useEffect, useReducer } from 'react';

import type { ComponentMetaData } from './descriptions';
import { isReactor, parseComponentType, removeIds } from './reactor';
import { META, appendPrototype, isFormula, isMethod } from './reactorObject';
import { isObjectNotArray, isObjectOrArray } from './util';
import type {
  ChangeListener,
  ComponentDescription,
  IDisposable,
  Point,
  Project,
  Properties,
  Reactor,
  ReactorObject,
} from '.';

// Use Symbols to hide private Component properties we don't want to confuse anyone with.
export const DESCRIPTION = Symbol('DESCRIPTION');
export const FORCEUPDATE = Symbol('FORCEUPDATE');

// These props are expected by all Components' React renderers.
// TODO: these aren't optional properties. Get Typescript under control.
export type ComponentProps = {
  type?: string;
  component?: Component;
};

export type Component = ReactorObject & {
  // TODO: these become reserved property names
  componentType: string;
  _meta?: ComponentMetaData;
  project: Project & { log(...args: any[]): void };
  _element?: HTMLElement; // TODO: Too tightly bound to HTML?
  parent?: Component;
  components?: Component[];
  componentDesignMode?: boolean;
  _componentDesignModeInteractionPoint?: Point;
  primaryElement: HTMLElement | null;
  secondaryElement: HTMLElement | null;

  _metaListeners?: IDisposable[];

  [DESCRIPTION]: ComponentDescription;
  [META]: ComponentMetaData & ReactorObject;
  [FORCEUPDATE]?(): void;

  // init context:
  // - called before mount
  // - called before parent's init
  // - called after local state is set
  // - no initialization order guarantees WRT to siblings
  // Should only reference its own state.
  init(): void;
};

export function isComponent(obj: any): obj is Component {
  return DESCRIPTION in obj;
}

export function getDefaultMetaData(): ComponentMetaData {
  return {
    exported: false,
    commands: {},
    events: {},
    properties: {},
  };
}

// Attach listeners to detect metadata changes
function attachMetaListeners(
  target: Component,
  source: Component | ComponentDescription
): IDisposable[] {
  const ret: IDisposable[] = [];

  if (!isReactor(source)) {
    return ret;
  }

  // Watch for a change to source._meta
  ret.push(
    source.onPropertyChange((reactor, property, newValue, oldValue, type) => {
      property = property as string;

      if (property === '$_meta') {
        // Defer promoteToComponent to the next tick to avoid recursing since property changes are emitted immediately.
        setTimeout(() => promoteToComponent(target, target.project));
      }
    })
  );

  const meta = source.$_meta;
  if (!meta) {
    return ret;
  }

  const handler: ChangeListener = (reactor, property, newValue, oldValue, type) => {
    // We're only concerned about evaluated prop changes
    if ((property as string)[0] === '$') {
      return;
    }

    // Defer promoteToComponent to the next tick to avoid recursing since property changes are emitted immediately.
    setTimeout(() => promoteToComponent(target, target.project));
  };

  // Watch for changes to source._meta.<property>
  ret.push(meta.onPropertyChange(handler));

  // Watch for changes to  source._meta.<property>.<sub> if <property> is a reactor
  for (const key of Object.keys(meta)) {
    const metaField = (meta as any)[key];
    if (isReactor(metaField)) {
      ret.push((meta[key] as ReactorObject).onPropertyChange(handler));
    }
  }
  return ret;
}

// Attach listeners to detect property changes
function attachPropertyListeners(
  target: Component,
  source: Component | ComponentDescription
): IDisposable[] {
  const ret: IDisposable[] = [];

  if (isReactor(source)) {
    ret.push(
      // Handle changes to the source component
      source.onPropertyChange((reactor, property, newValue, oldValue, type) => {
        inheritProperties(target, source);
      })
    );
  }

  ret.push(
    // Handle cases where the target component has a prop set to undefined
    target.onPropertyChange((reactor, property, newValue, oldValue, type) => {
      // Only trigger when a prop is being cleared
      if (newValue === undefined) {
        inheritProperties(target, source);
      }
    })
  );

  return ret;
}

export function promoteToComponent(reactor: Reactor, project: Project): void {
  const componentType = reactor.$componentType;
  const component = (reactor as unknown) as Component;

  // When processing $_meta, $componentType triggers calls to promoteToComponent
  // Remove componentType from $_meta.properties
  if (typeof componentType !== 'string') {
    return;
  }

  //console.log(`promoteToComponent: ${reactor.name} ${componentType}`);
  //console.log('  component:', component);

  const master = resolveComponentType(componentType, project);
  if (!master) {
    console.warn(`No master for componentType ${componentType}`);
    return;
  }

  //console.log('  master:', master);

  // Attach the ComponentDescription to the Reactor -- now it is a Component!
  const description = getComponentDescription(master, project);
  if (!description) {
    console.warn(`No ComponentDescription for componentType ${componentType}`);
    return;
  }
  component[DESCRIPTION] = description;
  //console.log('  description:', description);

  // Build up META
  const meta = getDefaultMetaData();
  extendComponentMetadata(meta, component.$_meta);
  extendComponentMetadata(meta, master._meta);
  extendComponentMetadata(meta, description._meta);
  removeIds(meta);

  // Create META *once* an re-use on other invocations
  if (!component[META]) {
    component[META] = project.createReactor({}) as ComponentMetaData & ReactorObject;
  }

  // Update META
  Object.assign(component[META], meta);
  //console.log('  META: ', component[META]);

  if (component._metaListeners) {
    component._metaListeners.forEach((o) => o.dispose());
  }
  component._metaListeners = [
    ...attachMetaListeners(component, component),
    ...attachMetaListeners(component, master),
    ...attachPropertyListeners(component, master),
  ];

  inheritProperties(component, master);
}

// Return a ComponentDescription for a given component
function getComponentDescription(
  component: Component | ComponentDescription,
  project: Project
): ComponentDescription | undefined {
  while (isReactor(component)) {
    if ((component as Component).componentType === 'Play Kit/CompositeMaster') {
      // Composite component instances get their *description* from Composite instead of
      // CompositeMaster (which is what their componentType points to)
      return resolveComponentType('Play Kit/Composite', project) as ComponentDescription;
    } else if (isComponent(component)) {
      // the component is already a Component, so just use its ComponentDescription
      return component[DESCRIPTION];
    } else {
      // Walk up the inheritance chain
      component = resolveComponentType((component as Component).componentType, project)!;
      if (!component) {
        return undefined;
      }
    }
  }
  // the component is already JS ComponentDescription
  return component as ComponentDescription;
}

// Merge inherited properties from a master (optional) and per-property defaults into a component
export function inheritProperties(
  component: Component,
  master: Component | ComponentDescription
): void {
  const defProps: Properties = {};
  const meta = component[META];
  for (const property in meta.properties) {
    defProps[property] = (master as any)[property] ?? meta.properties[property].default;
  }

  // Apply the defaults
  for (const property in defProps) {
    if (component['$' + property] === undefined) {
      let value = defProps[property];
      // Turn objects and arrays into Reactors so they'll have e.g. onPropertyChange.
      if (isObjectOrArray(value)) {
        value = component.project.createReactor(value);
      }
      component[property] = value;
    }
  }

  // HACK: override formulas and methods
  if (isReactor(master)) {
    for (const property in (master as ReactorObject).getOwnExpressions()) {
      const val = (master as ReactorObject)[property];
      if (isMethod(val) || isFormula(val)) {
        component[property] = val;
      }
    }
  }
}

export const ComponentBridge: React.FC<{
  component: Component;
}> = ({ component }) => {
  component.project.log(`ComponentBridge render ${component.name || component.id}`);

  // On unmount, sever the connection to the Component.
  useEffect(() => {
    // TODO: only do this for components with a React renderer?
    return () => {
      delete component[FORCEUPDATE];
    };
  }, [component]);

  // Give the Component a way to force an update of its React renderer.
  const [, forceUpdate] = useReducer((c) => c + 1, 0);

  // Hide it with a Symbol to avoid name collisions and other surprises.
  // TODO: only do this for components with a React renderer?
  component[FORCEUPDATE] = forceUpdate;

  const description = component[DESCRIPTION];
  const Renderer = description?.renderer;

  if (Renderer) {
    // TODO: hmmm... ComponentDescription does not distinguish renderer properties.
    // Reduce the state down to the set of props the renderer accepts.
    const props: Properties = {};
    Object.keys(component[META].properties || {}).forEach((property) => {
      if (property in component) {
        // copy the props.
        props[property] = component[property];
      }
    });

    // Pass the Component as a prop so the renderer has access to it.
    return (
      <ErrorBoundary component={component}>
        <Renderer {...props} component={component} />
      </ErrorBoundary>
    );
  } else {
    return null;
    //return <div>{component.componentType} has no renderer</div>;
  }
};

export function getDescription(component: Component): ComponentDescription | undefined {
  return component[DESCRIPTION];
}

export function getForceUpdate(component: Component): undefined | (() => void) {
  return component[FORCEUPDATE];
}

// Follow an import path through a projects import hierarchy
function resolveImport(importPath: string, root: Project): Reactor | undefined {
  const path = importPath.split('/');

  for (const name of path) {
    root = root.imports?.[name];
    if (!root) {
      break;
    }
  }

  return root;
}

// Return the ComponentMaster or ComponentDescription for a componentType
export function resolveComponentType(
  componentType: string,
  project: Project
): Component | ComponentDescription | undefined {
  let root = project as Reactor | undefined;
  const { importPath, unqualifiedType } = parseComponentType(componentType);
  if (importPath) {
    // Component is referencing an imported project
    root = resolveImport(importPath, project);
    if (!root) {
      console.warn(`Project has no ${importPath} import for "${componentType}".`);
      return undefined;
    }
  }

  if (isReactor(root)) {
    // This is a Component master
    const master = root!.Components?.[unqualifiedType] as Component | undefined;
    if (!master) {
      console.warn(`Component "${unqualifiedType}" doesn't exist. importPath:${importPath}.`);
      return undefined;
    }
    return master;
  } else {
    // This is a JS module, import the Description directly
    let description = root![unqualifiedType + 'Description'] as ComponentDescription | undefined;
    if (!description) {
      console.warn(`Module ${importPath} does not export ${unqualifiedType}Description`);
      return undefined;
    }
    description = buildComponentDescription(description, project);
    return description;
  }
}

// Return a ComponentDescription with protype/extends processing
function buildComponentDescription(
  description: ComponentDescription,
  project: Project
): ComponentDescription | undefined {
  // Don't alter the original.
  description = {
    ...description!,
  };

  // If the description "extends" another perform a manual inheritance.
  // Also treate componentType the same
  if (description.extends) {
    const protoDescription = resolveComponentType(description.extends, project);
    if (protoDescription && !isReactor(protoDescription)) {
      // TODO: Do we really want ALL properties inherited?
      description = { ...protoDescription, ...description };

      // Inherit the protoDescription's prototype.
      if (
        description.prototype &&
        description.prototype !== protoDescription?.prototype &&
        protoDescription?.prototype
      ) {
        appendPrototype(description.prototype, protoDescription.prototype);
      }

      // Extend the metadata too
      extendComponentMetadata(description._meta!, protoDescription._meta);
    } else {
      console.error(
        `Invalid Component Description for ${description.extends} while processing ${description.name}`
      );
    }
  }

  return description as ComponentDescription;
}

// Return the ComponentMaster for a componentType
export function getComponentMaster(componentType: string, project: Project): Component | undefined {
  const master = resolveComponentType(componentType, project);
  if (!master) {
    return undefined;
  }
  if (!isReactor(master)) {
    return undefined;
  }
  return master as Component;
}

// Merge/extend fields in ComponentMetaData
// recurses only one level deep.
// TODO: do we actually just want a full deep merge of, say, properties?
export function extendComponentMetadata(
  target: ComponentMetaData,
  source: ComponentMetaData | undefined
) {
  for (const key in source) {
    if (key[0] === '$') {
      continue;
    }
    const targetValue = (target as any)[key];
    const sourceValue = (source as any)[key];
    if (targetValue === undefined) {
      (target as any)[key] = sourceValue;
    } else if (isObjectNotArray(targetValue)) {
      for (const prop in sourceValue) {
        if (!(prop in targetValue)) {
          if (isReactor(sourceValue[prop])) {
            targetValue[prop] = sourceValue[prop].getState();
          } else if (isObjectNotArray(sourceValue[prop])) {
            targetValue[prop] = { ...sourceValue[prop] };
          } else {
            targetValue[prop] = sourceValue[prop];
          }
        }
      }
    }
  }
}

// Find, in the current project, the component at the specified path.
// Return undefined if it none exists.
// TODO: scope?
export function getComponentByPath(path: string | undefined): Properties | undefined {
  if (path === undefined) {
    return undefined;
  }

  // TODO:
  return undefined;
}

export function isCompositeInstance(component: Component): boolean {
  if (!component.componentType) {
    return false;
  }
  return getComponentMaster(component.componentType, component.project) !== undefined;
}

export function isCompositeMaster(component: Component): boolean {
  return component.componentType === 'Play Kit/CompositeMaster';
}

export function eventNameFromHandlerName(handlerName: string): string {
  return lowerFirstLetter(handlerName.slice(2));
}

function lowerFirstLetter(s: string): string {
  return s[0].toLowerCase() + s.slice(1);
}

export class ErrorBoundary extends React.Component<
  { component: Component },
  { message?: string; stack?: string }
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { message: error.message, stack: error.stack };
  }

  componentDidCatch(error: any, errorInfo: any) {
    //this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.message) {
      const { width, height } = this.props.component;
      return (
        <div
          style={{
            width,
            height,
            color: 'darkred',
            backgroundColor: '#ffc0c0',
            position: 'absolute',
            whiteSpace: 'pre-wrap',
            overflowY: 'auto',
            fontSize: '13px',
          }}
        >
          {this.state.stack}
        </div>
      );
    }

    return this.props.children;
  }
}
