import { getConfig } from '@playful/runtime';
import type firebase from 'firebase/app';

export let app: firebase.app.App | undefined;

// NOTE: db is initialized async so users of it must be certain
// that Firebase has already been initialized.
export let db: firebase.database.Database;

async function getFirebase(): Promise<firebase.app.App> {
  if (app) {
    return app;
  }

  const firebase = (await import(/* webpackChunkName: "FirebaseApp" */ 'firebase/app')).default;

  await Promise.all([
    import(/* webpackChunkName: "FirebaseAuth" */ 'firebase/auth'),
    import(/* webpackChunkName: "FirebaseDb" */ 'firebase/database'),
  ]);

  // Firebase app config
  const cfg = getConfig();
  const config = {
    apiKey: cfg.firebaseApiKey,
    authDomain: cfg.firebaseAuthDomain,
    databaseURL: cfg.firebaseDatabaseUrl,
    projectId: cfg.googleProjectId,
    messagingSenderId: cfg.firebaseSenderId,
    appId: cfg.firebaseAppId,
  };

  app = firebase.initializeApp(config);
  db = app.database();
  return app;
}

export type TokenDidRefreshListener = () => void | Promise<void>;

/**
 * We utilize custom claims for things like beta/preview account approval, and likely
 * administrator role. The claims are stored within the token itself, so when we make
 * updates the claim won't be reflected either to the client, or the token it uses to
 * make API requests, until either the token expires (1hr default I believe) or the
 * user logs out and then logs in.
 *
 * This helper leverages the existing userPush notification mechanism to deliver a
 * user_token notification. When received, all connected clients will renew their tokens.
 *
 * It adds an onIdTokenChanged lisener and, when the state changes, it attaches a db
 * listener to the current users userPush/$id/user_token. If there was a previous listener
 * active, it clears it.
 *
 * Since the ref.on will fire immediately with the current value, a firstValue flag is used
 * to prevent immediate refresh of the token.
 *
 * To allow for the App to hook in and interact with the react runtime and the UserService,
 * a token refresh callback is provided. This callback will get called immediately with the
 * token which comes with initial auth, and then again each time the token is proactively
 * refreshed.
 *
 */
export function setupTokenRefresh(
  auth: firebase.auth.Auth,
  tokenUpdated?: TokenDidRefreshListener
): firebase.Unsubscribe {
  let firstValue = false;
  let subscribedUserId: string | undefined = undefined;

  async function onTokenShouldRefresh(snapshot: firebase.database.DataSnapshot): Promise<void> {
    // db.ref('value' will fire immediately with the current value, and we don't want to immediately
    // refresh the token. So bail on the first value
    if (firstValue) {
      firstValue = false;
      return;
    }

    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
      tokenUpdated?.();
    }
  }

  return auth.onIdTokenChanged(
    async (user: firebase.User | null): Promise<void> => {
      const newUserId = user ? user.uid : undefined;

      const db = await getFirebaseDb();

      // first notify with initial token from auth. Allows the initially authed credentials
      // to be loaded into the userService for example.
      tokenUpdated?.();

      // there was an existing watch, clear it.
      if (subscribedUserId !== undefined) {
        db.ref(`userPush/${subscribedUserId}/user_token`).off('value', onTokenShouldRefresh);
        subscribedUserId = undefined;
      }

      // attach a watch for the current user id
      if (newUserId !== undefined) {
        subscribedUserId = newUserId;
        firstValue = true;
        db.ref(`userPush/${subscribedUserId}/user_token`).on('value', onTokenShouldRefresh);
      }
    }
  );
}

export async function getFirebaseAuth(): Promise<firebase.auth.Auth> {
  return (await getFirebase()).auth();
}

export async function getFirebaseDb(): Promise<firebase.database.Database> {
  return (await getFirebase()).database();
}

export async function getFirebaseAuthToken(): Promise<string | undefined> {
  const auth = (await getFirebase()).auth();
  if (auth.currentUser === null) {
    return undefined;
  }
  return await auth.currentUser.getIdToken();
}

export async function getFirebaseAuthTokenResult(): Promise<
  firebase.auth.IdTokenResult | undefined
> {
  const auth = (await getFirebase()).auth();
  if (auth.currentUser === null) {
    return undefined;
  }
  return await auth.currentUser.getIdTokenResult();
}
