import type { UserId } from '@playful/runtime';
import type firebase from 'firebase/app';
import React, {
  Context,
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { PropsWithChildren } from 'react';

import { apiRequest, axiosRequest } from '../apiService';
import { db, getFirebaseAuthTokenResult, setupTokenRefresh } from '../firebase';
import { InitialUserClaims, InitialUserFeatures, UserClaims, UserFeatures } from './flags';
import type { AccessToken, PublicUser, User } from './user';

export const getLoginStatus = (user: User | null) => user?.id !== 'anonymous';
export const getApprovalStatus = (loggedIn: boolean, approved: boolean) => loggedIn && approved;
export const getAdminStatus = (loggedIn: boolean, admin: boolean) => loggedIn && admin;

// Have the backend populate user-related collections.
export function setUserInfo(userName: string, email: string, invite: string) {
  const params = new URLSearchParams({ userName, email, invite });

  return axiosRequest(`/backend/setUserInfo?${params.toString()}`, {
    method: 'post',
    withCredentials: true,
  });
}

export type UserCtx = {
  user: User | null;
  publicUsers: { [userId: string]: PublicUser };
  setPublicUsers: Dispatch<SetStateAction<{ [userId: string]: PublicUser }>>;
  setUser: (user: User | null) => void;
  userFeatures: UserFeatures;
  setUserFeatures: Dispatch<SetStateAction<UserFeatures>>;
  updateCurrentUser: (user: User | undefined) => void;
  hasFeature: (featureName: keyof UserFeatures) => boolean;
  hasClaim: (claimName: keyof UserClaims) => boolean;
  updateClaims: (claims: { [claim: string]: boolean | undefined }) => void;
  clearClaims: () => void;
  subscribeUserFeatures: (
    userId: string,
    callback: (snapshot: firebase.database.DataSnapshot) => void
  ) => () => void;
  updateUserFeatures: (userId: string, features: any) => void;
  getUserId: (userName: string) => Promise<UserId | undefined>;
  getPublicUsers: () => Promise<PublicUser[]>;
  getPublicUser: (userId: string) => Promise<PublicUser>;
  updatePublicUser: (publicUser: PublicUser) => Promise<void>;
  getAccessTokens: () => Promise<AccessToken[]>;
  addAccessToken: (description: string) => Promise<AccessToken>;
  delAccessToken: (id: string) => Promise<void>;
  isLoggedIn: boolean;
  isApproved: boolean;
  isAdmin: boolean;
  registeredUserName: string;
  setRegisteredUserName: Dispatch<SetStateAction<string>>;
};

// this is a hack for now.
// later, we'll need to go through and just add assertions and null checks for these across the codebase.
// this is safe, for now, as we don't render the app at all if `user` isn't true, but that may not
// always be the case.
export type UseUserCtx = Omit<UserCtx, 'user' | 'setUser'> & {
  user: User;
  setUser: Dispatch<SetStateAction<User | null>>;
};

export const UserContext = createContext<UserCtx>(undefined as any);

export const useUserContext = () => useContext<UseUserCtx>(UserContext as Context<UseUserCtx>);

type PublicUserRecord = { created: number; name: string; profileProject?: string };

const anonymousUser = { id: 'anonymous', name: 'anonymous', email: '' } as User;

// expose pre-filled provider
export function UserProvider({
  auth,
  onUserChange,
  onApprovalStatusChange,
  children,
}: PropsWithChildren<{
  auth?: firebase.auth.Auth;
  onUserChange?: (user: User | null) => void;
  onApprovalStatusChange?: (approvedStatus: boolean) => void;
}>) {
  // the onUserChange and onApprovalStatusChange are used in memoized callbacks, which are
  // in turn used in useEffects. If onUserChange or onApprovalStatusChange are an
  // un-memoized cb (or inline fn), it will cause an infinite useEffect rerender. This is
  // why we ref the callbacks in the next lines.
  const onUserChangeRef = useRef(onUserChange);
  const onApprovalStatusChangeRef = useRef(onApprovalStatusChange);
  const [user, setUser] = useState<User | null>(null);
  const [userFeatures, setUserFeatures] = useState<UserFeatures>(InitialUserFeatures);
  const [publicUsers, setPublicUsers] = useState<{ [userId: string]: PublicUser }>({});
  const [userIds, setUserIds] = useState<{ [userName: string]: UserId }>({});
  const [userClaims, setUserClaims] = useState<UserClaims>(InitialUserClaims);
  const [registeredUserName, setRegisteredUserName] = useState('');
  const { id: userId } = user || {};

  const updateCurrentUser = useCallback((user: User | undefined) => {
    if (user) {
      setPublicUsers((publicUsers) => ({
        ...publicUsers,
        [user.id]: { id: user.id, name: user.name },
      }));
      setUserIds((userIds) => ({ ...userIds, [user.name]: user.id }));
    }
  }, []);

  const hasFeature = useCallback((featureName: keyof UserFeatures) => userFeatures?.[featureName], [
    userFeatures,
  ]);

  const hasClaim = useCallback((claimName: keyof UserClaims) => userClaims?.[claimName], [
    userClaims,
  ]);

  const isLoggedIn = getLoginStatus(user);
  const isApproved = getApprovalStatus(isLoggedIn, hasClaim('approved'));
  const isAdmin = getAdminStatus(isLoggedIn, hasClaim('admin'));

  const updateClaims = useCallback((claims: { [claim: string]: boolean | undefined }) => {
    const approved = claims.approved ?? InitialUserClaims.approved;

    setUserClaims((userClaims) => ({
      ...userClaims,
      approved,
      admin: claims.admin ?? InitialUserClaims.admin,
    }));

    // we don't fire the onApprovalStatusChange cb here bc we're not interested in the
    // claim, we're interested in the derived `isApproved` value. `isApproved` changes based
    // on the user status, so we have it as a useEffect below.
  }, []);

  const clearClaims = useCallback(() => {
    updateClaims({ ...InitialUserClaims });
    setUserFeatures({ ...InitialUserFeatures });
  }, [updateClaims]);

  const subscribeUserFeatures = useCallback(
    (userId: string, callback: (snapshot: firebase.database.DataSnapshot) => void) => {
      const cb = (snapshot: firebase.database.DataSnapshot) => {
        callback(snapshot);
        setUserFeatures(snapshot.val());
      };

      db.ref(`userFeatures/${userId}`).on('value', cb);

      return () => db.ref(`userFeatures/${userId}`).off('value', cb);
    },
    []
  );

  // name could be better...a getter that sets can cause confusing renders
  const getUserId = useCallback(
    async (userName: string): Promise<UserId | undefined> => {
      // Try the cache first.
      let userId = userIds[userName];
      if (userId) return userId;

      const snapshot = await db.ref(`userids/${userName.toLowerCase()}`).once('value');
      userId = snapshot.val();

      if (!userId) {
        return undefined;
      } else {
        // Add to cache.
        setUserIds({ ...userIds, [userName]: userId });

        return userId;
      }
    },
    [userIds]
  );

  // name could be better...a getter that sets can cause confusing renders
  const getPublicUsers = useCallback(async () => {
    const snapshot = await db.ref(`users`).once('value');
    const userMap: { [uid: string]: PublicUserRecord } = snapshot.val();
    const _publicUsers: PublicUser[] = Object.keys(userMap).map((uid) =>
      publicUserFromUserRecord(uid, userMap[uid])
    );
    const _userObj = Object.fromEntries(_publicUsers.map((user) => [user.id, user]));

    // Add to cache.
    setPublicUsers((publicUsers) => ({ ...publicUsers, ..._userObj }));

    return _publicUsers;
  }, []);

  // name could be better...a getter that sets can cause confusing renders
  const getPublicUser = useCallback(async (userId: string) => {
    if (userId === 'anonymous') return { id: 'anonymous', name: 'Anonymous' };

    const snapshot = await db.ref(`users/${userId}`).once('value');
    const userRecord = snapshot.val();

    return publicUserFromUserRecord(userId, userRecord);
  }, []);

  const updatePublicUser = useCallback(async (publicUser: PublicUser) => {
    // Can't update id or name.
    const publicUserRecord = {
      // Pass null to remove data.
      profileProject: publicUser.profileProject ? publicUser.profileProject : null,
    };

    await db.ref(`users/${publicUser.id}`).update(publicUserRecord);

    // Update public users cache.
    setPublicUsers((publicUsers) => ({ ...publicUsers, [publicUser.id]: publicUser }));
  }, []);

  const getAccessTokens = useCallback(async () => {
    if (!user) return [];

    const ret = await apiRequest(`users/${user.id}/access_tokens`, {
      method: 'GET',
    });

    return ret.json();
  }, [user]);

  const addAccessToken = useCallback(
    async (description: string) => {
      if (!user) throw new Error('Not logged in');

      const ret = await apiRequest(`users/${user.id}/access_tokens`, {
        method: 'POST',
        body: JSON.stringify({ description }),
      });

      return ret.json();
    },
    [user]
  );

  const delAccessToken = useCallback(
    async (id: string) => {
      if (!user) throw new Error('Not logged in');

      await apiRequest(`users/${user.id}/access_tokens/${id}`, {
        method: 'DELETE',
      });
    },
    [user]
  );

  const updateUser = useCallback(
    (user: User | null) => {
      setUser(user);

      // Heap analytics tracking
      const heap = (window as any).heap;
      if (heap) {
        if (user?.id !== 'anonymous') {
          heap.identify(user?.id);
          heap.addUserProperties({ email: user?.email, username: user?.name });
        } else {
          heap.resetIdentity();
        }
      }

      onUserChangeRef.current?.(user);
    },
    [onUserChangeRef]
  );

  const memoValues = useMemo(
    () => ({
      publicUsers,
      user,
      userFeatures,
      registeredUserName,
    }),
    [user, userFeatures, registeredUserName, publicUsers]
  );

  const value = {
    ...memoValues,
    setPublicUsers,
    setUser: updateUser,
    setUserFeatures,
    updateUserFeatures,
    isAdmin,
    isApproved,
    isLoggedIn,
    delAccessToken,
    addAccessToken,
    getAccessTokens,
    updatePublicUser,
    getPublicUser,
    getPublicUsers,
    getUserId,
    subscribeUserFeatures,
    clearClaims,
    updateClaims,
    hasClaim,
    hasFeature,
    updateCurrentUser,
    setRegisteredUserName,
  };

  // we need to do this here, because there's no guarantee that the below firebase callbacks
  // will fire sequentially--approved is a derived value, and it's possible the user becomes
  // logged in before we know they're approved, and vice versa.
  useEffect(() => {
    onApprovalStatusChangeRef.current?.(isApproved);
  }, [isApproved]);

  useEffect(() => {
    if (!auth) return;

    return auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser) {
        // displayNames and emails can change but not uids.
        // Also might find uses for emailVerified, photoURL, isAnonymous, and providerData
        const user: User = {
          id: firebaseUser.uid,
          name: firebaseUser.displayName!,
          email: firebaseUser.email!,
        };

        // When onAuthStateChanged is called as part of a register it doesn't include the
        // displayName (it doesn't know it yet). We work around by having register stash the
        // userName for retrieval here.
        if (!firebaseUser.displayName) {
          user.name = registeredUserName!;
          console.assert(user.name, 'getRegisteredUserName did not return displayName');
        }

        updateUser(user);
      } else {
        updateUser(anonymousUser);
      }
    });
  }, [auth, setUser, registeredUserName, updateUser]);

  useEffect(() => {
    if (!auth) return;

    return setupTokenRefresh(auth, async () => {
      const tokenResult = await getFirebaseAuthTokenResult();

      if (!tokenResult) return;

      updateClaims(tokenResult.claims);
    });
  }, [auth, updateClaims]);

  useEffect(() => {
    if (userId) return subscribeUserFeatures(userId, (snapshot) => setUserFeatures(snapshot.val()));
  }, [userId, setUserFeatures, subscribeUserFeatures]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

function updateUserFeatures(userId: string, features: any) {
  db.ref(`userFeatures/${userId}`).update(features);
}

function publicUserFromUserRecord(userId: string, userRecord: PublicUserRecord): PublicUser {
  return {
    id: userId,
    name: userRecord?.name,
    profileProject: userRecord?.profileProject,
  };
}
