/* eslint-disable react/no-unescaped-entities */
import { Typography, createStyles, makeStyles } from '@material-ui/core';
import type { ProjectInfo } from '@playful/runtime';
import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch } from 'wouter';

import Logo from './logo.svg';
import type { ProjectStore } from './project/projectStorage';
import { AuthDialog } from './user/AuthDialog';

const ProjectExplorer = lazy(
  () => import('./explorer/ProjectExplorer' /* webpackChunkName: "ProjectExplorer" */)
);
const Workbench = lazy(() => import('./workbench/Workbench' /* webpackChunkName: "Workbench" */));
const UserPage = lazy(() => import('./explorer/UserPage' /* webpackChunkName: "UserPage" */));
const UsersPage = lazy(() => import('./explorer/UsersPage' /* webpackChunkName: "UsersPage" */));

type AppRoutesProps = {
  projectInfos: ProjectInfo[];
  projectStore: ProjectStore | undefined;
  isApproved: boolean;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    teaser: {
      textAlign: 'center',
      padding: theme.spacing(4),
    },
    logo: {
      width: '200px',
    },
  })
);

export function AppRoutes(props: AppRoutesProps) {
  const classes = useStyles();
  const { projectInfos, projectStore, isApproved } = props;
  const [signinTimeout, setSigninTimeout] = useState(false);

  // Avoid displaying the signed out experience for the very brief interval while
  // firebase is signing the user in via cookie. Unsightly flashing.
  useEffect(() => {
    window.setTimeout(() => setSigninTimeout(true), 250);
  }, []);

  return isApproved ? (
    <Switch>
      <Route path='/@:userName'>
        {(params) => <UserPage userName={params.userName} projectStore={projectStore!} />}
      </Route>
      <Route path='/@'>
        <UsersPage />
      </Route>
      <Route path='/e/:projectId'>
        {(params) => <Workbench projectStore={projectStore!} projectId={params.projectId} />}
      </Route>
      <Route path='/gallery'>
        <ProjectExplorer tab='gallery' projectStore={projectStore!} projectInfos={projectInfos} />
      </Route>
      <Route path='/tutorials'>
        {() => (
          <ProjectExplorer
            tab='tutorials'
            projectStore={projectStore!}
            projectInfos={projectInfos}
          />
        )}
      </Route>
      <Route path='/'>
        <ProjectExplorer tab='private' projectStore={projectStore!} projectInfos={projectInfos} />
      </Route>
      <Route path='/signIn'>
        <AuthDialog form='signin' allowClose={false} open={true} />
      </Route>
      <Route path='/register'>
        <AuthDialog form='register' allowClose={false} open={true} />
      </Route>
      <Route path='/forgot-password'>
        <AuthDialog form='forgot-password' allowClose={false} open={true} />
      </Route>
      <Route path='/:any*'>
        <Typography
          data-cy='error_404'
          component='h1'
          variant='h2'
          align='center'
          color='textSecondary'
          style={{ marginTop: '2rem' }}
        >
          404 :(
        </Typography>
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route path='/signIn'>
        <AuthDialog form='signin' allowClose={false} open={true} />
      </Route>
      <Route path='/register'>
        <AuthDialog form='register' allowClose={false} open={true} />
      </Route>
      <Route path='/forgot-password'>
        <AuthDialog form='forgot-password' allowClose={false} open={true} />
      </Route>
      <Route path='/e/:projectId'>
        <Typography align='center' variant='h4'>
          Unable to open this Project. Is it private or deleted?
        </Typography>
        <Typography align='center' variant='h5'>
          Or you may need to Login
        </Typography>
      </Route>
      <Route path='/:any*'>
        {signinTimeout && (
          <div className={classes.teaser} data-cy='teaser'>
            <Logo className={classes.logo} />
            <h1>Thanks for your interest!</h1>
            <h2>We'll let you know when it's ready!</h2>
          </div>
        )}
      </Route>
    </Switch>
  );
}
