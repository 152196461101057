import type { IProjectModel } from './designRuntime';
import type { Component, Point, Rectangle } from './runtime';

export type ComponentState = {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: number;
  scale: number;
  [property: string]: any;
};

export function rotatedScaledPointsFromRectangle(rect: ComponentState): Point[] {
  const center: Point = { x: rect.x + rect.width / 2, y: rect.y + rect.height / 2 };

  return pointsFromRectagle(rect).map((point) => {
    const [x, y] = getScaledPointRotatedByDegreesAroundOrigin(
      point,
      center,
      rect.scale,
      rect.rotation
    );

    return { x, y } as Point;
  });
}

function pointsFromRectagle(rect: ComponentState): Point[] {
  return [
    { x: rect.x, y: rect.y },
    { x: rect.x + rect.width, y: rect.y },
    { x: rect.x + rect.width, y: rect.y + rect.height },
    { x: rect.x, y: rect.y + rect.height },
  ];
}

export function getGlobalCornersForComponentWithRotationAndScale(
  component: Component,
  includeRotation = false
): { tl: Point; tr: Point; br: Point; bl: Point } | undefined {
  if (!component.getGlobalCoordinates) {
    return undefined;
  }

  const { x, y, scale } = component.getGlobalCoordinates(0, 0);
  const w = component.width;
  const h = component.height;
  const rotDegrees = component.rotation;

  if (w === undefined || h === undefined) {
    return undefined;
  }

  const rotRadians = includeRotation ? (rotDegrees / 180) * Math.PI : 0;
  const mX = x + w / 2;
  const mY = y + h / 2;

  return {
    tl: rotate([x, y]),
    tr: rotate([x + w, y]),
    br: rotate([x + w, y + h]),
    bl: rotate([x, y + h]),
  };

  function rotate([x, y]: number[]): Point {
    return {
      x: scale * (x - mX) * Math.cos(rotRadians) - scale * (y - mY) * Math.sin(rotRadians) + mX,
      y: scale * (x - mX) * Math.sin(rotRadians) + scale * (y - mY) * Math.cos(rotRadians) + mY,
    };
  }
}

export function getRectangleFromCorners(corners: {
  tl: Point;
  tr: Point;
  br: Point;
  bl: Point;
}): Rectangle {
  const { tl, tr, bl, br } = corners;

  const x1 = Math.min(tl.x, tr.x, bl.x, br.x);
  const y1 = Math.min(tl.y, tr.y, bl.y, br.y);
  const x2 = Math.max(tl.x, tr.x, bl.x, br.x);
  const y2 = Math.max(tl.y, tr.y, bl.y, br.y);

  return { x: x1, y: y1, w: x2 - x1, h: y2 - y1 };
}

export function getComponentRectWithRotationAndScale(
  component: Component,
  includeRotation = false
): Rectangle | undefined {
  const corners = getGlobalCornersForComponentWithRotationAndScale(component, includeRotation);
  if (corners === undefined) {
    return undefined;
  }

  return getRectangleFromCorners(corners);
}

export function getScaledPointRotatedByDegreesAroundOrigin(
  point: Point,
  origin: Point,
  scale: number,
  rotDegrees: number
): [number, number] {
  const rotRadians = (rotDegrees / 180) * Math.PI;

  return getScaledPointRotatedByRadiansAroundOrigin(point, origin, scale, rotRadians);
}

export function getScaledPointRotatedByRadiansAroundOrigin(
  { x, y }: Point,
  { x: oX, y: oY }: Point,
  scale: number,
  rotRadians: number
): [number, number] {
  return [
    scale * (x - oX) * Math.cos(rotRadians) - scale * (y - oY) * Math.sin(rotRadians) + oX,
    scale * (x - oX) * Math.sin(rotRadians) + scale * (y - oY) * Math.cos(rotRadians) + oY,
  ];
}

// Returns a Rectangle containing all selected components in global (Deck) coordinates.
export function getSelectionBounds(
  projectModel: IProjectModel,
  includeRotation: boolean
): Rectangle | undefined {
  const selectedComponents = projectModel.getSelection() as Component[];

  let selectionRect = undefined;
  for (const component of selectedComponents) {
    if (component.parent) {
      if (component.width === undefined || component.height === undefined) {
        continue;
      }

      const componentRect = getComponentRectWithRotationAndScale(component, includeRotation);
      selectionRect = unionAndNormalizeRect(componentRect, selectionRect);
    }
  }

  return selectionRect;
}

// Return a Rectangle with a top-left origin and integer values.
export function normalizeRect(x1: number, y1: number, x2: number, y2: number): Rectangle {
  // TODO: do we really want to integerize these? Only worthwhile for project coordinates.
  x1 = Math.round(x1);
  y1 = Math.round(y1);
  x2 = Math.round(x2);
  y2 = Math.round(y2);
  return {
    x: Math.min(x1, x2),
    y: Math.min(y1, y2),
    w: Math.abs(x1 - x2),
    h: Math.abs(y1 - y2),
  };
}

export function unionAndNormalizeRect(a?: Rectangle, b?: Rectangle): Rectangle | undefined {
  if (!a) return b;
  if (!b) return a;
  const x = Math.min(a.x, b.x);
  const y = Math.min(a.y, b.y);
  const x2 = Math.max(a.x + a.w, b.x + b.w);
  const y2 = Math.max(a.y + a.h, b.y + b.h);
  return normalizeRect(x, y, x2, y2);
}
