/**
 * Heavily inspired by
 * @see https://github.com/wangweiwei/video-metadata-thumbnails/blob/master/src/video/index.ts
 */
import { canvasToBlob } from './canvas';

type VideoThumbnailOptions = {
  scale?: number;
  start: number; // In seconds, where in the video to take the snapshot
  quality: number;
};

export class VideoThumbnail {
  private videoElement: HTMLVideoElement;
  private canvas: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;

  constructor(url: string) {
    this.videoElement = document.createElement('video') as HTMLVideoElement;
    this.videoElement.preload = 'auto';
    this.videoElement.playsInline = true;
    this.videoElement.muted = true;
    this.videoElement.volume = 0;
    this.videoElement.crossOrigin = 'anonymous';
    this.videoElement.src = url;
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    this.canvas = canvas;
    this.canvasContext = canvas.getContext('2d')!;
  }

  getThumbnail(options: VideoThumbnailOptions): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const { start, scale, quality } = options;
      let canPlay = false;
      const canplayHandler = () => {
        if (!canPlay) {
          this.videoElement.currentTime = start;
        }
        canPlay = true;
      };
      const timeupdateHandler = () => {
        const { videoElement, canvasContext } = this;
        const { videoWidth, videoHeight } = videoElement;
        const $videoWidth = videoWidth * (scale || 1);
        const $videoHeight = videoHeight * (scale || 1);
        this.canvas.width = $videoWidth;
        this.canvas.height = $videoHeight;
        canvasContext.drawImage(this.videoElement, 0, 0, $videoWidth, $videoHeight);
        canvasToBlob(this.canvas, 'image/jpeg', quality)
          .then((blob) => {
            endedHandler();
            resolve(blob);
          })
          .catch((error) => {
            endedHandler();
            reject(error);
          });
      };

      const endedHandler = () => {
        this.videoElement.removeEventListener('ended', endedHandler, false);
        this.videoElement.removeEventListener('canplay', canplayHandler, false);
        this.videoElement.removeEventListener('timeupdate', timeupdateHandler, false);
        this.videoElement.removeEventListener('error', errorHandler, false);
      };
      const errorHandler = () => {
        const { error } = this.videoElement;
        if (error) {
          reject(error);
        } else {
          reject(new Error('__NAME__ unknown error'));
        }
        endedHandler();
      };
      this.videoElement.addEventListener('canplay', canplayHandler, false);
      this.videoElement.addEventListener('timeupdate', timeupdateHandler, false);
      this.videoElement.addEventListener('ended', endedHandler, false);
      this.videoElement.addEventListener('error', errorHandler, false);
    });
  }
}
