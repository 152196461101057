import { importCJS } from './importCJS';
import { loadProject, migrateProject } from './project';
import { deserialize, isReactor } from './reactor';
import { ReactorObjectPrototype } from './reactorObject';
import { urlFromSource } from './resources';
import { ImportInfo, Project, ProjectState, getResourceUrl } from './runtime';
import { dirName, pathJoin } from './util';

export function getImportProtocolAndPath(importInfo: ImportInfo): string[] {
  if (!importInfo.source) {
    return ['', ''];
  }
  const s = importInfo.source.split(':');
  return [s[0], s.slice(1).join(':')];
}

export async function importProjectOrModule(
  importInfo: ImportInfo,
  project: Project
): Promise<any> {
  const imports = project.imports;

  // Release imported module/project/whatever, if already loaded.
  unimportProjectOrModule(importInfo, project);

  if (!isImport(importInfo)) {
    return;
  }

  let [protocol, path] = getImportProtocolAndPath(importInfo);
  if (!protocol) {
    return;
  }

  // Hang on to these so we'll have them at unimport time even if they've changed.
  // TODO: do we really need them?
  importInfo.importedName = importInfo.name;

  //console.log(`import ${importInfo.name} (${importInfo.source})`);
  switch (protocol) {
    // Handle CJS modules
    case 'cjs': {
      if (path[0] !== '/') {
        path = urlFromSource(path, project)!;
      }

      // Make the import promise available to those who want to wait on the import.
      importInfo.promise = importCJS(path);

      // Import!
      importInfo.promise = importInfo.promise
        .then((module: any) => {
          importInfo.module = module;
          imports[importInfo.name] = module;
        })
        .catch((err) => console.error(err));
      return importInfo.promise;
    }

    // Handle importing ESM modules
    case 'esm': {
      if (path[0] !== '/') {
        path = urlFromSource(path, project)!;
      }
      // Make the import promise available to those who want to wait on the import.
      importInfo.promise = import(
        /* webpackIgnore: true */
        path
      );

      // Import!
      importInfo.promise = importInfo.promise
        .then((module: any) => {
          importInfo.module = module;
          imports[importInfo.name] = module;
        })
        .catch((err) => console.error(err));
      return importInfo.promise;
    }

    // Handle playkit imports
    case 'playkit': {
      // Import!
      importInfo.promise = import('@playful/playkit')
        .then((module: any) => {
          importInfo.module = module;
          imports[importInfo.name] = module;
        })
        .catch((err) => console.error(err));
      return importInfo.promise;
    }

    // Import components from another project
    case 'project': {
      const projectResource = importInfo.projectResource;
      let url: string;
      let resourceRoot: string;
      if (!!projectResource) {
        // Old-style import from external resource
        url = getResourceUrl(projectResource, 'project.json');
        resourceRoot = getResourceUrl(projectResource, '');
      } else {
        // New style import via path to a project.json file
        url = pathJoin([project.resourceRoot, path]);
        resourceRoot = pathJoin([project.resourceRoot, dirName(path)]);
      }
      importInfo.promise = readRemoteProject(url)
        .then((state) => importProjectState(state, importInfo, project, resourceRoot))
        .catch((err) => {
          // imp.error = err;
          console.error(err);
        });
      return importInfo.promise;
    }

    default:
      console.error(`unknown import protocol: ${importInfo.source}`);
  }
}

function importProjectState(
  state: ProjectState,
  importInfo: ImportInfo,
  project: Project,
  resourceRoot: string
): Promise<void> {
  const imports = project.imports;
  return loadProject(
    state,
    { title: importInfo.name } as any,
    project.mainProject,
    resourceRoot
  ).then((importedProject) => {
    importInfo.importedProject = importedProject;
    imports[importInfo.name] = importedProject;

    // Have the host project inherit the imported project's imports.
    // This way we have one flat list of imports regardless of how deeply projects are nested.
    appendPrototype(imports, importedProject.imports);
  });
}

export function unimportProjectOrModule(imp: ImportInfo, project: Project): void {
  if (imp.module || imp.importedProject) {
    const imports = project.imports;

    //console.log(`unimport ${imp.name} (${imp.source})`);
    delete imp.promise;
    delete imp.module;

    if (imp.importedProject) {
      // Remove the imported project's contributed imports.
      removePrototype(imports, imp.importedProject.imports);
    }

    // Clean up.
    delete imports[imp.importedName!];
    delete imp.importedName;
  }
}

export function importProjectsAndModules(project: Project): Promise<any>[] {
  const imports: ImportInfo[] = project.Imports!;
  if (!imports) {
    return [];
  }

  return imports.map((info) => importProjectOrModule(info, project));
}

export function isImport(info: any): boolean {
  // TODO: try if (!info.importedName || !info.source) {
  if (!info.name || !info.source) {
    return false;
  }
  return true;
}

// TODO: progress
export async function readRemoteProject(url: string): Promise<ProjectState> {
  // TODO: error handling
  const response = await fetch(url);
  // TODO: error handling
  const jsonText = await response.text();
  const json = deserialize(jsonText);

  // Upgrade the project to the latest format.
  await migrateProject(json);

  return json;
}

function appendPrototype(chain: any, prototype: any): void {
  console.assert(chain !== undefined);
  let chainEnd = chain;
  while (
    isReactor(Object.getPrototypeOf(chainEnd)) &&
    Object.getPrototypeOf(chainEnd) !== ReactorObjectPrototype
  ) {
    chainEnd = Object.getPrototypeOf(chainEnd);
  }
  Object.setPrototypeOf(chainEnd, prototype);
}

function removePrototype(chain: any, prototype: any): void {
  console.assert(chain !== undefined);
  let linkBefore = chain;
  while (Object.getPrototypeOf(linkBefore) !== prototype) {
    linkBefore = Object.getPrototypeOf(linkBefore);
  }
  Object.setPrototypeOf(linkBefore, Object.getPrototypeOf(prototype));
}
