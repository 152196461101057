import type { ProjectId } from '@playful/runtime';
import { isObjectNotArray } from '@playful/runtime/util';
import { useCallback } from 'react';
import { proxy, subscribe, useSnapshot } from 'valtio';

import { isSmallScreen } from './components/componentUtil';
import type { DeviceViewport } from './workbench/DevicesMenu';

export const defaultPanelWidth = 270;
export const splitterSize = 6;
export const showProjectFilterButton = false;
export const defaultFontSize = 12;

const initialProjectWorkbenchState: ProjectWorkbenchState = {
  expandedNodes: [],
  lastUpdated: new Date().getTime(),
};

const initialState = {
  workbench: {
    // Transient [TODO: but currently persisted anyway]
    saving: false,

    // Persistent
    showContentPanel: !isSmallScreen(),
    contentPanelWidth: 375 + splitterSize,
    showObjectTree: false,
    objectTreeWidth: defaultPanelWidth,
    codePanelWidth: 500,
    showConsole: false,
    consoleHeight: 200,
    showInspector: true,
    inspectorPanel: {
      widths: {
        properties: defaultPanelWidth,
        code: defaultPanelWidth * 2,
      } as { [tab: string]: number },
    },
    contentPanel: {
      activeTab: 'Components',
    },
    viewportDevice: {
      device: 'Responsive',
      width: undefined,
      height: undefined,
    } as DeviceViewport,
  },
  contentPanel: {
    activeTab: 'Components',
  },
  projectWorkbenchStateCollection: {} as Record<string, ProjectWorkbenchState>,
  mobileModalOpen: true,
};

export type ProjectWorkbenchState = { expandedNodes: string[]; lastUpdated: number };

export const globalState = proxy(initialState);

// Persist the global state as it changes.
// TODO: Global state we don't want persisted.
subscribe(globalState, () => saveGlobalState());

export function loadGlobalState(): void {
  const json = localStorage.getItem('globalState');
  if (json) {
    const loadedState = JSON.parse(json);
    mergeObjects(globalState, loadedState);
    mergeProjectWorkbenchState(globalState, loadedState);
  }
}
export function saveGlobalState(): void {
  localStorage.setItem('globalState', JSON.stringify(globalState, null, 2));
}

// Preserves the projectWorkbenchState when a project is copied
export function cloneProjectWorkbenchState(oldId: ProjectId, newProjectId: ProjectId) {
  if (globalState.projectWorkbenchStateCollection[oldId]) {
    globalState.projectWorkbenchStateCollection[newProjectId] = {
      ...globalState.projectWorkbenchStateCollection[oldId],
    };
  }
}

// Recursively merge object b's properties on top of object a's.
function mergeObjects(a: any, b: any): void {
  for (const key in a) {
    if (key in b) {
      if (isObjectNotArray(a[key])) {
        mergeObjects(a[key], b[key]);
      } else {
        a[key] = b[key];
      }
    }
  }
}

// Merge the designState in a special-ish way to deal with the dynamic project indexing.
function mergeProjectWorkbenchState(a: any, b?: any): void {
  if (!b) {
    return;
  }
  for (const key in b.projectWorkbenchStateCollection) {
    if (!a.projectWorkbenchStateCollection[key]) {
      a.projectWorkbenchStateCollection[key] = initialProjectWorkbenchState;
    }

    for (const propertyKey in b.projectWorkbenchStateCollection[key]) {
      if (propertyKey in initialProjectWorkbenchState) {
        a.projectWorkbenchStateCollection[key][propertyKey] =
          b.projectWorkbenchStateCollection[key][propertyKey];
      }
    }
  }
}

// Given a project ID returns the projectDesignState and setter
export function useProjectWorkbenchState(
  projectId: ProjectId,
  initialState: Partial<ProjectWorkbenchState>
): {
  projectWorkbenchState: ProjectWorkbenchState;
  setProjectWorkbenchState: (state: Partial<ProjectWorkbenchState>) => void;
} {
  const { projectWorkbenchStateCollection } = useSnapshot(globalState);
  if (!globalState.projectWorkbenchStateCollection[projectId]) {
    globalState.projectWorkbenchStateCollection[projectId] = {
      ...initialProjectWorkbenchState,
      ...initialState,
    };
  }

  const setProjectWorkbenchState = useCallback(
    (state: Partial<ProjectWorkbenchState>) => {
      globalState.projectWorkbenchStateCollection[projectId] = {
        ...globalState.projectWorkbenchStateCollection[projectId],
        ...state,
        lastUpdated: new Date().getTime(),
      };
    },
    [projectId]
  );

  return {
    projectWorkbenchState:
      projectWorkbenchStateCollection[projectId] ||
      globalState.projectWorkbenchStateCollection[projectId] ||
      initialState,
    setProjectWorkbenchState,
  };
}
