import type {
  ProjectId,
  ProjectInfo,
  ProjectState,
  ResourceData,
  Tags,
  UserId,
} from '@playful/runtime';
import { readRemoteProject, serialize } from '@playful/runtime';
import type firebase from 'firebase/app';

import { apiRequest } from '../apiService';
import { db } from '../firebase';
import { cloneProjectWorkbenchState } from '../globalState';
import { PREVIEW, PROJECT_JSON, Resource } from '../resources';
import type { User } from '../user/user';
import { generateUUID, suggestName, suggestSuffix } from '../utils/util';

export const emptyProjectInfo = {
  id: 'empty',
  owner: 'wi4oXQ0XETh4oY0aDzhTVlY2fZP2',
  ownerName: 'Play',
  name: 'empty',
  title: 'Empty Project',
  project: 'empty',
  modified: 0,
  created: 0,
  template: 'empty',
  sharing: 'public',
  version: 0,
};

export class ProjectStore {
  loaded = false;
  private readonly userId: UserId;
  private projectInfos: ProjectInfo[];
  private collection: string;

  constructor(userId: UserId, private listener: (projectInfos: ProjectInfo[]) => void) {
    this.userId = userId;

    this.projectInfos = [];

    // Notify of initial state.
    this.notifyListener();

    if (userId === 'public') {
      // TODO: replace this with some sort of 'global push' notification
      this.collection = 'publicProjects/';
    } else {
      this.collection = `userPush/${userId}/user_projects`;
    }

    db.ref(this.collection).on('value', this.onProjectsUpdate);

    // Do a refresh here. Normally firebase will do it *unless* the collection above doesn't exist
    this.refresh();
  }

  close() {
    db.ref(this.collection).off('value', this.onProjectsUpdate);
  }

  private getProjectInfo(id: ProjectId): ProjectInfo | undefined {
    const info = this.projectInfos.find((info) => info.id === id);
    return info && { ...info };
  }

  async getProjectInfoAsync(
    userId: UserId,
    projectId: ProjectId,
    options?: { bypassCache?: boolean }
  ): Promise<ProjectInfo | undefined> {
    let projectInfo;
    if (!options?.bypassCache) {
      projectInfo = this.getProjectInfo(projectId);
      if (projectInfo) {
        return projectInfo;
      }
    }
    if (userId !== 'anonymous') {
      try {
        const ret = await apiRequest(`users/${userId}/projects/${projectId}`, {
          method: 'GET',
        });
        projectInfo = (await ret.json()) as ProjectInfo;
        if (projectInfo) {
          this.updateProjectInfoCache(projectInfo);
          return projectInfo;
        }
      } catch (err) {
        // Users that don't own the project fall here and we check if it's public.
      }
    }

    // Try loading by just projectId
    const ret = await apiRequest(`projects/${projectId}`, {
      method: 'GET',
    });
    projectInfo = (await ret.json()) as ProjectInfo;
    this.updateProjectInfoCache(projectInfo);
    return projectInfo;
  }

  updateProjectInfoCache(info: ProjectInfo): void {
    const index = this.projectInfos.findIndex((infoT) => infoT.id === info.id);
    if (index !== -1) {
      this.projectInfos[index] = info;
      this.notifyListener();
    }
  }

  async readProject(info: ProjectInfo, projectResource?: Resource): Promise<ProjectState> {
    const res = projectResource ?? (await Resource.get(info.project));
    return readRemoteProject(res.getDataUrl(PROJECT_JSON));
  }

  // Writes the project state remotely and returns a ProjectInfo with new values.
  async writeProject(
    projectInfo: ProjectInfo,
    state: ProjectState,
    isAdmin: boolean,
    preview?: Promise<Blob | undefined>
  ): Promise<ProjectInfo> {
    // Don't modify the original.
    projectInfo = { ...projectInfo };

    // Write the project's files to remote storage.
    const file = serialize(state);
    const buffer = new TextEncoder().encode(file);
    const res = new Resource(projectInfo.project);

    if (preview) {
      preview.then((blob) => {
        blob && res.uploadDataBlob(PREVIEW, blob);
      });
    }

    const saves: [Promise<ResourceData>, Promise<ProjectInfo>] = [
      // Write the project's files to remote storage.
      res.uploadDataBuffer(PROJECT_JSON, buffer, 'application/json'),
      // Write the project's info to the database.
      this.writeProjectInfo(projectInfo, isAdmin),
    ];

    const [, writtenInfo] = await Promise.all(saves);

    return writtenInfo;
  }

  async deleteProject(id: ProjectId): Promise<void> {
    const info = this.getProjectInfo(id);
    if (info) {
      await apiRequest(`users/${info.owner}/projects/${info.id}`, {
        method: 'DELETE',
      });
    }
  }

  async duplicateProject(
    info: ProjectInfo,
    newOwner: UserId,
    newOwnerName: string,
    isAdmin: boolean
  ): Promise<ProjectInfo> {
    const dupInfo = this.duplicateProjectInfo(info, newOwner, newOwnerName);
    const state = await this.readProject(info);

    // Clear Components exported flags.
    if (state.Components) {
      Object.entries(state.Components).forEach(([key, component]) => {
        component._meta!.exported = false;
      });
    }

    const res = await new Resource(info.project).clone();
    dupInfo.project = res.id;
    return this.writeProject(dupInfo, state, isAdmin);
  }

  duplicateProjectInfo(
    info: ProjectInfo,
    owner: UserId,
    ownerName: string,
    title?: string,
    template = false,
    version: string | undefined = undefined
  ): ProjectInfo {
    if (template) {
      title = suggestTitle(title!, info.title, this.projectInfos);
    } else if (version) {
      // TODO: better new title
      title = info.title + ' ' + version.slice(0, 4);
    } else {
      title = info.title + ' copy';
    }

    const dupInfo: ProjectInfo = {
      id: generateUUID(),
      owner,
      ownerName,
      name: template
        ? suggestName(
            info.name,
            this.projectInfos.map((info) => info.name)
          )
        : info.name,
      title,
      template: template ? info.id : info.template,
      project: info.project,
      version: 0,
      created: 0,
      modified: 0,
      sharing: 'private',
    };

    if (info.tags) {
      // Drop tags we don't want to automatically propagate to duplicated projects.
      dupInfo.tags = cleanTags(info.tags);
    }

    cloneProjectWorkbenchState(info.id, dupInfo.id);

    return dupInfo;
  }

  newProjectInfo(user: User, name: string, title: string): ProjectInfo {
    return {
      id: generateUUID(),
      owner: user.id,
      ownerName: user.name,
      name: suggestName(
        name,
        this.projectInfos.map((info) => info.name)
      ),
      title: suggestTitle(title!, title, this.projectInfos),
      template: '',
      project: 'placeholder',
      version: 0,
      created: 0,
      modified: 0,
      sharing: 'private',
    };
  }

  // TODO: Move publishing to the backend. Can't trust clients to write ProjectInfo.
  async publish(id: ProjectId): Promise<ProjectInfo | undefined> {
    const info = this.getProjectInfo(id);
    if (!info) {
      // TODO: enforce can only publish remotely saved projects
      return;
    }

    await apiRequest(`users/${this.userId}/projects/${info.id}/publish`, {
      method: 'PUT',
    });

    return this.getProjectInfoAsync(this.userId, id, { bypassCache: true });
  }

  async unpublish(id: ProjectId): Promise<void> {
    const info = this.getProjectInfo(id);
    if (!info) {
      // TODO: enforce can only unpublish remotely saved projects
      return;
    }
    await apiRequest(`users/${this.userId}/projects/${info.id}/unpublish`, {
      method: 'PUT',
    });
  }

  async writeProjectInfo(info: ProjectInfo, isAdmin: boolean): Promise<ProjectInfo> {
    if (this.userId === 'anonymous') {
      return info;
    }
    let userId = this.userId;
    if (this.userId !== info.owner) {
      if (isAdmin) {
        userId = info.owner;
      } else {
        return info;
      }
    }
    const ret = await apiRequest(`users/${userId}/projects/${info.id}`, {
      method: 'PUT',
      body: JSON.stringify(info),
    });
    return (await ret.json()) as ProjectInfo;
  }

  async setProjectSharing(info: ProjectInfo, sharing: string, isAdmin: boolean): Promise<void> {
    if (info.sharing === sharing) {
      return;
    }
    info.sharing = sharing;
    await this.writeProjectInfo(info, isAdmin);
  }

  async setProjectLocked(info: ProjectInfo, locked: boolean, isAdmin: boolean): Promise<void> {
    if (info.locked === locked) {
      return;
    }
    info.locked = locked;
    await this.writeProjectInfo(info, isAdmin);
  }

  // Fetch projects from the server
  private async refresh(): Promise<void> {
    const url = this.userId === 'public' ? 'projects' : `users/${this.userId}/projects`;
    const ret = await apiRequest(url, {
      method: 'GET',
    });
    this.projectInfos = await ret.json();
    this.notifyListener();
  }

  private notifyListener(): void {
    this.listener(this.projectInfos);
  }

  // Firebase has told us there's been a update to projects
  private onProjectsUpdate = (snapshot: firebase.database.DataSnapshot): void => {
    this.loaded = true;
    this.refresh();
  };
}

// Fetch project infos by tag
export async function getProjectInfosByTag(tag: string): Promise<ProjectInfo[]> {
  const ret = await apiRequest(`projects?tag=${encodeURIComponent(tag)}`, {
    method: 'GET',
  });
  return await ret.json();
}

function suggestTitle(title: string, template: string, projectInfos: ProjectInfo[]): string {
  const suffix = suggestSuffix(
    template,
    projectInfos.map((info) => info.title),
    false,
    true
  );
  return title + (suffix ? ' ' + suffix : '');
}

// Drop tags we don't want to automatically propagate to duplicated projects.
export function cleanTags(tags: Tags): Tags {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _gallery, _template, _featured, tutorial, dogfood, ...safeTags } = tags;
  return safeTags;
}

export type ComponentEntry = {
  ownerId: UserId;
  projectId: ProjectId;
  projectTitle?: string;
  name: string;
  description?: string;
  author?: string;
  public: boolean;
  tags?: Tags;
};

// Fetch components
export async function getComponents(): Promise<ComponentEntry[]> {
  const ret = await apiRequest('components', {
    method: 'GET',
  });
  return (await ret.json()) as ComponentEntry[];
}
