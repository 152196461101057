import { CircularProgress, Dialog, IconButton, Tooltip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Edit as EditIcon } from '@material-ui/icons';
import type { ProjectInfo } from '@playful/runtime';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import { pushHistoryState } from '../App';
import { getFirebaseAuthToken } from '../firebase';
import { useTitle } from '../hooks/handyHooks';
import type { ProjectStore } from '../project/projectStorage';
import { Avatar } from '../user/Avatar';
import type { PublicUser } from '../user/user';
import { useUserContext } from '../user/UserContext';

const useStyles = makeStyles((theme) =>
  createStyles({
    loading: { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' },
    progress: { margin: theme.spacing(2) },
    controls: {
      zIndex: 100,
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      filter: 'drop-shadow(0px 2px 0px white)',
      pointerEvents: 'none',
    },
    button: { pointerEvents: 'auto' },
    iframe: { border: 0, height: '100%' },
    projectView: { position: 'absolute', width: '100%', height: '100%', overflow: 'auto' },
    attributionTip: { color: theme.palette.secondary.light },
  })
);

export type ProjectPlayerProps = {
  projectInfo: ProjectInfo;
  projectStore: ProjectStore;
  onEdit(projectInfo: ProjectInfo): void;
  onClose(): void;
};

export const ProjectPlayer: React.FC<ProjectPlayerProps> = (props) => {
  const classes = useStyles();
  const { projectInfo, onEdit, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [creator, setCreator] = useState<PublicUser>();
  const { user, getPublicUser, publicUsers, setPublicUsers } = useUserContext();
  const [, setLocation] = useLocation();
  const [token, setToken] = useState<string>();

  useTitle(projectInfo.title);

  // The ProjectPlayer adds itself to the browser history. This function is called by explicit
  // 'close'es to remove it from the history. This covers the cases the brower back button doesn't.
  const popAndClose = () => {
    window.history.back();
    onClose();
  };

  // Add the ProjectPlayer to the browser history so the back button does what people expect.
  useEffect(() => {
    function onPopState(): void {
      onClose();
    }
    window.addEventListener('popstate', onPopState);

    // Display a URL that if copied, shared, and used will bring people to the published project.
    // TODO: ...but it may not be pubished. Maybe we want to come back to viz.site/gallery/<playthisproject>?
    pushHistoryState(undefined, '', `/p/${projectInfo.ownerName}/${projectInfo.id}/`);

    return () => {
      window.removeEventListener('popstate', onPopState);
    };
    // This (must) only execute once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _user = publicUsers[projectInfo.owner];

    if (_user) {
      setCreator(_user);

      return;
    }

    getPublicUser(projectInfo.owner).then((publicUser) => {
      setCreator(publicUser);
      setPublicUsers((publicUsers) => ({ ...publicUsers, [publicUser.id]: publicUser }));
    });
  }, [projectInfo.owner, setPublicUsers, publicUsers, getPublicUser]);

  useEffect(() => {
    getFirebaseAuthToken().then((token) => setToken(token));
  });

  return (
    <Dialog fullScreen open={true} onClose={popAndClose}>
      <div className={classes.controls}>
        <Tooltip
          placement='bottom'
          interactive
          title={
            <>
              Created by{' '}
              <a className={classes.attributionTip} href={`/@${projectInfo.ownerName}`}>
                {projectInfo.ownerName}
              </a>
            </>
          }
        >
          <IconButton className={classes.button} onClick={() => setLocation(`/@${creator?.name}`)}>
            <Avatar user={creator} />
          </IconButton>
        </Tooltip>
        {(projectInfo.owner === user.id || projectInfo.sharing === 'public') && (
          <Tooltip placement='bottom' title='Open in Play'>
            <IconButton
              className={classes.button}
              onClick={() => onEdit(projectInfo)}
              aria-label='edit'
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip placement='bottom' title='Close'>
          <IconButton className={classes.button} onClick={() => popAndClose()} aria-label='close'>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </div>
      {isLoading && (
        <div className={classes.loading}>
          <CircularProgress className={classes.progress} />
        </div>
      )}
      <iframe
        data-cy={token ? 'project-player' : undefined}
        title={projectInfo.title}
        className={classes.iframe}
        src={
          token ? `/p/${projectInfo.ownerName}/${projectInfo.id}/?chrome=false&auth=${token}` : ''
        }
        style={{ display: isLoading ? 'none' : 'block' }}
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </Dialog>
  );
};

export default ProjectPlayer; // For lazy loading.
