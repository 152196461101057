import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import type { ProjectInfo, Tags } from '@playful/runtime';
import React, { useState } from 'react';

interface EditTagsDialogProps {
  projectInfo: ProjectInfo;
  onClose(confirmed: boolean): void;
}

// These tags are hidden from the user
const internalTags: string[] = [];

export const TagsDialog: React.FC<EditTagsDialogProps> = (props) => {
  const { projectInfo, onClose } = props;

  let projectTags: string[] = [];
  if (projectInfo.tags) {
    projectTags = Object.keys(projectInfo.tags)
      .filter((tag) => !internalTags.includes(tag)) // Filter out internal tags
      .filter((tag) => projectInfo.tags![tag]); // Filter any 'false' tags. Ideally, this wouldn't happen, but TypeScript only goes so far
  }
  const [tags, setTags] = useState(projectTags);

  const tagChoices = projectTags;

  // Thanks https://stackoverflow.com/a/59038435/707320.
  const onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Tab':
      case ',':
      case ' ': {
        event.preventDefault();
        event.stopPropagation();
        const value = (event.target as HTMLTextAreaElement).value;
        if (value.length > 0) {
          setTags([...tags, value]);
        }
        break;
      }
      default:
    }
  };

  return (
    <Dialog
      open={true}
      onClose={() => onClose(false)}
      aria-labelledby='set-tags-dialog-title'
      aria-describedby='set-tags-dialog-description'
    >
      <DialogTitle id='set-tags-dialog-title'>Assign tags to {projectInfo.title}</DialogTitle>
      <DialogContent>
        <Autocomplete
          multiple
          disableClearable
          freeSolo
          size='small'
          options={tagChoices}
          value={tags}
          getOptionLabel={(tag) => tag}
          noOptionsText='No tags'
          renderInput={(params) => {
            (params.inputProps as any).onKeyDown = onKeyDown;
            return <TextField {...params} autoFocus variant='standard' label='Tags' />;
          }}
          onChange={(event, value) => setTags(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            // TODO: somehow capture the common case of typing a tag and clicking save (no enter/tab/space/comma).
            setProjectInfoTags(projectInfo, tags);
            onClose(true);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function setProjectInfoTags(projectInfo: ProjectInfo, tags: string[]): void {
  // Convert to dict
  const oldTags = projectInfo.tags || {};
  const newTags: Tags = {};
  tags
    .filter((tag) => !internalTags.includes(tag)) // Filter out internal tags
    .forEach((tag) => {
      newTags[tag] = true;
    });

  // Copy any internal tags from old -> new
  internalTags.forEach((tag) => {
    if (oldTags[tag]) {
      newTags[tag] = true;
    }
  });

  // Save it.
  projectInfo.tags = newTags;
}

// Convert query arg tags=<tag1>,<tag2>,... into [ "<tag1>, "<tag2>", ...].
export function getTagsFromQueryString(): string[] {
  const queryParams = new URL(window.location.href).searchParams;
  const tags = queryParams.get('tags');
  if (!tags) return [];
  return tags.split(',');
}

// Return true if the TagObject has all the tags listed.
export function hasMatchingTags(tags: string[], tagObject: Tags | undefined): boolean {
  if (!tagObject) {
    return false;
  }
  for (const tag of tags) {
    if (!tagObject[tag]) {
      return false;
    }
  }
  return true;
}
