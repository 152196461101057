import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  WithMobileDialog,
  withMobileDialog,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import type { ProjectInfo } from '@playful/runtime';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import { useStyles as useProjectCardStyles } from './explorer/ProjectCard';
import { useStyles as useProjectListStyles } from './explorer/ProjectList';
import { useResourceDataUrl } from './hooks/useResource';
import { emptyProjectInfo, getProjectInfosByTag } from './project/projectStorage';
import { PREVIEW } from './resources';

const useStyles = makeStyles((theme) =>
  createStyles({
    loading: {
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      margin: theme.spacing(2),
    },
  })
);

interface ChooseTemplateDialogProps extends WithMobileDialog {
  onDone(projectInfo?: ProjectInfo, title?: string): void;
}

const ChooseTemplateDialog: React.FC<ChooseTemplateDialogProps> = (props) => {
  const { fullScreen, onDone } = props;
  const classes = useStyles();
  const listClasses = useProjectListStyles();
  const [projectInfos, setProjectInfos] = useState<ProjectInfo[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getProjectInfosByTag('_template').then((infos) => {
      // Filter out welcome templates.
      infos = (infos ?? []).filter((info) => !info.tags!['welcome']);

      // Provide an empty project if there are no template projects.
      if (infos.length === 0) {
        infos.unshift(emptyProjectInfo);
      }

      setProjectInfos(infos);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        open={true}
        onClose={() => onDone()}
        aria-labelledby='form-dialog-title'
        /*PaperComponent={DraggablePaper}*/
      >
        <DialogTitle>Choose a template to begin with</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div className={classes.loading}>
              <CircularProgress className={classes.progress} />
            </div>
          ) : (
            <div className={listClasses.root}>
              {projectInfos.map((info) => (
                <TemplateCard
                  key={info.id}
                  projectInfo={info}
                  onDone={(info) => onDone(info, info.title)}
                />
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => onDone()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

interface ChooseTemplateProps {
  onDone(projectInfo?: ProjectInfo, title?: string): void;
}

const MobileChooseTemplateDialog = withMobileDialog({ breakpoint: 'xs' })(ChooseTemplateDialog);
export default MobileChooseTemplateDialog;

export const ChooseTemplate: React.FC<ChooseTemplateProps> = (props) => {
  return <MobileChooseTemplateDialog onDone={props.onDone} />;
};

export interface TemplateCardProps {
  projectInfo: ProjectInfo;
  onDone(projectInfo: ProjectInfo): void;
}

export const TemplateCard: React.FC<TemplateCardProps> = (props) => {
  const { projectInfo, onDone } = props;
  const classes = useProjectCardStyles();
  const { url: previewUrl } = useResourceDataUrl(projectInfo.project, PREVIEW);

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      <div className={clsx(classes.imageHolder, classes.grow)} onClick={() => onDone(projectInfo)}>
        {previewUrl && (
          <img
            src={previewUrl}
            className={classes.image}
            title={projectInfo.title}
            alt={projectInfo.title}
          />
        )}
      </div>
      <div className={classes.titleBar}>
        <div className={classes.titleWrap}>
          <div onClick={() => onDone(projectInfo)}>{projectInfo.title}</div>
        </div>
      </div>
    </div>
  );
};
