import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import type { ProjectInfo } from '@playful/runtime';
import React, { useEffect, useState } from 'react';

import type { ProjectStore } from '../project/projectStorage';
import type { PublicUser } from '../user/user';
import { useUserContext } from '../user/UserContext';
import { ProjectCard, titleHeight } from './ProjectCard';
import { ProjectPlayer } from './ProjectPlayer';
import { TagsDialog, hasMatchingTags } from './Tags';

export const projectPreviewWidth = 144;
export const largerPreviewWidth = 170;
export const smallScreenThreshold = 361;

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, ${projectPreviewWidth}px)`,
      gridAutoRows: `${projectPreviewWidth + titleHeight + 8}px`,
      [theme.breakpoints.up(smallScreenThreshold)]: {
        gridTemplateColumns: `repeat(auto-fill, ${largerPreviewWidth}px)`,
        gridAutoRows: `${largerPreviewWidth + titleHeight + 8}px`,
      },
      gridGap: theme.spacing(2),
      padding: 0,
      justifyContent: 'center',
      gridAutoFlow: 'dense',
      //backgroundColor: theme.palette.background.paper,
    },
    loading: {
      display: 'flex',
      justifyContent: 'center',
    },
    progress: {
      margin: theme.spacing(2),
    },
  })
);

export interface ProjectListProps {
  primaryAction?: 'edit' | 'view';
  projectInfos: ProjectInfo[];
  projectStore: ProjectStore;
  tags?: string[];
  style?: React.CSSProperties;
  onEdit(projectInfo: ProjectInfo, version?: string): void;
  onDuplicate(projectInfo: ProjectInfo): void;
  onPublish?(projectInfo: ProjectInfo): void;
  onUnpublish?(projectInfo: ProjectInfo): void;
  onSetSharing?(projectInfo: ProjectInfo, sharing: string): void;
  onSetLocked?(projectInfo: ProjectInfo, locked: boolean): void;
  onRename?(projectInfo: ProjectInfo): void;
  onDelete?(projectInfo: ProjectInfo): void;
  onExport?(projectInfo: ProjectInfo): void;
  onSetTags?(projectInfo: ProjectInfo): void;
  publicUser?: PublicUser;
  onSetProfileProject?(projectInfo: ProjectInfo): void;
  onUnsetProfileProject?(projectInfo: ProjectInfo): void;
}

export const ProjectList: React.FC<ProjectListProps> = (props) => {
  const {
    primaryAction = 'view',
    projectInfos,
    projectStore,
    tags,
    style,
    onEdit,
    onPublish,
    onUnpublish,
    onDuplicate,
    onSetSharing,
    onSetLocked,
    onRename,
    onDelete,
    onExport,
    onSetTags,
    publicUser,
    onSetProfileProject,
    onUnsetProfileProject,
    children,
  } = props;
  const classes = useStyles();
  const { isAdmin } = useUserContext();
  const [deleteProjectInfo, setDeleteProjectInfo] = useState<ProjectInfo | undefined>();
  const [renameProjectInfo, setRenameProjectInfo] = useState<ProjectInfo | undefined>();
  const [editTagsProjectInfo, setEditTagsProjectInfo] = useState<ProjectInfo | undefined>();
  const [setTagsProjectInfo, setSetTagsProjectInfo] = useState<ProjectInfo | undefined>();
  const [confirmDeleteProjectInfo, setConfirmDeleteProjectInfo] = useState<
    ProjectInfo | undefined
  >();
  const [playProjectInfo, setPlayProjectInfo] = useState<ProjectInfo>();
  const [ioError, setIoError] = useState('');

  useEffect(() => {
    if (deleteProjectInfo) {
      setDeleteProjectInfo(undefined);
      projectStore.deleteProject(deleteProjectInfo.id).catch((err) => {
        setIoError(err.toString());
      });
      onDelete?.(deleteProjectInfo);
    }
  }, [projectStore, projectInfos, deleteProjectInfo, onDelete]);

  useEffect(() => {
    if (setTagsProjectInfo) {
      setSetTagsProjectInfo(undefined);
      projectStore.writeProjectInfo(setTagsProjectInfo, isAdmin).catch((err) => {
        setIoError(err.toString());
      });
      onSetTags?.(setTagsProjectInfo);
    }
  }, [projectStore, projectInfos, setTagsProjectInfo, onSetTags, isAdmin]);

  useEffect(() => {
    if (renameProjectInfo) {
      setRenameProjectInfo(undefined);
      projectStore.writeProjectInfo(renameProjectInfo, isAdmin).catch((err) => {
        setIoError(err.toString());
      });
      onRename?.(renameProjectInfo);
    }
  }, [projectStore, renameProjectInfo, onRename, isAdmin]);

  return (
    <div style={style}>
      {ioError && <div>{ioError}</div>}
      {playProjectInfo && (
        <ProjectPlayer
          projectInfo={playProjectInfo}
          projectStore={projectStore}
          onClose={() => setPlayProjectInfo(undefined)}
          onEdit={() => {
            setPlayProjectInfo(undefined);
            onEdit(playProjectInfo);
          }}
        />
      )}
      {deleteProjectInfo && (
        <Dialog
          open={true}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Deleting {deleteProjectInfo.title}</DialogTitle>
          <DialogContent>
            <div className={classes.loading}>
              <CircularProgress className={classes.progress} />
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div className={classes.root}>
        {children}
        {projectInfos
          // Filter down to matching tags. Don't filter at all if no tags specified.
          .filter(
            (info) => tags === undefined || tags.length === 0 || hasMatchingTags(tags, info.tags)
          )
          .map((info) => (
            <ProjectCard
              key={info.id}
              primaryAction={primaryAction}
              projectInfo={info}
              onEdit={onEdit}
              onPlay={(projectInfo) => setPlayProjectInfo(projectInfo)}
              onDelete={onDelete && onDeleteProject}
              onPublish={onPublish}
              onUnpublish={onUnpublish}
              onRename={onRename && onRenameProject}
              onDuplicate={onDuplicate}
              onSetSharing={onSetSharing}
              onSetLocked={onSetLocked}
              onExport={onExport}
              onEditTags={onSetTags && onEditTags}
              publicUser={publicUser}
              onSetProfileProject={onSetProfileProject}
              onUnsetProfileProject={onUnsetProfileProject}
            />
          ))}
      </div>
      {confirmDeleteProjectInfo && (
        <ConfirmDeleteDialog
          projectInfo={confirmDeleteProjectInfo}
          onClose={onConfirmDeleteDialogClose}
        />
      )}
      {editTagsProjectInfo && (
        <TagsDialog projectInfo={editTagsProjectInfo} onClose={onEditTagsDialogClose} />
      )}
    </div>
  );

  function onDeleteProject(projectInfo: ProjectInfo): void {
    setConfirmDeleteProjectInfo(projectInfo);
  }

  function onRenameProject(projectInfo: ProjectInfo): void {
    setRenameProjectInfo(projectInfo);
  }

  function onEditTags(projectInfo: ProjectInfo): void {
    setEditTagsProjectInfo(projectInfo);
  }

  function onConfirmDeleteDialogClose(confirm: boolean): void {
    setConfirmDeleteProjectInfo(undefined);
    if (confirm) {
      setDeleteProjectInfo(confirmDeleteProjectInfo);
    }
  }

  function onEditTagsDialogClose(confirm: boolean): void {
    setEditTagsProjectInfo(undefined);
    if (confirm) {
      setSetTagsProjectInfo(editTagsProjectInfo);
    }
  }
};

interface ConfirmDeleteDialogProps {
  projectInfo: ProjectInfo;
  onClose(confirmed: boolean): void;
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = (props) => {
  const { projectInfo, onClose } = props;

  return (
    <Dialog
      open={true}
      onClose={() => onClose(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Delete {projectInfo.title}?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>This can not be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant='contained' onClick={() => onClose(true)} color='primary' autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
