import type { ChangeEvent, FocusEvent } from 'react';

import type { Component as PlayComponent } from './component';
import { getConfig } from './config';
import type { Properties, Reactor, ReactorId, ReactorObjectBase } from './reactor';
import type { CreateReactorOptions, ReactorFactory } from './reactorFactory';
import type { IWorkbench } from '.';

export type Component = PlayComponent;

export type Point = { x: number; y: number };

export type Rectangle = { x: number; y: number; w: number; h: number };

// Of the format "#rrggbb" or "#rrggbbaa"
export type Color = string;

// Accepts project resource ids, possibly prefixed with "resource:" and returns
// a URL the resource can be fetched from.
// TODO: move to frontend/resources.ts
export function getResourceUrl(resId: string, key: string | null = '', secret = ''): string {
  if (resId.startsWith('resource:')) {
    resId = resId.split(':', 2)[1];
  }
  const apiRoot = getConfig().apiRoot;
  if (secret !== '') {
    resId += '.' + secret;
  }

  const dataPath = key === null ? '' : `/data/${key}`;

  return `${apiRoot}/resources/${resId}${dataPath}`;
}

export function isOnlySlide(reactor: Reactor): boolean {
  const slides = reactor.project?.slides as Reactor[];
  if (!slides) {
    return false;
  }
  return slides.length === 1 && slides[0] === reactor;
}

export type Tags = { [tag: string]: true | null };

// TODO: how to share this type with server?
// TODO: replace name, title with manifest.name?
export type ProjectInfo = {
  id: ProjectId; // Unique across all projects
  owner: UserId; // User id of the project's owner
  ownerName: string; // Username of the project's owner
  name: string;
  title: string;
  template: ProjectId; // Project id of the template this project is derived from
  created: number; // Timestamp (e.g. Date.now())
  modified: number; // Timestamp (e.g. Date.now())
  project: ResourceId; // ResourceId of the project data
  sharing: string; // 'public' | 'private'. TODO: 'userA,userB,userC'
  version: number; // Incremented at save time
  publishedProject?: ResourceId; // ResourceId of the published project data
  publishedVersion?: number;
  published?: number; // A timestamp, like created, modified.
  // Array of ResourceIds of previous (and current) published projects in time ascending order.
  publishedHistory?: string[];
  // TODO: publishedDate?: string[]; // to published as publishedHistory is to publishedProject
  // TODO: create an index for important known tags and at some point maintain a tag->projectId mapping.
  // Reserved tags: _template, _gallery, kit
  tags?: Tags;
  manifest?: WebAppManifest;
  locked?: boolean;
};

export type ImportInfo = {
  // Stored properties.
  name: string;
  // <"http" | "https" | "project" | "library">:<path>
  source: string;
  projectVersion?: number;
  projectResource?: string;
  projectOwner?: string; // TODO: remove once projects aren't nested under owner
  projectId?: string;
  importedComponents?: string[];

  // Runtime properties.
  promise?: Promise<any>;
  module?: any;
  importedProject?: Project;
  importedName?: string;
};

export type ProjectId = string;
export type ResourceId = string;
export type UserId = string;

export interface Workbench {}

// Progressive Web Application (PWA) stuff
// TODO: would be nice to get this from some place official
type TextDirection = 'auto' | 'ltr' | 'rtl';
type DisplayMode = 'browser' | 'fullscreen' | 'standalone' | 'minimal-ui';

type ImageResource = {
  src?: string;
  sizes?: string;
  type?: string;
  purpose?: string;
  platform?: string;
};

type ExternalApplicationResource = {
  platform?: string;
  url?: string;
  id?: string;
  min_version?: string;
  fingerprints?: Fingerprint[];
};

type Fingerprint = {
  type: string;
  value: string;
};

type ShortcutItem = {
  name: string;
  short_name?: string;
  description?: string;
  url: string;
  icons?: ImageResource[];
};

type WebAppManifest = {
  dir?: TextDirection;
  lang?: string;
  name?: string;
  short_name?: string;
  description?: string;
  icons?: ImageResource[];
  screenshots?: ImageResource[];
  categories?: string[];
  iarc_rating_id?: string;
  start_url?: string;
  display?: DisplayMode;
  orientation?: OrientationLockType;
  theme_color?: string;
  background_color?: string;
  scope?: string;
  related_applications?: ExternalApplicationResource[];
  prefer_related_applications?: boolean;
  shortcuts?: ShortcutItem[];
};

export type ProjectState = {
  runtimeVersion?: number;
  manifest?: WebAppManifest;
  Imports?: ImportInfo[];
  Components?: { [type: string]: Component };
};

// TODO: Eliminate frontend dependencies on Slide
export type Slide = Component & { width: number; height: number };

export interface Project extends ReactorObjectBase {
  viewportWidth: number;
  viewportHeight: number;
  playSurfaceWidth: number;
  playSurfaceHeight: number;

  reactorFactory: ReactorFactory;
  mainProject: Project;
  designMode: boolean;
  thumbnailMode: boolean;
  resourceRoot: string; // root path for all resource loading
  readonly info: ProjectInfo;

  workbench?: IWorkbench; // Only available at design time.

  manifest?: WebAppManifest;
  Imports?: ImportInfo[];
  imports: Reactor;
  Components?: { [type: string]: Component };

  initialize(reactorFactory: ReactorFactory, workbench?: Workbench): Promise<void>;
  createReactor<T extends Reactor>(properties?: Properties, options?: CreateReactorOptions): T;
  getReactorById(id: ReactorId): Reactor;
  hasReactor(id: ReactorId): boolean;
  getReactorByPath(path: string): Reactor | undefined;
  forEachReactor(callback: (reactor: Reactor) => boolean): void;
  dispose(): void;

  // TODO: move these to a View object?
  mount(container: HTMLElement): void;
  unmount(): void;
  toCanvas(context: CanvasRenderingContext2D): Promise<void>;
}

export type ResourceSource = string; // Access string in the format of `resource:<resourceKey>`

export interface IResource {
  readonly id: ResourceId;
  secret?: string;
  name?: string;
  keys: Array<ResourceData>;
  created: Date;
  modified: Date;
  getData(key: 'orig' | 'thumb'): ResourceData | undefined;
  getDataUrl(key: 'orig' | 'thumb'): string;
}

export interface ResourceData {
  key: string;
  mimeType?: string;
  size?: number;
  hash?: string;
  url?: string; // Data URL, probably a blob:
  progress?: number; // Upload progress
}

// TODO is this the right place for these?
export interface FormValidationChange {
  name: string;
  value: any;
}

export type BlurHandlerEventType =
  | FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  | FormValidationChange;

export type ChangeHandlerEventType =
  | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  | FormValidationChange;

export type BlurHandlerType = (event: BlurHandlerEventType) => void;
export type ChangeHandlerType = (event: ChangeHandlerEventType) => void;
