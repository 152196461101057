import { Action, Interaction, Variant, makeVariant } from '@playful/playkit/interaction';

import { eventNameFromHandlerName, promoteToComponent } from './component';
import { importProjectsAndModules } from './importer';
import { ID, Reactor, qualifyComponentType } from './reactor';
import { createReactorFactory } from './reactorFactory';
import { updateMethodsObject } from './reactorObject';
import type { Project, ProjectInfo, ProjectState } from './runtime';
import { isObjectOrArray } from './util';

export const currentRuntimeVersion = 7;

type Writable<T> = { -readonly [K in keyof T]: T[K] };

export async function loadProject(
  state: ProjectState,
  info?: ProjectInfo,
  mainProject?: Project,
  resourceRoot?: string
): Promise<Project> {
  // Calculate the max in-use reactor id
  let maxReactorId = 0;
  forEachObject(state, (obj) => {
    if (obj[ID] > maxReactorId) {
      maxReactorId = obj[ID];
    }
  });

  // Arrays in old (Deck) projects don't have ids and rely on a nextReactorId to know where new ids start.
  const reactorFactory = createReactorFactory(maxReactorId + 1);

  // The first created object is considered the "project" and augmented with ReactorFactory methods.
  // TODO: maybe not and leave it to global Reactor object?

  // newReactors will have all ReactorObjects in the tree, depth-first order.
  const newReactors: Reactor[] = [];
  const project = reactorFactory.createReactor<Project>(state, {
    contextualName: info?.title || '!',

    // Capture newly created Reactors so their prototypes can be set after all imports
    // have been imported.
    updateMethods(reactor: Reactor) {
      newReactors.push(reactor);
    },
  });
  project.reactorFactory = reactorFactory;

  // All projects have a reference to the main (root/host/global/etc) project.
  project.mainProject = mainProject || project;
  project.resourceRoot = resourceRoot || mainProject?.resourceRoot || '';

  // TODO:
  if (info) {
    // Funky cast to override readonly.
    (project as Writable<Project>).info = { ...info }; // TODO: not updated as info changes (e.g. after Project save)
  }

  // Wait for all imports before updating Reactor prototypes which may come from them.
  await Promise.allSettled(importProjectsAndModules(project));

  // Add a methods object for each Reactor. Link Reactors to their Components and prototypes.
  for (const reactor of newReactors) {
    if (reactor.$componentType) {
      promoteToComponent(reactor, project);
    }
    reactor[updateMethodsObject]();

    // Now that all initial values, methods, inheritance, etc are set let the Component
    // further initialize itself.
    if (reactor.$componentType) {
      reactor.init?.();
    }
  }

  return project;
}

// migrateProject alters the ProjectState in place.
export async function migrateProject(state: ProjectState): Promise<ProjectState> {
  const runtimeVersion = state.runtimeVersion || 0;

  // Migrations for projects from before we recorded a runtimeVersion.
  if (runtimeVersion === 0) {
    // If this Project imports "Slide Kit" then it is in need of migration.
    // TODO: Delete this when they're all migrated.
    if (state.Imports) {
      // Remove Slide Kit import, if any.
      const filteredImports = state.Imports?.filter(
        (importInfo) => importInfo.name !== 'Slide Kit'
      );
      if (filteredImports.length !== state.Imports.length) {
        console.log('Migrating from Slide Kit to Play Kit');

        // Replace the Slide Kit import with a Play Kit import.
        state.Imports = [{ name: 'Play Kit', source: 'library:/dist/playkit/index.js' }].concat(
          filteredImports
        );

        // Map each Slide Kit component to its Play Kit equivalent.
        forEachObject(state, (obj) => {
          if (obj.prototype?.startsWith('Slide Kit/')) {
            const [, componentName] = obj.prototype.split('/', 2);
            obj.type = `Play Kit/${componentName}`;
            delete obj.prototype;
          }
        });
      }
    }

    forEachObject(state, (obj) => {
      // A few projects have Component.type in the form of "import.component" and now
      // we prefer "import/component".
      if (obj.type) {
        const [importPath, componentName] = obj.type.split('.', 2);
        if (importPath && componentName) {
          obj.type = qualifyComponentType(importPath, componentName);
        }
      }

      // Migrate Component "type" property to "componentType".
      if (obj.type) {
        // Guard against Reactors that have "type" properties but aren't Components.
        if ((obj.type as string).includes('/')) {
          obj.componentType = obj.type;
          delete obj.type;
        }
      }
    });
  }

  if (runtimeVersion <= 1) {
    // At one time Slide Kit used project.slideWidth/Height to set define the dimensions
    // of new Slide instances. No more!
    delete (state as any).slideWidth;
    delete (state as any).slideHeight;
  }

  if (runtimeVersion === 1) {
    // Migrate Components.
    const Components = state.Components;
    if (Components) {
      Object.keys(Components).forEach((componentName) => {
        // Rasnfrasn ids.
        if (componentName === 'id') return;

        const description = Components[componentName];

        // The Composite Wrapper has been renamed from "Wrapper" to "Composite".
        if (description.extends === 'Play Kit/Wrapper') {
          description.extends = 'Play Kit/Composite';
        }

        // For a brief interval during runtimeVersion 1 Components were defined as
        // ComponentDescription instances. Now they are full-on Container component
        // instances with ComponentDescription fields included.
        if (!description.componentType) {
          console.log(`  Migrating Composite Component "${componentName}" to be a Container`);
          const component = description;
          component.componentType = 'Play Kit/CompositeMaster';
          component.extends = 'Play Kit/Composite';
          component.name = componentName;
          // Provide x,y coords so master resizing doesn't get confused.
          component.x = 0;
          component.y = 0;
          component.width = component.properties?.width?.default || 50;
          component.height = component.properties?.height?.default || 50;
        }
      });
    }
  }

  // Convert _projectHash to projectResource
  if (runtimeVersion <= 2) {
    state.Imports = state.Imports?.map((importInfo) => {
      const projectHash = (importInfo as any)._projectHash;
      if (projectHash) {
        importInfo.projectResource = projectHash;
        delete (importInfo as any)._projectHash;
      }
      return importInfo;
    });
  }

  // Convert playkit import to be 'internal'
  if (runtimeVersion <= 3) {
    state.Imports = state.Imports?.map((importInfo) => {
      if (importInfo.name === 'Play Kit') {
        importInfo.source = 'playkit';
      }
      return importInfo;
    });
  }

  // Convert meta.actions to meta.commands
  if (runtimeVersion <= 4) {
    forEachObject(state, (obj) => {
      if (obj._meta && obj._meta.actions) {
        obj._meta.commands = obj._meta.actions;
        delete obj._meta.actions;
      }
    });
  }

  // Convert meta.actions to meta.commands
  if (runtimeVersion <= 5) {
    // Look for ComponentMasters with defined properties
    forEachObject(state, (obj) => {
      if (obj.componentType !== 'Play Kit/CompositeMaster') {
        return;
      }
      // Not needed anymore
      delete obj.extends;

      // Move these
      obj._meta ||= {
        description: obj.description,
        properties: obj.properties,
        events: obj.events,
      };
      delete obj.description;
      delete obj.properties;
      delete obj.events;
      delete obj.x;
      delete obj.y;
    });
  }

  // Convert event handlers
  if (runtimeVersion <= 6) {
    forEachObject(state, (obj) => {
      if (!obj.componentType) {
        return;
      }

      obj.eventHandlers ||= {};
      for (const prop in obj) {
        // Identify event handler methods
        if (prop.match(/^on[A-Z][a-zA-Z]+$/)) {
          const eventName = eventNameFromHandlerName(prop);
          obj.eventHandlers[eventName] = obj[prop];
          delete obj[prop];
        }
      }
    });
  }

  // Convert Interaction Action arguments to be Variants.
  if (runtimeVersion <= 7) {
    let key = 1;

    function migrateActions(actions: Action[]) {
      for (const action of actions) {
        if (!action.key) {
          action.key = (key++).toString();
        }
        if (action.args) {
          const args = action.args;
          for (const argName in args) {
            const value = args[argName] as any;
            if (value.type !== undefined) {
              // Already a Variant.
              if ((value as Variant).type === 'actions') {
                migrateActions(value.value);
              }
            } else {
              args[argName] = makeVariant(value);
            }
          }
        } else {
          // The args object isn't optional as it once was.
          action.args = {};
        }
      }
    }

    forEachObject(state, (obj) => {
      if (!obj.componentType || !obj.interactions) {
        return;
      }

      for (const interaction of obj.interactions as Interaction[]) {
        if (!interaction.key) {
          interaction.key = (key++).toString();
        }
        migrateActions(interaction.actions);
      }
    });
  }

  state.runtimeVersion = currentRuntimeVersion;

  return state;
}

// Iterate and recurse down an (acyclic) object hierarchy, calling back for each one.
function forEachObject(obj: any, callback: (obj: any) => void): void {
  if (Array.isArray(obj)) {
    for (const element of obj) {
      if (isObjectOrArray(element)) {
        forEachObject(element, callback);
      }
    }
  } else {
    for (const property in obj) {
      const value = obj[property];
      if (isObjectOrArray(value)) {
        forEachObject(value, callback);
      }
    }
  }
  callback(obj);
}

export function isProject(component: any): component is Project {
  return component.mainProject && component.mainProject === component;
}
