/**
 * Add any [key: string]: boolean value here to create a new feature flag.
 * Adding a new flag here will automatically add a menu item in the user menu
 * which allows a user to toggle the flag.
 */
export const InitialUserFeatures = {
  developerMode: true,
  experiments: false,
  codeFeatures: false,
};

export type UserFeatures = typeof InitialUserFeatures;

export const InitialUserClaims = {
  approved: false,
  admin: false,
};

export type UserClaims = typeof InitialUserClaims;
