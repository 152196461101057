import {
  Divider,
  IconButton,
  Input,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  AccountCircle as AccountCircleIcon,
  Close as CloseIcon,
  CloudDownload as CloudDownloadIcon,
  Cloud as CloudIcon,
  CloudOff as CloudOffIcon,
  Delete as DeleteIcon,
  FileCopy as DuplicateIcon,
  Edit as EditIcon,
  History as HistoryIcon,
  LocalOffer as LocalOfferIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  MoreVert as MoreVertIcon,
  Person as PrivateIcon,
  Public as PublicIcon,
  Share as ShareIcon,
  Visibility as ViewIcon,
} from '@material-ui/icons';
import type { ProjectInfo } from '@playful/runtime';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';

import { appName } from '../App';
import { isSmallScreen } from '../components/componentUtil';
import NestedMenuItem from '../components/NestedMenuItem';
import { useResourceDataUrlAuth } from '../hooks/useResource';
import { PREVIEW } from '../resources';
import type { PublicUser } from '../user/user';
import { largerPreviewWidth, projectPreviewWidth, smallScreenThreshold } from './ProjectList';

export const titleHeight = 24;

export const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      transition: 'all .1s ease-in-out',
      '&:hover': {
        transform: 'scale(1.15)',
      },
    },
    imageHolder: {
      width: projectPreviewWidth,
      height: projectPreviewWidth,
      [theme.breakpoints.up(smallScreenThreshold)]: {
        width: largerPreviewWidth,
        height: largerPreviewWidth,
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      display: 'block',
    },
    titleBar: {
      marginTop: '4px',
      position: 'absolute',
      left: 0,
      right: 0,
      height: titleHeight,
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
    },
    titleWrap: {
      flexGrow: 1,
      marginLeft: 8,
      marginRight: 0,
      color: theme.palette.common.black,
      overflow: 'hidden',
      cursor: 'pointer',
    },
    subtitle: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    actionIcon: {},
    menuButton: {
      padding: '4px 0px 0px 0px',
      margin: 0,
      color: theme.palette.common.black,
    },
    renameCancelButton: {
      padding: '4px 0px 0px 0px',
      margin: 0,
      color: theme.palette.common.black,
    },
    renameInput: {
      // TODO: to match what's set on the body. Is there a better way to inherit them?
      fontSize: '0.875rem',
      letterSpacing: '0.01071em', // TODO: not having an effect
    },
    attributionTip: {
      color: theme.palette.secondary.light,
    },
  })
);

export interface ProjectCardProps {
  primaryAction?: 'edit' | 'view';
  projectInfo: ProjectInfo;
  onEdit(projectInfo: ProjectInfo, version?: string): void;
  onPlay(projectInfo: ProjectInfo): void;
  onDuplicate(projectInfo: ProjectInfo): void;
  onDelete?(projectInfo: ProjectInfo): void;
  onPublish?(projectInfo: ProjectInfo): void;
  onUnpublish?(projectInfo: ProjectInfo): void;
  onRename?(projectInfo: ProjectInfo): void;
  onSetSharing?(projectInfo: ProjectInfo, sharing: string): void;
  onSetLocked?(projectInfo: ProjectInfo, locked: boolean): void;
  onExport?(projectInfo: ProjectInfo): void;
  onEditTags?(ProjectInfo: ProjectInfo): void;
  publicUser?: PublicUser;
  onSetProfileProject?(projectInfo: ProjectInfo): void;
  onUnsetProfileProject?(projectInfo: ProjectInfo): void;
}

export const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const {
    primaryAction = 'view',
    projectInfo,
    onEdit,
    onPlay,
    onDelete,
    onPublish,
    onUnpublish,
    onRename,
    onDuplicate,
    onSetSharing,
    onSetLocked,
    onExport,
    onEditTags,
    publicUser,
    onSetProfileProject,
    onUnsetProfileProject,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [renaming, setRenaming] = useState(false);

  const url = `/p/${projectInfo.ownerName}/${projectInfo.id}/`;

  const share:
    | ((data: { url: string; text: string; title: string }) => void)
    | undefined = (window.navigator as any).share;
  //const subtitle = 'how does it look with a subtitle?';

  const renameInputRef = useCallback<(el: HTMLInputElement) => void>((input) => {
    if (input) {
      input.select();
    }
  }, []);

  // TODO: loading and error images
  const { url: previewUrl } = useResourceDataUrlAuth(projectInfo.project, PREVIEW);

  const isProfileProject =
    publicUser?.profileProject && publicUser.profileProject === projectInfo.id;

  // The tooltip doesn't interact well with touch-scrolling so don't show it on mobile.
  const tooltipTitle = isSmallScreen() ? (
    ''
  ) : (
    <>
      by{' '}
      <a className={classes.attributionTip} href={`/@${projectInfo.ownerName}`}>
        {projectInfo.ownerName}
      </a>
    </>
  );

  return (
    <Tooltip
      placement='bottom'
      // Control attribution tooltip positioning to be within the card, above the title.
      PopperProps={{
        modifiers: {
          flip: { enabled: false },
          hide: { enabled: false },
          preventOverflow: { enabled: false },
          offset: { enabled: true, offset: '0, -65px' },
        },
      }}
      interactive
      title={tooltipTitle}
    >
      <div style={{ height: '100%', position: 'relative' }} data-cy='project-card'>
        <div
          className={clsx(classes.imageHolder, classes.grow)}
          onClick={primaryAction === 'edit' ? onEditClick : onPlayClick}
          data-cy={primaryAction === 'edit' ? 'edit-project' : 'play-project'}
          data-cy-published={projectInfo.published! !== 0}
          data-cy-owner={projectInfo.owner}
          data-cy-title={projectInfo.title}
        >
          {previewUrl && (
            <img
              src={previewUrl}
              className={classes.image}
              alt={projectInfo.title}
              loading='lazy'
            />
          )}
        </div>
        <div className={classes.titleBar}>
          <div className={classes.titleWrap}>
            {renaming ? (
              <Input
                className={classes.renameInput}
                inputRef={renameInputRef}
                defaultValue={projectInfo.title}
                autoFocus
                disableUnderline
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='off'
                spellCheck={false}
                onBlur={(e) => onRenameAccept((e.target as HTMLInputElement).value)}
                onKeyPress={(e) =>
                  e.key === 'Enter' && onRenameAccept((e.target as HTMLInputElement).value)
                }
                onKeyDown={(e) => e.key === 'Escape' && onRenameCancel()}
              />
            ) : (
              <div onClick={primaryAction === 'edit' ? onPlayClick : onEditClick}>
                {projectInfo.title}
              </div>
            )}
            {/*subtitle ? <div className={classes.subtitle}>{subtitle}</div> : null*/}
          </div>
          {renaming ? (
            <IconButton
              size='small'
              className={classes.renameCancelButton}
              aria-label='Close'
              onClick={onRenameCancel}
            >
              <CloseIcon />
            </IconButton>
          ) : (
            <div className={classes.actionIcon}>
              {projectInfo.locked && onSetLocked && <LockIcon fontSize='small' color='secondary' />}
              <MoreVertIcon
                className={classes.menuButton}
                aria-owns={menuOpen ? 'menu-project' : undefined}
                aria-haspopup='true'
                data-cy='project-menu'
                onClick={(event) => setAnchorEl(event.currentTarget as any)}
                color='inherit'
                style={{
                  color: projectInfo.published
                    ? projectInfo.publishedVersion! < projectInfo.version
                      ? 'rgba(255, 255, 0)'
                      : 'rgba(0, 200, 0)'
                    : 'rgba(0, 0, 0)',
                }}
              />
              {/* TODO: pass the menu in so the card doesn't need changes along with menu handling */}
              <Menu
                id='menu-project'
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={menuOpen}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={onEditClick} dense data-cy='edit-project'>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  Edit
                </MenuItem>
                {onRename && (
                  <MenuItem onClick={onRenameClick} dense>
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    Rename
                  </MenuItem>
                )}
                <MenuItem onClick={onDuplicateClick} dense data-cy='duplicate-project'>
                  <ListItemIcon>
                    <DuplicateIcon />
                  </ListItemIcon>
                  Duplicate
                </MenuItem>
                {onDelete && (
                  <MenuItem onClick={onDeleteClick} dense data-cy='delete-project'>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    Delete
                  </MenuItem>
                )}
                {projectInfo.owner !== 'anonymous' && <Divider />}
                {share && (
                  <MenuItem onClick={onShareClick} dense>
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    Share
                  </MenuItem>
                )}
                {projectInfo.owner !== 'anonymous' && (
                  <MenuItem onClick={onViewClick} dense disabled={!projectInfo.publishedProject}>
                    <ListItemIcon>
                      <ViewIcon />
                    </ListItemIcon>
                    View Published
                  </MenuItem>
                )}
                {onPublish && (
                  <MenuItem
                    onClick={onPublishClick}
                    dense
                    data-cy='publish-project'
                    disabled={
                      !!projectInfo.publishedProject &&
                      projectInfo.version <= projectInfo.publishedVersion!
                    }
                  >
                    <ListItemIcon>
                      <CloudIcon />
                    </ListItemIcon>
                    {!!projectInfo.publishedProject &&
                    projectInfo.version > projectInfo.publishedVersion!
                      ? 'Publish Changes'
                      : 'Publish'}
                  </MenuItem>
                )}
                {onUnpublish && (
                  <MenuItem
                    onClick={onUnpublishClick}
                    dense
                    data-cy='unpublish-project'
                    disabled={!projectInfo.publishedProject}
                  >
                    <ListItemIcon>
                      <CloudOffIcon />
                    </ListItemIcon>
                    Unpublish
                  </MenuItem>
                )}
                {false /* TODO: Hide history from users until we figure out what we want to expose. */ &&
                  menuOpen &&
                  projectInfo.publishedHistory && (
                    <NestedMenuItem
                      MenuItemProps={{ dense: true }}
                      label={
                        <>
                          <ListItemIcon>
                            <HistoryIcon />
                          </ListItemIcon>
                          History
                        </>
                      }
                      mainMenuOpen={menuOpen}
                    >
                      {projectInfo.publishedHistory?.map((projectHash) => (
                        <MenuItem
                          key={projectHash}
                          dense
                          onClick={() => onHistoryItemClick(projectHash)}
                        >
                          {projectHash}
                        </MenuItem>
                      ))}
                    </NestedMenuItem>
                  )}
                {(onSetSharing || onEditTags) && <Divider />}
                {onSetSharing && (
                  <MenuItem onClick={onTogglePublic} dense data-cy='share-project'>
                    <ListItemIcon>
                      {projectInfo.sharing === 'private' ? <PublicIcon /> : <PrivateIcon />}
                    </ListItemIcon>
                    {projectInfo.sharing === 'private' ? 'Share with Everyone' : 'Make Private'}
                  </MenuItem>
                )}
                {onEditTags && (
                  <MenuItem onClick={onEditTagsClick} dense>
                    <ListItemIcon>
                      <LocalOfferIcon />
                    </ListItemIcon>
                    Tags...
                  </MenuItem>
                )}
                {onSetLocked && (
                  <MenuItem onClick={onToggleLocked} dense data-cy='lock-project'>
                    <ListItemIcon>
                      {projectInfo.locked ? <LockIcon /> : <LockOpenIcon />}
                    </ListItemIcon>
                    {projectInfo.locked ? 'Unlock Project' : 'Lock Project'}
                  </MenuItem>
                )}
                {onExport && <Divider />}
                {(onExport || onSetProfileProject || onUnsetProfileProject) && <Divider />}
                {onSetProfileProject && (
                  <MenuItem
                    dense
                    onClick={
                      isProfileProject ? onUnsetProfileProjectClick : onSetProfileProjectClick
                    }
                  >
                    <ListItemIcon>
                      <AccountCircleIcon />
                    </ListItemIcon>
                    {(isProfileProject ? 'Unset' : 'Set') + ' as Profile'}
                  </MenuItem>
                )}
                {onExport && (
                  <MenuItem onClick={onExportClick} dense>
                    <ListItemIcon>
                      <CloudDownloadIcon />
                    </ListItemIcon>
                    Export
                  </MenuItem>
                )}
              </Menu>
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );

  function onHistoryItemClick(projectHash: string): void {
    setAnchorEl(null);
    onEdit(projectInfo, projectHash);
  }

  function onShareClick(_event: React.MouseEvent): void {
    setAnchorEl(null);
    if (share) {
      (window.navigator as any).share({
        url,
        text: `Check out this ${appName} project "${projectInfo.title}".`,
        title: projectInfo.title,
      });
    }
  }

  function onViewClick(): void {
    setAnchorEl(null);
    window.open(url, projectInfo.name);
  }

  function onPublishClick(): void {
    setAnchorEl(null);
    onPublish!(projectInfo);
  }

  function onUnpublishClick(): void {
    setAnchorEl(null);
    onUnpublish!(projectInfo);
  }

  function onTogglePublic(): void {
    setAnchorEl(null);
    onSetSharing!(projectInfo, projectInfo.sharing === 'private' ? 'public' : 'private');
  }

  function onToggleLocked(): void {
    setAnchorEl(null);
    onSetLocked!(projectInfo, !projectInfo.locked);
  }

  function onRenameClick(): void {
    setAnchorEl(null);
    setRenaming(true);
  }

  function onRenameCancel(): void {
    setRenaming(false);
  }

  function onRenameAccept(newTitle: string): void {
    setRenaming(false);
    // TODO: any title restrictions? sanitization?
    projectInfo.title = newTitle;
    onRename!(projectInfo);
  }

  function onEditClick(): void {
    setAnchorEl(null);
    onEdit(projectInfo);
  }

  function onPlayClick(): void {
    setAnchorEl(null);
    onPlay(projectInfo);
  }

  function onDeleteClick(): void {
    setAnchorEl(null);
    onDelete!(projectInfo);
  }

  function onDuplicateClick(): void {
    setAnchorEl(null);
    onDuplicate(projectInfo);
  }

  function onExportClick(): void {
    setAnchorEl(null);
    onExport!(projectInfo);
  }

  function onEditTagsClick(): void {
    setAnchorEl(null);
    onEditTags!(projectInfo);
  }

  function onSetProfileProjectClick(): void {
    setAnchorEl(null);
    onSetProfileProject!(projectInfo);
  }

  function onUnsetProfileProjectClick(): void {
    setAnchorEl(null);
    onUnsetProfileProject!(projectInfo);
  }
};
