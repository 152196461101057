import { useEffect } from 'react';

export type StringDictionary = { [key: string]: string };
export type UndefinedStringDictionary = { [key: string]: string | undefined };

/**
 * Like useEffect but works with async functions and makes sure that errors will be reported
 */
export function useAsyncEffect(effect: () => Promise<any>, deps?: []) {
  useEffect(() => {
    effect().catch((e) => console.warn('useAsyncEffect error', e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function setCookie(name: string, value: string, days?: number): void {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string): string {
  const b = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return b ? b.pop()! : '';
}

export function deleteCookie(name: string): void {
  document.cookie = name + '=; Max-Age=-99999999;';
}

export function parseJwt(token: string): { [name: string]: string } {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(base64);
}

// NOTE: Performance of this is terrible.
export function generateUUID(): string {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += performance.now(); //use high-precision timer if available
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string): string => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

// Return true if the two objects are shallowly equal.
export function shallowCompare(a: any, b: any): boolean {
  if (a === b) return true;
  if (!a || !b) return false;
  for (const key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

export function suggestName(baseName: string, takenNames: string[], alwaysSuffix = false): string {
  return baseName + suggestSuffix(baseName, takenNames, alwaysSuffix);
}

export function suggestSuffix(
  baseName: string,
  takenNames: string[],
  alwaysSuffix = false,
  space = false
): string {
  let n = 1;

  while (true) {
    const candidateName = !alwaysSuffix ? baseName : baseName + (space ? ' ' : '') + n;
    if (!takenNames.includes(candidateName)) {
      return !alwaysSuffix ? '' : String(n);
    }
    alwaysSuffix = true;
    n++;
  }
}

// Convert "#rrggbb" to "rgb(R, G, B)"
export function inputColorFromColor(c: string | undefined | null): string {
  //console.log('a in:', c);
  if (c === undefined || c === null || c === '') {
    //console.log('a out: rgb(0, 0, 0)');
    return 'rgb(0, 0, 0)';
  }
  const value = parseInt(c.slice(1), 16);
  const out = `a rgb(${(value & 0xff0000) >> 16}, ${(value & 0xff00) > 8}, ${value & 0xff})`;
  //console.log('out:', out);
  return out;
}

export function hexpad(s: string | number): string {
  if (typeof s === 'number') {
    s = s.toString();
  }
  return parseInt(s).toString(16).padStart(2, '0');
}

// Convert "rgb(R, G, B)" to "#rrggbb"
// Convert "rgba(R, G, B, A)" to "#rrggbbaa"
export function colorFromInputColor(ic: string): string {
  //console.log('b in:', ic);
  const out =
    '#' +
    ic
      .slice(ic[3] === 'a' ? 5 : 4, -1)
      .split(',')
      .map((s) => hexpad(s))
      .join('');
  //console.log('b out:', out);
  return out;
}

export function encode64(buffer: ArrayBuffer): string {
  return btoa(new Uint8Array(buffer).reduce((s, b) => s + String.fromCharCode(b), ''));
}

export function hex(buffer: ArrayBuffer): string {
  return [].map
    .call(new Uint8Array(buffer), (b: number) => ('00' + b.toString(16)).slice(-2))
    .join('');
}

export interface Watcher {
  close(): void;
}

export class WatcherList<T> {
  private callbacks: ((item: T) => void)[] = [];

  newWatcher(callback: (item: T) => void): Watcher {
    this.callbacks.push(callback);
    return { close: this.closeWatcher.bind(this, callback) };
  }

  closeWatcher(callback: (item: T) => void): void {
    const i = this.callbacks.indexOf(callback);
    if (i !== -1) {
      this.callbacks.splice(i, 1);
    }
  }

  notify(item: T): void {
    // Slice to be immune from list mutations during notification callbacks.
    for (const callback of this.callbacks.slice()) {
      try {
        callback(item);
      } catch (err) {
        // ignore
      }
    }
  }

  async dispose(): Promise<void> {
    this.callbacks = [];
  }
}

export function upperFirstLetter(s: string): string {
  return s[0].toUpperCase() + s.slice(1);
}

export type Error = [any];
export type Success<T> = [null, T];
export type Status<T> = Error | Success<T>;

/**
 *
 * A tiny util that allows the usage of async/await without a try/catch.
 * It returns an array where the first entry is any error thrown, and the
 * second is the res data.
 *
 * Usage: const [err, data] = await fromPromise(myAsyncFn())
 */
export function fromPromise<T>(promise: Promise<T>): Promise<Status<T>> {
  return promise.then((data): Status<T> => [null, data]).catch((err) => [err]);
}

export function lowerFirstLetter(s: string): string {
  return s[0].toLowerCase() + s.slice(1);
}

// button123 -> button, b1tton -> b1tton, b1tton -> b1tton, 13434bb -> 13434bb
export function trimNumberSuffix(s: string): string {
  return s.replace(/^(.*?)([0-9]*)$/, '$1');
}

export function isDescendant(el: HTMLElement, parentId: string): boolean {
  let isChild = false;

  if (el.id === parentId) {
    //is this the element itself?
    isChild = true;
  }

  while ((el = el.parentNode as HTMLElement)) {
    if (el.id == parentId) {
      isChild = true;
    }
  }

  return isChild;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
