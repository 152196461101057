// Reactor
// - encapsulates state and exposes as expression ("$" prefixed) and value properties
// - evaluates expression properties to produce value properties
// - tracks dirty state (invalidate, validate, update)
// - property changes can be observed

//import { createReactorArray } from './reactorArray';
//import { createReactorObject } from './reactorObject';
import { isObjectNotArray, isObjectOrArray } from './util';

// Register our Reactor debug formatter with DevTools.
registerReactorFormatter();

export type ReactorId = number;
export const ID = Symbol('ID');

export type ChangeType = 'add' | 'remove' | 'change';
export type ChangeListener = (
  reactor: Reactor,
  property: PropertyKey,
  newValue: any,
  oldValue: any,
  type: ChangeType
) => void;
export type Properties = {
  [property: string]: any;
  [property: symbol]: any;
};

export interface IDisposable {
  dispose(): void;
}

// Shared by ReactorObject and ReactorArray // TODO: -> Reactor?
export interface ReactorBase extends IDisposable {
  readonly [ID]: ReactorId;
  readonly id: ReactorId; //deprecated
  readonly $id: ReactorId; //deprecated

  getState(includeDefaultValues?: boolean): Properties;
  onPropertyChange(listener: ChangeListener): IDisposable;
}

export type ReactorArrayBase = ReactorBase;

export interface ReactorArray<T = any> extends ReactorArrayBase, Array<T> {
  [property: number]: T;
}

// Public interface
export interface ReactorObjectBase extends ReactorBase {
  readonly __contextualName: string;

  validate(): void;
  invalidate(property?: string): void;
  update?(changed: Properties): void;
  clearDirty(): void;

  //getOwnProperty(property: PropertyKey): any | undefined;
  //getProperty(property: PropertyKey): any | undefined;
  getOwnExpressions(): Properties;
  getExpressions(): Properties;

  evaluateExpressionProperties(onlyFormulas?: boolean): void;
}

export interface ReactorObject extends ReactorObjectBase {
  [property: string]: any;
  [property: symbol]: any;
}

export interface Reactor extends ReactorBase {
  [property: number]: any;
  [property: string]: any;
  [property: symbol]: any;
}

export function isReactor(object: any): object is Reactor {
  if (!object) {
    return false;
  }
  return object._isReactor === true;
}

export function parseComponentType(
  qualifiedType: string
): {
  importPath?: string;
  unqualifiedType: string;
} {
  if (qualifiedType.indexOf('/') === -1) {
    return { unqualifiedType: qualifiedType };
  } else {
    const s = qualifiedType.split('/');
    const unqualifiedType = s.pop()!;
    const importPath = s.join('/');
    return { importPath, unqualifiedType };
  }
}

export function qualifyComponentType(
  importPath: string | undefined,
  unqualifiedType: string
): string {
  return importPath !== undefined ? importPath + '/' + unqualifiedType : unqualifiedType;
}

// Remove ids from an object hierarchy or array in place.
export function removeIds<T>(state: T): T {
  delete (state as any)[ID];

  // Recurse on child objects and arrays;
  if (Array.isArray(state)) {
    for (const value of state) {
      if (isObjectOrArray(value)) {
        removeIds(value);
      }
    }
  } else {
    for (const key in state) {
      const value = state[key];
      if (isObjectOrArray(value)) {
        removeIds(value);
      }
    }
  }
  return state;
}

// Make the debugger display of Reactors a little nicer via a DevTools custom formatter.
// https://www.mattzeunert.com/2016/02/19/custom-chrome-devtools-object-formatters.html
export function registerReactorFormatter() {
  if (typeof window === 'undefined') {
    return;
  }

  // https://stackoverflow.com/questions/55733647/chrome-devtools-formatter-for-javascript-proxy
  if (!Array.isArray((window as any).devtoolsFormatters)) {
    (window as any).devtoolsFormatters = [];
  }

  //function jsonmlify(o: any): any {}

  (window as any).devtoolsFormatters.push({
    header(value: any) {
      if (!isReactor(value) || Array.isArray(value)) {
        return null;
      }

      const properties: Properties = { ...value };
      const short: Properties = { id: value.id };
      if (value.name) {
        short.name = value.name;
      }
      delete properties.id;
      delete properties.name;
      const keys = Object.keys(properties).slice(0, 5);
      keys.forEach((property) => (short[property] = properties[property]));

      return [
        'div',
        {},
        `{ ${Object.keys(short)
          .map((property) => {
            let v = value[property];
            switch (typeof v) {
              case 'object':
                if (isReactor(v)) {
                  v = `R[${v.id}]`;
                } else if (Array.isArray(v)) {
                  v = `Array`;
                } else {
                  v = `Object`;
                }
                break;

              case 'function':
                v = 'fn';
                break;

              default:
                v = JSON.stringify(v);
            }
            if (typeof v === 'string' && v.length > 10) {
              v = v.slice(0, 10) + '...';
            }
            return `${property}: ${v}`;
          })
          .join(', ')} }`,
      ];
    },

    hasBody() {
      return true;
    },

    body(value: any) {
      return [
        'ol',
        {
          style:
            'list-style-type:none; padding-left: 0px; margin-top: 4px; margin-bottom: 0px; margin-left: 12px',
        },
        ...Object.keys(value).map((property) => [
          'li',
          { style: 'padding-bottom: 4px; white-space: nowrap; color: #ccc' },
          ['span', { style: 'color: rgb(210, 120, 230)' }, property],
          ': ',
          ['object', { object: value[property] }],
        ]),
      ];
    },
  });
}

// TODO: dates, regex
export function serialize(state: any): string {
  return JSON.stringify(
    state,
    (_key: string, value: any): any => {
      // ReactorArray ids are encoded as an extra element at the end of the array.
      if (Array.isArray(value) && (value as ReactorArray)[ID]) {
        const valuePlusId = value.slice();
        valuePlusId.push(`__id:${(value as ReactorArray)[ID]}`);
        return valuePlusId;
      } else if (isObjectNotArray(value) && (value as ReactorObject)[ID]) {
        value.id = (value as ReactorObject)[ID];
        return value;
      } else {
        return value;
      }
    },
    2
  );
}

export function transformIdToSymbol(value: any) {
  if (Array.isArray(value) && value.length > 0) {
    // ReactorArray ids are encoded as an extra element at the end of the array.
    const idString = value[value.length - 1];
    if (typeof idString === 'string') {
      if (idString.startsWith('__id:')) {
        (value as any)[ID] = parseInt(idString.split(':')[1]);
        value.pop();
      }
    }
  } else if (isObjectNotArray(value) && value.id !== undefined) {
    // Translate old id values to new symbol value
    (value as any)[ID] = value.id;
    delete value.id;
  }

  return value;
}

// TODO: dates, regex
export function deserialize(s: string): any {
  return JSON.parse(s, (_key: string, value: any): any => {
    return transformIdToSymbol(value);
  });
}
