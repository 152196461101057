import React from 'react';
import ReactDOM from 'react-dom';

import * as runtime from '.';

// Allowed module imports
const importMap: Record<string, any> = {
  react: React,
  'react-dom': ReactDOM,
  '@playful/runtime': runtime,
};

const importCache: Record<string, any> = {};

/**
 * A mini CJS module loader
 * The imported module has the following restrictions
 * - It can import modules specified in importMap
 * - Modules splitting not supported (follows from above)
 */
export async function importCJS(url: string): Promise<any> {
  if (!importCache[url]) {
    const script = await (await fetch(url)).text();
    importCache[url] = importCJSScript(url, script);
  }
  return importCache[url];
}

function _importCJSSync(url: string): any {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, false);
  xhr.send(null);
  return importCJSScript(url, xhr.responseText);
}

function pathToURL(path: string, base: string): string {
  // Absolute URL
  if (path.match(/^[a-z]*:\/\//)) {
    return path;
  }

  // Protocol relative URL
  if (path.indexOf('//') === 0) {
    return new URL(path, base).toString();
  }

  // Relative/absolute path
  return new URL(path, base).toString();
}

function importCJSScript(url: string, script: string): any {
  // Append sourceURL so SourceMaps fetch from the right URL
  script += `//# sourceURL=${url}`;
  const func = new Function('module', 'exports', 'require', script);
  const module = {
    exports: {},
    id: url,
  };

  const require = (name: string): any => {
    // Allow old-school webpack url!<path> style names
    if (name.indexOf('!') !== -1) {
      const [loader, path] = name.split('!', 2);
      if (['file', 'url'].includes(loader)) {
        const moduleUrl = pathToURL(path, url);
        return moduleUrl;
      }
    } else {
      const mod = importMap[name];
      if (mod) {
        return mod;
      }
      const moduleUrl = pathToURL(name, url);
      return _importCJSSync(moduleUrl);
    }
  };

  func.call(module, module, module.exports, require);

  return module.exports;
}
