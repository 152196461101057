export function isObjectNotArray(value: any): boolean {
  return value && typeof value === 'object' && !Array.isArray(value);
}

export function isObjectOrArray(value: any): boolean {
  return value && typeof value === 'object';
}

// Join paths with a /
export function pathJoin(parts: string[]): string {
  const exp = /\/{1,}/g;
  return parts.join('/').replace(exp, '/');
}

// Return the <dir> part of <dir>/<file>.<ext>
export function dirName(path: string): string {
  return path.substring(0, path.lastIndexOf('/'));
}

// foo.ext -> foo, foo.bar.ext -> foo.bar
export function trimFileExtension(s: string): string {
  return s.replace(/\.[^/.]+$/, '');
}

// Convert the string into a valid Javascript identifier by replacing invalid chars
// with underscores and prepending an underscore if it starts with a digit.
export function stringToIdentifier(s: string): string {
  s = s.replace(/\W/g, '_');
  if (s[0] >= '0' && s[0] <= '9') {
    s = '_' + s;
  }
  return s;
}

export function getShadowDomAwareTarget(event: Event): EventTarget | null {
  if (event.composedPath) {
    return event.composedPath()[0];
  }

  let ret: Element | null = event.target as Element;

  while (ret?.shadowRoot != null) {
    ret = ret.shadowRoot.activeElement;
  }

  return ret;
}

export function isSafari(): boolean {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isMac(): boolean {
  return navigator.platform.toUpperCase().indexOf('MAC') !== -1;
}
