import { Avatar as MuiAvatar } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AccountCircle } from '@material-ui/icons';
import React from 'react';

import { MD5 } from '../utils/md5';
import type { PublicUser } from './user';

const useStyles = makeStyles((theme) =>
  createStyles({
    avatar: {
      width: 34,
      height: 34,
    },
  })
);

export interface AvatarProps {
  user?: PublicUser;
}

export const Avatar: React.FC<AvatarProps> = (props) => {
  const { user } = props;
  const classes = useStyles();
  // TODO: Add an avatar image URL to PublicUser and use that instead of Gravatar.
  const emailHash = (user as any)?.email ? MD5((user as any).email) : undefined;

  // TODO: Tooltip w/ userName
  return emailHash ? (
    <MuiAvatar
      className={classes.avatar}
      src={`https://www.gravatar.com/avatar/${emailHash}?d=mp`}
      alt={user!.name}
    />
  ) : (
    <AccountCircle />
  );
};
