import { Tooltip, TooltipProps } from '@material-ui/core';
import React, { FunctionComponent, ReactElement } from 'react';

import { BlankTargetAnchor } from './MarkdownTooltip';

type LearningLinkProps = {
  children: ReactElement | string;
  link?: string;
};

type LearningToolipProps = {
  children: ReactElement;
  tip: ReactElement;
  placement?: TooltipProps['placement'];
};

export const Docs = {
  Component(component: string, anchor?: string) {
    return `docs/concepts/components/${escape(component)}${anchor ? `#${anchor}` : ''}`;
  },
  Methods() {
    return 'docs/concepts/components/index#methods';
  },
  ObjectPanel() {
    return 'docs/concepts/objects';
  },
};

export const docPathForLink = (link?: string): string => {
  return ['/learning-center', link].filter(Boolean).join('/');
};

export const markdownLinkForDoc = (title: string, link: string): string => {
  return `[${title}](${docPathForLink(link)})`;
};

export const LearningLink: FunctionComponent<LearningLinkProps> = ({ children, link }) => {
  return <BlankTargetAnchor children={children} href={docPathForLink(link)} />;
};

export const LearningTooltip: FunctionComponent<LearningToolipProps> = ({
  children,
  placement = undefined,
  tip,
}) => {
  return <Tooltip arrow children={children} interactive placement={placement} title={tip} />;
};
