import { Checkbox, CircularProgress, FormControlLabel, Link, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useLocation } from 'wouter';

import { PushButton, Textfield } from '../brand';
import { getFirebaseAuth } from '../firebase';
import { useFormValidation } from '../hooks/useFormValidation';
import { StringDictionary, fromPromise } from '../utils/util';
import { RegisterValues, validateRegister } from '../utils/validateAuth';
import { useStyles } from './authStyles';
import { getErrorMsg } from './authutils';
import { setUserInfo, useUserContext } from './UserContext';

interface SignUpProps {
  onDone(): void;
  onSubmit(): void;
}

// TODO: anti-bot CAPTCHA, etc
export const SignUpForm: React.FC<SignUpProps> = (props) => {
  const classes = useStyles();
  const { setRegisteredUserName } = useUserContext();
  const { onDone, onSubmit } = props;
  const [, setLocation] = useLocation();
  const formRef = useRef<HTMLFormElement>(null);
  const searchParams = new URLSearchParams(window.location.search);

  const { handleSubmit, handleChange, handleBlur, values, errors, submitting } = useFormValidation(
    {
      email: searchParams.get('email') || '',
      password: '',
      userName: '',
      code: searchParams.get('code') || '',
    },
    validateRegister,
    createAccount
  );

  // TODO: do fields need ids?
  return (
    <div key='signup' className={classes.paper}>
      <form ref={formRef} className={classes.form} noValidate onSubmit={handleSubmit}>
        <Textfield
          fullWidth
          value={values.userName}
          error={!!errors.userName}
          onChange={handleChangeAndValidate}
          onBlur={handleBlur}
          id='userName'
          label='Username'
          name='userName'
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='none'
          spellCheck='false'
          helperText={errors.userName || ''}
          autoFocus
        />
        <Textfield
          fullWidth
          value={values.email}
          error={!!errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          id='email'
          label='Email'
          type='email'
          name='email'
          autoComplete='email'
          autoCorrect='off'
          autoCapitalize='none'
          spellCheck='false'
          helperText={errors.email || ''}
        />
        <Textfield
          value={values.password}
          error={!!errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='new-password'
          autoCorrect='off'
          autoCapitalize='none'
          spellCheck='false'
          helperText={errors.password || ''}
        />
        {errors?.other && (
          <Typography variant='body1' color='error' style={{ marginBottom: '1rem' }}>
            {errors.other}
          </Typography>
        )}
        <FormControlLabel
          className={classes.keepUp}
          control={<Checkbox value='allowExtraEmails' color='primary' />}
          label={
            <Typography variant='body2'>
              Keep me up to date with Play happenings and don&apos;t spam me.
            </Typography>
          }
        />
        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
        <div className={classes.dialogActions} style={{ alignItems: 'center' }}>
          <PushButton
            id='sign-up'
            style={{ marginBottom: 0 }}
            className={classes.submitButton}
            disabled={submitting}
          >
            Sign Up
          </PushButton>
          <div className={classes.actionLinks}>
            <Link className={classes.link} onClick={() => setLocation('/signin')} variant='body2'>
              Sign In
            </Link>
          </div>
        </div>
      </form>
    </div>
  );

  function handleChangeAndValidate(event: React.ChangeEvent<HTMLInputElement>) {
    handleChange(event);
    handleBlur(event as any);
  }

  async function createAccount(): Promise<
    (Partial<RegisterValues> & { other?: string }) | undefined
  > {
    onSubmit();

    const err = await register(values.email, values.password, values.userName, values.code);

    if (err) return err;
    
    onDone();
  }

  async function getCredentials(userName: string, email: string, password: string) {
    // Save the userName so it can be picked up by the onAuthStateChanged callback.
    setRegisteredUserName(userName);
    return (await getFirebaseAuth()).createUserWithEmailAndPassword(email, password)!;
  }

  async function register(
    email: string,
    password: string,
    userName: string,
    code: string
  ): Promise<StringDictionary | undefined> {
    const [credErr, credentials] = await fromPromise(getCredentials(userName, email, password));

    if (credErr) return getErrorMsg(credErr);

    // Remember userName as "displayName".
    await credentials!.user!.updateProfile({ displayName: userName });

    const [setErr] = await fromPromise(setUserInfo(userName, email, code));

    if (setErr) {
      // try to return detailed message
      const err = setErr?.response?.data?.error || {
        code: 'auth/set-info-failed',
        message: 'Failed to write user info. Try again?',
      };

      // If this fails the user account is not fully valid and must be deleted.
      await credentials!.user!.delete();

      return getErrorMsg(err);
    }
  }
};
