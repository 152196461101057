import { CircularProgress, Link, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import { useLocation } from 'wouter';

import { PushButton, Textfield } from '../brand';
import { getFirebaseAuth } from '../firebase';
import { useFormValidation } from '../hooks/useFormValidation';
import { StringDictionary, fromPromise } from '../utils/util';
import { validateAuth } from '../utils/validateAuth';
import { useStyles } from './authStyles';
import { getErrorMsg } from './authutils';

const login = async (email: string, password: string) =>
  await (await getFirebaseAuth()).signInWithEmailAndPassword(email, password);

interface SignInProps {
  onDone(): void;
  onSubmit(): void;
}

export const SignInForm: React.FC<SignInProps> = (props) => {
  const { onDone, onSubmit } = props;
  const [, setLocation] = useLocation();
  const classes = useStyles();
  const formRef = useRef<HTMLFormElement>(null);

  const { handleSubmit, handleChange, handleBlur, values, errors, submitting } = useFormValidation(
    { email: '', password: '' },
    validateAuth,
    signIn
  );

  return (
    <div key='signin' className={classes.paper}>
      <Typography component='h1' variant='h5' className={classes.header}>
        Welcome back,&nbsp;friend!
      </Typography>
      <form
        id='sign-in-form'
        ref={formRef}
        className={classes.form}
        noValidate
        onSubmit={handleSubmit}
      >
        <Textfield
          value={values.email}
          error={!!errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          id='email'
          label='Email'
          type='email'
          name='email'
          autoComplete='email'
          autoCorrect='off'
          autoCapitalize='off'
          spellCheck='false'
          autoFocus
          helperText={errors.email || ''}
        />
        <Textfield
          value={values.password}
          error={!!errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          name='password'
          label='Password'
          type='password'
          id='password'
          autoComplete='current-password'
          autoCorrect='off'
          autoCapitalize='none'
          spellCheck='false'
          helperText={errors.password || ''}
        />
        {errors?.other && (
          <Typography variant='body1' color='error' style={{ marginBottom: '1rem' }}>
            {errors.other}
          </Typography>
        )}
        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
        <div className={classes.dialogActions}>
          <PushButton className={classes.submitButton} id='sign-in' disabled={submitting}>
            Sign In
          </PushButton>
          <div className={classes.actionLinks}>
            <Link
              className={classes.link}
              onClick={() => setLocation('/forgot-password')}
              variant='body2'
            >
              Forgot password?
            </Link>
          </div>
        </div>
      </form>
    </div>
  );

  async function signIn(): Promise<StringDictionary | undefined> {
    onSubmit();

    const [err] = await fromPromise(login(values.email, values.password));

    if (err) return getErrorMsg(err);
    onDone();
  }
};
