// Thanks https://github.com/mui-org/material-ui/issues/11723#issuecomment-632132246

import { Menu, MenuItem, PaperProps } from '@material-ui/core';
import type { MenuItemProps } from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { ArrowLeft } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  label: React.ReactChild;
  mainMenuOpen: boolean;
  expandIcon?: any;
  highlightColor?: string;
  left?: boolean;
  MenuItemProps?: MenuItemProps & any;
  PaperProps?: Partial<PaperProps>;
};

type State = {
  subMenuOpen: boolean;
};

class NestedMenuItem extends React.Component<Props, State> {
  private highlightColor: string;
  private expandIcon: any;
  private subMenuRef: React.RefObject<HTMLDivElement>;
  private nestedMenuRef: React.RefObject<HTMLLIElement>;
  private subMenuOpen!: boolean;

  static propTypes = {
    label: PropTypes.any.isRequired, // The MenuItem text content
    mainMenuOpen: PropTypes.bool.isRequired, // The same variable assigned to main menu 'open' prop
    expandIcon: PropTypes.object, // usually left or right arrow icon
    highlightColor: PropTypes.string, // highlight background color when item is focused
    left: PropTypes.bool, // expand nested menu to the left?
    MenuItemProps: PropTypes.object, // e.g. { dense }
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      subMenuOpen: false,
    };
    const expandIconSize = this.props.MenuItemProps?.dense ? 'small' : 'default';
    this.expandIcon = this.props.expandIcon ? (
      this.props.expandIcon
    ) : props.left ? (
      <ArrowLeft fontSize={expandIconSize} />
    ) : (
      <ArrowRight fontSize={expandIconSize} />
    );
    this.highlightColor = this.props.highlightColor ? this.props.highlightColor : '#dddddd';
    this.subMenuRef = React.createRef();
    this.nestedMenuRef = React.createRef();
  }

  isSubmenuFocused = () => {
    const active = this.nestedMenuRef.current?.ownerDocument?.activeElement;
    for (const child of this.subMenuRef.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({ subMenuOpen: true });
    this.nestedMenuRef.current!.style.backgroundColor = this.highlightColor;
  };

  handleMouseLeave = (e: React.MouseEvent) => {
    this.setState({ subMenuOpen: false });
    this.nestedMenuRef.current!.style.backgroundColor = 'white';
  };

  handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState({ subMenuOpen: this.state.subMenuOpen ? false : true });
  };

  handleFocus = (evt: React.FocusEvent) => {
    if (evt.target === this.nestedMenuRef.current) {
      this.setState({ subMenuOpen: true });
      this.nestedMenuRef.current!.style.backgroundColor = this.highlightColor;
    }
  };

  handleKeyDown = (evt: React.KeyboardEvent) => {
    const arrowRight = this.props.left ? 'ArrowLeft' : 'ArrowRight';
    const arrowLeft = this.props.left ? 'ArrowRight' : 'ArrowLeft';
    const length = this.subMenuRef.current?.children.length;
    if (length && length > 0) {
      // When keyboard nav goes out of bounds, wrap around the current menu
      // and prevent parent menu from receiving the key input
      if (evt.target === this.subMenuRef.current?.children[length - 1] && evt.key === 'ArrowDown') {
        evt.stopPropagation();
        (this.subMenuRef.current?.children[0] as HTMLElement | undefined)?.focus();
      } else if (evt.target === this.subMenuRef.current?.children[0] && evt.key === 'ArrowUp') {
        evt.stopPropagation();
        (this.subMenuRef.current?.children[length - 1] as HTMLElement | undefined)?.focus();
      } else if (this.isSubmenuFocused()) {
        evt.stopPropagation();
      }
    }
    // Handle arrow key directions behaviour
    if (evt.key === arrowRight && !this.isSubmenuFocused()) {
      if (!this.subMenuOpen) {
        this.setState({ subMenuOpen: true });
      }
      (this.subMenuRef.current?.children[0] as HTMLElement | undefined)?.focus();
      evt.stopPropagation();
    } else if (
      (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') &&
      evt.target === this.nestedMenuRef.current
    ) {
      this.setState({ subMenuOpen: false });
      this.nestedMenuRef.current!.style.backgroundColor = 'white';
    } else if (evt.key === arrowLeft) {
      this.nestedMenuRef.current?.focus();
      this.setState({ subMenuOpen: false });
    }
  };

  render() {
    return (
      <MenuItem
        ref={this.nestedMenuRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        // Root element must have a `tabIndex` attribute for keyboard navigation
        // tabIndex={-1}
        onKeyDown={this.handleKeyDown}
        style={{ outline: 'none' }}
        {...(this.props.MenuItemProps as any)}
      >
        {this.props.label}
        {this.expandIcon}
        <Menu
          // set to pointerEvents to none to prevent menu from capturing
          // events meant for child elements
          style={{ pointerEvents: 'none' }}
          anchorEl={this.nestedMenuRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: this.props.left ? 'left' : 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: this.props.left ? 'right' : 'left',
          }}
          open={this.state.subMenuOpen && this.props.mainMenuOpen}
          onClose={() => {
            this.setState({ subMenuOpen: false });
          }}
          disableAutoFocus
          disableEnforceFocus
          disableRestoreFocus
          PaperProps={this.props.PaperProps}
        >
          <div ref={this.subMenuRef} style={{ pointerEvents: 'auto' }}>
            {this.props.children}
          </div>
        </Menu>
      </MenuItem>
    );
  }
}

export default NestedMenuItem;
