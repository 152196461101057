import type { IDisposable, Properties, Reactor, ReactorId } from './reactor';
import type {
  Component,
  IResource,
  Point,
  Project,
  ProjectId,
  ProjectInfo,
  ProjectState,
  ResourceId,
  ResourceSource,
  Slide,
} from './runtime';

export type AddContentOptions = {
  position?: Point;
  noUndoStop?: boolean;
  width?: number;
  height?: number;
  hierarchyPosition?: {
    parent: Reactor;
    propertyOrIndex: string | number;
  };
  component?: Component;
};

export type AddResourceOptions = AddContentOptions & {
  componentType?: string;
};

export type AddComponentOptions = AddContentOptions & {
  noSuggestPosition?: boolean;
};

export type RelativePositionArray = Array<{
  x: number;
  y: number;
  scale: number;
  direction: 'up' | 'down';
}>;

export type Selection = Component[];

export type PropertySet = { component: Component; property: string; value: any };

export interface IProjectModel {
  setSelection(selection: Selection): void;
  getSelection(): Selection;
  onChangeSelection(listener: (event: { readonly selection: Selection }) => void): IDisposable;

  setDesignRoot(designRoot: Reactor | undefined): void;
  getDesignRoot(): Reactor | undefined;
  onChangeDesignRoot(
    listener: (event: {
      readonly designRoot: Reactor | undefined;
      readonly oldDesignRoot: Reactor | undefined;
    }) => void
  ): IDisposable;

  onChangeComponentContainer(
    listener: (event: {
      readonly newContainer: Component;
      readonly oldContainer: Component;
      readonly component: Component;
    }) => void
  ): IDisposable;

  // Set new property values and put their old values on the undo stack.
  setProperties(changes: PropertySet[], noUndoStop?: boolean): void;
  // Insert or Create&Insert a reactor and put it on the undo stack.
  insertReactor<T extends Reactor>(
    parentId: ReactorId,
    propertyOrIndex: string | number,
    state: Properties | Reactor
  ): T;

  moveReactor<T extends Reactor>(
    source: T,
    target: T,
    targetPropertyOrIndex?: string | number,
    noUndoStop?: boolean
  ): Component;
}

export interface IProject {}

export type ContentId = string;
export const playContentMimeType = 'application/play-content';
export const playComponentMimeType = 'application/play-component';
export type Content =
  | LibraryResourceContent
  | ImageContent
  | VideoContent
  | AudioContent
  | ComponentContent
  | ResourceContent
  | FileContent;

export type LibraryResourceContent = {
  id: ContentId;
  type: 'libraryResource';
  resourceId: ResourceId;
  resourceKey: string;
  resourceType: string;
  options?: AddResourceOptions;
};

export type ImageContent = {
  id: ContentId;
  type: 'image';
  url: string;
  name?: string;
  options?: AddResourceOptions;
};

export type VideoContent = {
  id: ContentId;
  type: 'video';
  url: string;
  name?: string;
  options?: AddResourceOptions;
};

export type AudioContent = {
  id: ContentId;
  type: 'audio';
  url: string;
  name?: string;
  options?: AddResourceOptions;
};

export type ComponentContent = {
  id?: ContentId;
  type: 'component';
  state: Properties;
  projectId?: ProjectId;
  options?: AddComponentOptions;
};

export type ResourceContent = {
  id: ContentId;
  type: 'resource';
  resource: IResource;
  options?: AddResourceOptions;
};

export type FileContent = {
  id: ContentId;
  type: 'file';
  files: File[];
  options?: AddResourceOptions;
};

export interface IDesignerContext {
  workbench: IWorkbench;
  projectModel: IProjectModel;
  project: IProject;
  viewportContext: IViewportContext;
  playing: boolean;
  addProgressiveMediaComponent: (
    resource: IResource,
    state?: Properties,
    options?: AddResourceOptions,
    component?: Component
  ) => Promise<Component | undefined>;
  addComponent: (state: Properties, options?: AddComponentOptions) => Component;
  addSlide: (state: Properties) => Slide;
}

/**
 * Contains information about the designer's viewport
 */
export interface IViewportContext {
  viewportRect: DOMRect;
  pan: Point;
  setPan: (pan: Point | ((currentPan: Point) => Point), disableSnap?: boolean) => void;
  zoom: number;
  setZoom: (zoom: number) => void;
  designerMatrix: DOMMatrix;
  panZoomControls: {
    enableTouchDrag: () => void;
    disableTouchDrag: () => void;
  };
}

export interface IDesigner {
  // TODO: mount needs project? or already has it?
  mount(container: HTMLElement, projectModel: IProjectModel, project: IProject): void;
  unmount(): void;
  play(play: boolean): void;

  validate(): void;
  invalidate(): void;
  update(changed: any): void;

  width: number;
  height: number;
  zoom: number;
  deviceWidth: number | undefined;
  deviceHeight: number | undefined;
  readonly playing: boolean;
}

export interface IWorkbench {
  loadProject(state: ProjectState, info?: ProjectInfo): Promise<Project>;
  onAddContent(listener: (e: IAddContentEvent) => void): IDisposable;

  addContentFromDataTransfer(
    dataTransfer: DataTransfer,
    options?: AddResourceOptions
  ): Promise<void>;
  getResource(resId: ResourceId): Promise<IResource>;
  getResourceSource(resource: IResource): Promise<ResourceSource | undefined>;

  getLastInteractionTime(): number | undefined;
  notifyIsInteracting(interacting: boolean): void;

  pendingContent: Record<ContentId, Content>;
  draggingContent: Record<ContentId, Content>;

  addSnack(snack: WorkbenchSnack): void;
  addInteraction(event: string, triggerComponent: Component, actionComponent?: Component): void;
}

export interface IAddContentEvent {
  readonly content: Content;
  readonly onDone?: () => void;
}

export class AddContentEvent implements IAddContentEvent {
  constructor(public readonly content: Content, public readonly onDone?: () => void) {}
}

export type WorkbenchSnack = {
  variant?: 'success' | 'error' | 'info' | 'warning';
  message: string;
  message2?: string;
  anchor?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
};

export function isRootViewContainer(component: Component): boolean {
  return component?.name === 'rootViewContainer';
}
