import {
  ActionDescription,
  Component,
  ID,
  META,
  Properties,
  ReactorId,
  ReactorObject,
  compile,
} from '@playful/runtime';

import type { PlayKitProject, View } from './playkit';

export type Interaction = {
  key: string; // Rendering aid.
  trigger: Trigger;
  actions: Action[]; // The actions to be executed when the trigger is satisfied.
  // state?: object; // TODO: State available to conditions.
};

export type Trigger = {
  targetId: ReactorId;
  event?: string; // One of the events the Component containing the Trigger can fire.
  //condition?: string; // TODO: An expression.
  args?: ActionArguments;
};

export type ActionArguments = Record<string, Variant>;

export type Action = {
  key: string; // Rendering aid.
  targetId: ReactorId;
  method: string;
  args: ActionArguments;
};

export type InteractionContext = {
  event: Event;
  triggerComponent: Component;
};

export type VariantType =
  | 'actions'
  | 'expression'
  | 'number'
  | 'string'
  | 'boolean'
  | 'object'
  | 'undefined'
  | 'unknown';

export type Variant<T = any> = {
  type: VariantType;
  // TODO: type-smarts that matches the type of value to the specified type.
  value: T;
};

// Initialize a Variant from a value and an optional type.
export function makeVariant<T>(value: T, type?: VariantType): Variant<T> {
  if (type) {
    return { type, value };
  } else if (value === undefined) {
    return { type: 'undefined', value };
  } else if (typeof value === 'number') {
    return { type: 'number', value };
  } else if (typeof value === 'string') {
    return { type: 'string', value };
  } else if (typeof value === 'boolean') {
    return { type: 'boolean', value };
  } else if (typeof value === 'object') {
    return { type: 'object', value };
  } else {
    console.assert(false, 'Unsupported value type:', value);
    return { type: 'unknown', value };
  }
}

export function variantToString(variant: Variant | undefined): string {
  if (!variant) {
    return '';
  }

  switch (variant.type) {
    case 'undefined':
      return '';

    case 'expression':
      return '=' + variant.value;

    case 'boolean':
      return variant.value ? 'true' : 'false';

    case 'actions':
      console.assert("Can't convert Variant<'actions'> to a string.");
      return '<error>';

    case 'object':
      console.assert("Can't convert object Variant<'object'> to a string.");
      return '<error>';

    default:
      return variant.value.toString();
  }
}

export async function triggerInteractions(
  context: InteractionContext,
  interactions: Interaction[]
) {
  for (const interaction of interactions as Interaction[]) {
    // TODO: other-object triggers
    if (interaction.trigger.event === context.event.type) {
      if (interaction.actions) {
        await executeActions(context, interaction.actions);
      }
    }
  }
}

export async function executeActions(context: InteractionContext, actions: Action[]) {
  const { triggerComponent } = context;

  for (const action of actions) {
    const component = triggerComponent.project.getReactorById(action.targetId) as Component;
    if (component) {
      try {
        const args = evaluateArgs(context, component, action.args);
        const output = component[action.method]({ ...args, context });

        // Actions can return a promise which is waited on before moving on to the next action.
        // TODO: interaction "busy" state?
        // Avoid await overhead if not needed.
        if (output !== undefined && typeof output === 'object') {
          await Promise.resolve(output);
        }
      } catch (err) {
        // Ignore user code errors.
      }
    }
  }
}

// Evaluate each argument, which may be a primitive type or an expression, to the primitve type.
// It is anticipated that input arguments will get richer, with alternate ways to reference
// object properties, variables, event and "input" properties.
// TODO: Handle evaluation results that don't match the parameter type. Coercion? Some notion
// of "undefined"?
function evaluateArgs(
  context: InteractionContext,
  component: Component,
  args?: ActionArguments
): Properties | undefined {
  if (!args) {
    return undefined;
  }

  const additionalScope = { event: context.event };
  const evaluatedArgs: Properties = {};

  for (const [key, arg] of Object.entries(args)) {
    let value = arg.value;
    if (isExpressionValue(arg)) {
      const expression = value as string;
      const compiledExpression = compile(expression, additionalScope);
      value = compiledExpression(component);
    }
    evaluatedArgs[key] = value;
  }

  return evaluatedArgs;
}

export function isExpressionValue(value: Variant | undefined): value is Variant<string> {
  return value?.type === 'expression';
}

export function isActionBlock(value: Variant | undefined): value is Variant<Action[]> {
  return value?.type === 'actions';
}

// TODO: This only works if actions are sorted by inheritance with youngest first.
export function getPrimaryAction(component: Component): string | undefined {
  const actions = component[META]?.actions;
  if (!actions) {
    return undefined;
  }
  const actionNames = Object.keys(actions);
  const actionName = actionNames.find((actionName) => actions[actionName].primary);
  if (actionName) {
    return actionName;
  }

  return actionNames[0];
}

// TODO: This only works if events are sorted by inheritance with youngest first.
export function getPrimaryEvent(component: Component): string | undefined {
  const events = component[META]?.events;
  if (!events) {
    return undefined;
  }
  const eventNames = Object.keys(events);
  const eventName = eventNames.find((eventName) => events[eventName].primary);
  if (eventName) {
    return eventName;
  }

  return eventNames[0];
}

// TODO: This only works if properties are sorted by inheritance with youngest first.
// Return the Component's described primary property or the first property if none is described.
// Only returns undefined when there are no properties.
export function getPrimaryProperty(component: Component): string | undefined {
  const properties = component[META]?.properties;
  if (!properties) {
    return undefined;
  }
  const propertyNames = Object.keys(properties);
  const propertyName = propertyNames.find((eventName) => properties[eventName].primary);
  if (propertyName) {
    return propertyName;
  }

  return propertyNames[0];
}

export function getActionDescription(
  component: Component,
  actionName: string
): ActionDescription | undefined {
  return component[META]?.actions?.[actionName];
}

// Get 'active' (non-garbage) arguments. Those are the ones described as parameters for the action.
export function getDescribedActionArguments(
  action: Action,
  project: PlayKitProject
): { [key: string]: Variant } | undefined {
  const target = project.hasReactor(action.targetId)
    ? (project.getReactorById(action.targetId) as Component)
    : undefined;
  if (!target) return;

  const description = getActionDescription(target, action.method);
  if (!description) return undefined;

  const args: { [key: string]: Variant } = {};
  if (!description.parameters) return args;
  for (const parameter of Object.keys(description.parameters)) {
    const arg = action.args[parameter];
    if (arg !== undefined) {
      args[parameter] = arg;
    }
  }
  return args;
}

// The pasted component may have Interaction references to the original.
// Fix them to point to itself.
export function fixPastedInteractions(view: View, originalReactorId: ReactorId): void {
  if (!view.interactions) return;

  const newReactorId = view[ID];

  for (const interaction of view.$interactions as Interaction[]) {
    if (interaction.trigger.targetId === originalReactorId) {
      ((interaction.trigger as unknown) as ReactorObject).$targetId = newReactorId;
      fixPastedActions(interaction.actions, originalReactorId, newReactorId);
    }
  }
}

function fixPastedActions(
  actions: Action[],
  originalReactorId: ReactorId,
  newReactorId: ReactorId
) {
  for (const action of actions) {
    if (action.targetId === originalReactorId) {
      ((action as unknown) as ReactorObject).$targetId = newReactorId;
    }

    // Recurse on Action block arguments.
    for (const key of Object.keys((action as any).args)) {
      const arg = action.args[key];
      if (isActionBlock(arg)) {
        fixPastedActions(arg.value, originalReactorId, newReactorId);
      }
    }
  }
}

// Initialize an Action with default values. Be smart about setProperty and toggle.
export function initAction(
  action: Action,
  actionName: string,
  target: Component | undefined
): void {
  action.method = actionName;

  // Intelligently carry over args from previous action.
  action.args = action.args ?? {};

  // If the action is setProperty then go ahead and specify the primary property as its argument.
  if (actionName === 'setProperty' || actionName === 'toggle') {
    const property = target ? getPrimaryProperty(target) : undefined;
    if (property) {
      // TODO: Default arguments in ActionDescription?
      action.args = { property: makeVariant(property) };
    }
  }
}

// Return the array of actions that contains the specified action.
export function findAction(
  actionPath: string,
  interactions: Interaction[]
): { actions: Action[]; index: number } {
  const path = actionPath.split('/');
  path.shift(); // Remove the component id which is only used for debugging.
  const interaction = interactions[Number(path.shift()!)];
  console.assert(interaction);
  let actionsOrArgs: Action[] | Properties = interaction.actions;
  console.assert(actionsOrArgs);

  // Find our way to the block the action is in.
  while (path.length > 1) {
    const index = path.shift()!;
    if (isNaN(Number(index))) {
      actionsOrArgs = (actionsOrArgs as ActionArguments)[index].value;
    } else {
      actionsOrArgs = (actionsOrArgs as Action[])[Number(index)].args;
    }
  }

  console.assert(actionsOrArgs);
  console.assert(path.length === 1);
  return { actions: actionsOrArgs as Action[], index: Number(path[0]) };
}
