import { ID } from './reactor';
import type { Properties, Reactor, ReactorId, ReactorObject } from './reactor';
import { createReactorArray } from './reactorArray';
import { createReactorObject } from './reactorObject';

export type CreateReactorOptions = {
  noId?: boolean;
  contextualName?: string;
  imports?: Properties;
  updateMethods?(reactor: Reactor): void;
};

export function createReactorFactory(nextReactorId=1): ReactorFactory {
  return new ReactorFactory(nextReactorId);
}

export class ReactorFactory {
  /** @internal */
  nextReactorId: number;

  /** @internal */
  reactors: { [id: number]: Reactor | undefined } = {
    0: { [ID]: -1, name: 'invalid Reactor' } as any,
  };

  /** @internal */
  rootReactor!: ReactorObject;

  constructor(nextReactorId = 1) {
    this.nextReactorId = nextReactorId;
  }

  // TODO: dispose()?

  getNextReactorId(): ReactorId {
    return this.nextReactorId;
  }

  getReactorById(id: ReactorId): Reactor {
    const reactor = this.reactors[id];
    if (!reactor) {
      throw new Error(`reactor id ${id} isn't in reactor index`);
    }
    return reactor;
  }

  hasReactor(id: ReactorId): boolean {
    return this.reactors[id] !== undefined;
  }

  // Reactor paths are dot-separated property names, e.g. Components.View.5.whatever
  getReactorByPath(path: string): Reactor | undefined {
    const segments = path.split('.');
    let reactor = this.rootReactor;
    if (path === '') {
      return reactor;
    }
    for (const segment of segments) {
      const r = reactor[segment];
      if (r === undefined) {
        return undefined; // Path not found
      }
      reactor = r;
    }
    return reactor;
  }

  forEachReactor(callback: (reactor: Reactor) => boolean): void {
    for (const id in this.reactors) {
      const reactor = this.reactors[id];
      if (reactor && reactor[ID] !== -1) {
        if (!callback(reactor)) {
          // Abort enumeration when callback returns false.
          return;
        }
      }
    }
  }

  //
  //
  //

  createReactor<T = Reactor>(objectOrArray?: Properties | [], options?: CreateReactorOptions): T {
    if (Array.isArray(objectOrArray)) {
      return (createReactorArray(this, objectOrArray, options) as unknown) as T;
    } else {
      return (createReactorObject(this, objectOrArray, options) as unknown) as T;
    }
  }
}
