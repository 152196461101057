import { canvasToBlob } from './canvas';

type ImageThumbnailOptions = {
  width: number;
  quality: number;
  mimeType: string;
};

export class ImageThumbnail {
  private url;
  private imageElement: HTMLImageElement;
  private canvas: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;
  constructor(url: string) {
    this.url = url;
    const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
    this.canvas = canvas;
    this.canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
    this.imageElement = new Image();
  }

  getThumbnail(options: ImageThumbnailOptions): Promise<Blob> {
    return new Promise((resolve, reject) => {
      this.imageElement.onload = (imageLoadEvent) => {
        const loadedImage = imageLoadEvent.target! as HTMLImageElement;
        const scaleFactor = options.width / loadedImage.width;

        this.canvas.width = options.width;
        this.canvas.height = loadedImage.height * scaleFactor;
        this.canvasContext.drawImage(loadedImage, 0, 0, this.canvas.width, this.canvas.height);
        canvasToBlob(this.canvas, options.mimeType, options.quality)
          .then((blob) => {
            resolve(blob);
          })
          .catch((error) => {
            reject(error);
          });
      };
      this.imageElement.src = this.url;
    });
  }
}
