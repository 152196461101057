import { makeStyles } from '@material-ui/core';

import { brightGreen } from '../brand';

export const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dialogContent: {
    padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
  },
  form: {
    width: '100%',
    minWidth: '40ch',
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      minWidth: 'auto',
    },
  },
  submit: {
    margin: theme.spacing(1, 0, 0),
  },
  dialogLogo: {
    margin: `${theme.spacing(2)}px auto`,
    display: 'block',
    width: '200px',
    maxWidth: '100%',
  },
  header: {
    marginBottom: theme.spacing(2),
    fontWeight: 800,
    textAlign: 'center',
    orphans: 4,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  keepUp: {
    marginBottom: theme.spacing(2),
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    alignContent: 'top',
  },
  actionLinks: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    justifyContent: 'center',
  },
  submitButton: {
    backgroundColor: brightGreen,
    '&:hover': {
      backgroundColor: brightGreen,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
  // TODO: handle this globally for all links
  link: {
    color: theme.palette.type === 'dark' ? theme.palette.text.hint : theme.palette.text.hint,
    textDecoration: 'underline',
  },
}));
