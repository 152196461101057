import { useMediaQuery } from '@material-ui/core';
import type { Theme } from '@material-ui/core/styles';

export const smallScreenThreshold = 600;

export function useIsSmallScreen(): boolean {
  return useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'), {
    noSsr: true,
  });
}

export function isSmallScreen(): boolean {
  return window.matchMedia(`(max-width: ${smallScreenThreshold}px)`).matches;
}
