import type { Point } from '@playful/runtime';
import type { Content } from '@playful/runtime';

/**
 * Clamps a number to a specific range. I.e. if the range is 10-20
 * 9 will be 10, 21 will be 20.
 */
export const clamp = (min: number, max: number) => (value: number) =>
  Math.max(min, Math.min(value, max));

/**
 * Checks for null or undefined, lifted from https://ramdajs.com/docs/#isNil
 */
export const isNil = (x: any) => x == null;

// Handy handler utils

/**
 * Returns a handler that will pass in `true` as the first arg to a wrapped function.
 */
export const withT = <Args extends unknown[]>(fn: (a: boolean, ...args: Args) => any) => (
  ...args: Args
) => fn(true, ...args);

/**
 * Returns a handler that will pass in `false` as the first arg to a wrapped function.
 */
export const withF = <Args extends unknown[]>(fn: (a: boolean, ...args: Args) => any) => (
  ...args: Args
) => fn(false, ...args);

/**
 * Returns a handler that will toggle its first arg (boolean) and pass it as the first arg to a wrapped function.
 */
export const toggle = <Args extends unknown[]>(fn: (a: boolean, ...args: Args) => any) => (
  v: boolean,
  ...args: Args
) => fn(!v, ...args);

/**
 * Returns only the truthy values in a list.
 */
export const filterT = (arr: any[]) => arr.filter(Boolean);

export function noop(): void {
  // do nothing
}

/**
 * Returns the distance between two points.
 */
export function dist(a: Point, b: Point) {
  return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
}

export function between(n: number, min: number, max: number) {
  return n >= min && n <= max;
}

// Simple equality: must hold the same values in the same order.
export function arraysEqual(a: any[], b: any[]): boolean {
  if (a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
}

export function pointsEqual(a?: Point, b?: Point): boolean {
  return Boolean(a && b && a.x === b.x && a.y === b.y);
}

export function isAudioType(content: Content) {
  return (
    content.type === 'audio' ||
    (content.type === 'libraryResource' && content?.resourceType?.startsWith('audio'))
  );
}

export function isVideoType(content: Content) {
  return (
    content.type === 'video' ||
    (content.type === 'libraryResource' && content?.resourceType?.startsWith('video'))
  );
}

export function isImageType(content: Content) {
  return (
    content.type === 'image' ||
    (content.type === 'libraryResource' && content?.resourceType?.startsWith('image'))
  );
}
