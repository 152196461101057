import type { Reactor } from '.';

// Generate a scope object containing:
// * This object's siblings (if it has a parent, it's the parent's components)
// * This object's properties, methods, etc
// * project: the project
//
const scopeTraps: ProxyHandler<any> = {
  has(target, p): boolean {
    const hasProperty = Reflect.has(target, p);
    if (hasProperty) {
      return true;
    }
    if ('parent' in target && 'getComponentByName' in target.parent) {
      return target.parent.getComponentByName(p) !== undefined;
    }
    return false;
  },

  get(target, p, receiver): any {
    // If the target or anything on its prototype chain has the property, return it.
    if (p in target) {
      return target[p];
    }

    // Don't bother looking for symbols (e.g. Symbol.unscopables) as sibling components.
    if (typeof p === 'symbol') {
      return undefined;
    }

    // TODO: Would be nice not to do this lookup twice (here and in has).
    if ('parent' in target && 'getComponentByName' in target.parent) {
      return target.parent.getComponentByName(p);
    }
  },

  ownKeys(target) {
    const ret = Reflect.ownKeys(target);
    if ('parent' in target && 'getComponentByName' in target.parent) {
      for (const component of target.parent.components) {
        if (ret.includes(component.name)) {
          // Own properties take precedence over sibling components.
          continue;
        }
        ret.push(component.name);
      }
    }
    return ret;
  },

  getOwnPropertyDescriptor(target, prop) {
    const desc = Object.getOwnPropertyDescriptor(target, prop);
    if (desc) {
      if (prop === 'project') {
        // project is marked as non-enumerable in ReactorObject, which prevents a bunch of infinite recursion errors
        desc.enumerable = true;
      }
    } else {
      if ('parent' in target && 'getComponentByName' in target.parent) {
        for (const component of target.parent.components) {
          if (component.name === prop) {
            return { configurable: true, enumerable: true, writable: false, value: component };
          }
        }
      }
    }
    return desc;
  },
};

export function getScope(reactor: Reactor) {
  return new Proxy(reactor, scopeTraps);
}
