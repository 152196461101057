import {
  Button,
  Divider,
  IconButton,
  InputBase,
  Link,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { Search as SearchIcon } from '@material-ui/icons';
import { humanizePropertyName } from '@playful/runtime/propertiesHelpers';
import React, { Suspense, lazy, useState } from 'react';
import { useLocation } from 'wouter';

import { docPathForLink } from '../components/LearningLink';
import { getFirebaseAuth } from '../firebase';
import { Avatar } from './Avatar';
import { InitialUserFeatures, UserFeatures } from './flags';
import { useUserContext } from './UserContext';

const AccessTokensDialog = lazy(
  () => import('./accessTokensDialog' /* webpackChunkName: "AccessTokensDialog" */)
);

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
    },
    searchButton: {
      paddingRight: 0,
    },
    accountButton: {
      padding: 0,
    },
  })
);

// TODO: this seems like a lot of BS to get the All/Mine select to have white text the right size.
const StyledInput = withStyles((theme) =>
  createStyles({
    root: {
      color: 'inherit',
      fontSize: '1.25rem',
    },
    input: {
      color: 'inherit',
    },
  })
)(InputBase);

const StyledDropDownIcon = withStyles(
  (theme) =>
    createStyles({
      input: {
        color: 'inherit',
      },
    }),
  { name: 'ArrowDropDownIcon' }
)(ArrowDropDownIcon as any);

export interface AccountBarProps {}

export const AccountBar: React.FC<AccountBarProps> = (props) => {
  const { user, isApproved, hasFeature, clearClaims, updateUserFeatures } = useUserContext();
  const classes = useStyles();
  const [, setLocation] = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [category, setCategory] = useState('all');
  const [tokensDialogOpen, setTokensDialogOpen] = useState(false);

  return (
    <div id='account-bar' style={{ marginRight: '16px' }}>
      {false && (
        <Select
          value={category}
          onChange={onCategoryChange}
          input={<StyledInput name='age' id='age-customized-select' />}
          IconComponent={StyledDropDownIcon}
        >
          <MenuItem value={'all'}>All</MenuItem>
          <MenuItem value={'mine'}>Mine</MenuItem>
        </Select>
      )}
      {false && (
        <IconButton
          className={classes.searchButton}
          aria-owns={'search-appbar'}
          aria-haspopup='true'
          color='inherit'
        >
          <SearchIcon />
        </IconButton>
      )}
      {user && user.id !== 'anonymous' ? (
        <div>
          <IconButton
            id='account-button'
            className={classes.accountButton}
            aria-owns={isMenuOpen ? 'menu-appbar' : undefined}
            aria-haspopup='true'
            onClick={onAccountButtonClick}
            color='inherit'
          >
            <Avatar user={user} />
          </IconButton>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={onAccountMenuItemClick}
          >
            <MenuItem onClick={onProfileClick} dense>
              My Profile
            </MenuItem>
            <MenuItem onClick={onAccountMenuItemClick} dense>
              My Account
            </MenuItem>
            {isApproved && (
              <MenuItem onClick={onTokensMenuItemClick} dense>
                My Tokens
              </MenuItem>
            )}
            <Divider />
            <MenuItem onClick={onLearningCenterClick}>Learning Center</MenuItem>
            <Divider />
            <MenuItem id='sign-out' onClick={onSignOutClick} dense>
              Sign out {user.name}
            </MenuItem>
            {isApproved && [
              <Divider key='divider' />,
              /*
                  // TODO: Hide for non-admins? Non-@playful users?
                  */

              Object.keys(InitialUserFeatures).map((feature) => (
                <MenuItem
                  key={`${feature}-feature-toggle`}
                  onClick={toggleFeatureClick(feature as any)}
                  dense
                >
                  {`${hasFeature(feature as any) ? 'Disable' : 'Enable'} ${humanizePropertyName(
                    feature
                  )}`}
                </MenuItem>
              )),
            ]}
          </Menu>
          <Suspense fallback={null}>
            {tokensDialogOpen && <AccessTokensDialog onDone={onTokensDialogDone} />}
          </Suspense>
        </div>
      ) : (
        <div>
          <Button
            id='sign-in'
            variant='contained'
            onClick={() => setLocation('/signin')}
            color='inherit'
          >
            <Typography variant='body2' className={classes.title}>
              Sign In
            </Typography>
          </Button>
        </div>
      )}
    </div>
  );

  async function onSignOutClick(): Promise<void> {
    setAnchorEl(null);
    const auth = await getFirebaseAuth();
    auth.signOut();
    clearClaims();
  }

  function onProfileClick() {
    setAnchorEl(null);
    setLocation(`/@${user.name}`);
  }

  function onAccountButtonClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function onAccountMenuItemClick() {
    setAnchorEl(null);
  }

  function onLearningCenterClick(): void {
    document.location.href = docPathForLink();
  }

  async function onTokensMenuItemClick() {
    setAnchorEl(null);
    setTokensDialogOpen(true);
  }

  function onTokensDialogDone() {
    setTokensDialogOpen(false);
  }

  function toggleFeatureClick(featureName: keyof UserFeatures) {
    return () => {
      updateUserFeatures(user.id, {
        [featureName]: !hasFeature(featureName),
      });
    };
  }

  function onCategoryChange(event: React.ChangeEvent<{ value: unknown }>) {
    setCategory(event.target.value as string);
  }
};
