import type { Project } from './runtime';
import { pathJoin } from './util';

export function urlFromSource(source: string, project: Project): string | undefined {
  const [a, b] = source.split(':', 2);

  const resourceRoot = project.resourceRoot!;

  if (a === 'data' || a === 'blob' || a === 'https' || a === 'http') {
    return source;
  } else if (a === 'sha256') {
    return pathJoin([resourceRoot, source]);
  } else if (a === 'resource') {
    return pathJoin([resourceRoot, b]);
  }
  return pathJoin([resourceRoot, source]);
}
