import { alpha } from '@material-ui/core/styles';

import { canvasToBlob } from './canvas';

type AudioDataOptions = {
  channel: number;
  samples: number;
};

type AudioDrawOptions = {
  canvasWidth: number;
  canvasHeight: number;
  fillStyle: string; // #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
  waveStrokeStyle: string; // #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
  strokeSpacing: number;

  /**
   * Max number of waves to draw. If there are more data points than waves some data will be skipped
   *
   */
  maxWaves: number;

  // Distance from the edge of the canvas to cut off the line
  padding: number;
};

type AudioThumbnailOptions = {
  width: number;
  height: number;
};

/**
 * Heavily inspired by
 * @see https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
 * Reads an audioBuffer and attempts to draw a waveform to represent it.
 * Focuses on speed over accuracy.
 */
export class AudioWaveform {
  private audioContext: AudioContext;
  private audioBuffer?: AudioBuffer;
  private filteredData?: Array<number>;
  private normalizedData?: Array<number>;
  private canvas: HTMLCanvasElement;
  private canvasContext: CanvasRenderingContext2D;

  /**
   * Should only be used for the decode step, which mutates this.
   * @private
   */
  private readonly arrayBuffer: ArrayBuffer;

  constructor(arrayBuffer: ArrayBuffer) {
    const AudioContext =
      window.AudioContext || // Default
      window.webkitAudioContext || // Safari and old versions of Chrome
      false;

    if (!AudioContext) {
      throw new Error('AudioContext could not be found in this browser.');
    }

    this.audioContext = new AudioContext();
    this.arrayBuffer = arrayBuffer;
    const canvas: HTMLCanvasElement = document.createElement('canvas') as HTMLCanvasElement;
    this.canvas = canvas;
    this.canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;
  }

  /**
   * This mutates the ArrayBuffer. Recommend passing a copying in.
   */
  decode() {
    if (!this.audioBuffer) {
      const promise = new Promise((resolve, reject) => {
        this.audioContext.decodeAudioData(
          this.arrayBuffer,
          (buffer) => {
            this.audioBuffer = buffer;
            resolve(buffer);
          },
          (error) => {
            reject(error);
          }
        );
      });
      return promise;
    }
  }

  /**
   * Creates a thumbnail for a audio file.
   * `samples` option is disable for drawing the thumbnail to allow them to be derived from the
   * width and strokeSpacing.
   * @param options
   */
  async getThumbnail(
    options: Partial<
      AudioThumbnailOptions & AudioDataOptions & AudioDrawOptions & { samples: never }
    >
  ): Promise<Blob> {
    const opts = {
      width: 150,
      height: 125,
      strokeSpacing: 3,
      ...options,
    };
    await this.decode();
    this.processAudioData({
      ...opts,
      samples: opts.width / opts.strokeSpacing,
    });
    this.drawAudioWave(opts);
    return canvasToBlob(this.canvas, 'image/png');
  }

  processAudioData(options: Partial<AudioDataOptions>) {
    this.filteredData = this.filterData(options);
    this.normalizedData = this.normalizeData(this.filteredData);
  }

  /**
   * Creates a simple imprecise audio wave from the audio data.
   * @param options
   */
  drawAudioWave(options: Partial<AudioDrawOptions>) {
    if (!this.normalizedData) {
      throw new Error('The audio data must be processed first (run processAudioData)');
    }
    const opts = {
      canvasWidth: 150,
      canvasHeight: 125,
      fillStyle: 'darkgray',
      waveStrokeStyle: '#fff',
      maxWaves: 70,
      padding: 20,
      strokeSpacing: 3,
      ...options,
    };

    this.canvas.width = opts.canvasWidth;
    this.canvas.height = opts.canvasHeight;
    const ctx = this.canvasContext;
    // Background color
    ctx.fillStyle = opts.fillStyle;
    ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

    // Set Y = 0 to be in the middle of the canvas
    ctx.translate(0, this.canvas.height / 2);

    const dataLength = this.normalizedData.length;

    for (let step = 0; step <= dataLength; step += 1) {
      const x = opts.strokeSpacing * step;
      let height = this.normalizedData[step] * this.canvas.height - opts.padding;
      if (height < 0) {
        // If the padding made the height < 0 set it back to 0.
        height = 0;
      } else if (height > this.canvas.height) {
        height = this.canvas.height;
      }
      // Draw the line
      this.drawLineSegment(ctx, x, height, opts.strokeSpacing, opts.waveStrokeStyle);
    }
  }

  private drawLineSegment(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    waveStrokeStyle: string
  ) {
    ctx.lineWidth = 1; // how thick the line is
    ctx.strokeStyle = waveStrokeStyle; // what color our line is
    ctx.beginPath();
    const half = y / 2;
    ctx.moveTo(x + width, 0);
    // From the center of the canvas draw a line above
    ctx.lineTo(x + width, -half);
    ctx.stroke();

    // From the center of the canvas draw a line below
    ctx.strokeStyle = alpha(waveStrokeStyle, 0.7);
    ctx.beginPath();
    ctx.moveTo(x + width, 1);
    ctx.lineTo(x + width, half);
    ctx.stroke();
  }

  private filterData(options: Partial<AudioDataOptions>) {
    if (!this.audioBuffer) {
      throw new Error('The Audio buffer must be decoded first.');
    }
    const opts = {
      channel: 0,
      samples: 100,
      ...options,
    };

    const rawData = this.audioBuffer.getChannelData(opts.channel); // TODO: Generate wave for every channel?
    const samples = opts.samples; // Number of samples we want to have in our final data set
    const blockSize = Math.floor(rawData.length / samples); // the number of samples in each subdivision
    const filteredData = [];
    for (let i = 0; i < samples; i++) {
      const blockStart = blockSize * i; // the location of the first sample in the block
      let sum = 0;
      for (let j = 0; j < blockSize; j++) {
        sum = sum + Math.abs(rawData[blockStart + j]); // find the sum of all the samples in the block
      }
      filteredData.push(sum / blockSize); // divide the sum by the block size to get the average
    }
    return filteredData;
  }

  private normalizeData(filteredData: Array<number>) {
    const multiplier = Math.pow(Math.max(...filteredData), -1);
    return filteredData.map((n) => n * multiplier);
  }
}
