import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export const useButtonStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '.35rem .35rem 0 black',
    borderRadius: 0,
    fontWeight: 700,
    '&:hover': {
      boxShadow: '.35rem .35rem 0 black',
    },
    '&.Mui-disabled': {
      backgroundColor: theme.palette.grey[300],
      boxShadow: '.35rem .35rem 0 black',
    },
  },
}));

export function PushButton({ className, children, ...props }: ButtonProps) {
  const brandClasses = useButtonStyles();

  return (
    <Button
      type='submit'
      variant='contained'
      disableElevation={true}
      className={clsx([brandClasses.root, className])}
      {...props}
    >
      {children}
    </Button>
  );
}
