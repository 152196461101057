import type { ResourceId } from '@playful/runtime';
import { useEffect, useState } from 'react';

import { Resource } from '../resources';

export function useResource(
  initialResId: ResourceId
): { res?: Resource; setResId: (resId: ResourceId) => void } {
  const [resId, setResId] = useState<ResourceId>(initialResId);
  const [res, setResource] = useState<Resource | undefined>(undefined);

  useEffect(() => {
    const fetchResource = async () => {
      const res = await Resource.get(resId);
      setResource(res);
    };
    fetchResource();
  }, [resId]);

  return { res, setResId };
}

export function useResourceDataUrl(
  initialResId: ResourceId,
  initialKey: string
): { url?: string; setResId: (resId: ResourceId) => void; setKey: (key: string) => void } {
  const [key, setKey] = useState<string>(initialKey);
  const { res, setResId } = useResource(initialResId);
  const url = res?.getDataUrl(key);
  return { url, setResId, setKey };
}

export function useResourceDataUrlAuth(
  initialResId: ResourceId,
  initialKey: string
): { url?: string; setResId: (resId: ResourceId) => void; setKey: (key: string) => void } {
  const [resId, setResId] = useState<string>(initialResId);
  const [key, setKey] = useState<string>(initialKey);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    const getUrl = async () => {
      try {
        setUrl(await Resource.getDataUrlAuth(resId, key));
      } catch (e) {
        console.error(e);
      }
    };
    getUrl();
  }, [resId, key]);

  return { url, setResId, setKey };
}
