import type firebase from 'firebase';

type Code = 'email' | 'password' | 'userName' | 'other';
type AuthErr = Partial<Record<Code, string>>;

export function getErrorMsg(err: firebase.auth.Error): AuthErr {
  const { code, message } = err;
  const errs = {
    'auth/wrong-password': { password: 'Wrong password.' },
    'auth/weak-password': { password: 'Password is too weak.' },
    'auth/email-already-in-use': { email: message },
    'auth/user-not-found': { email: 'User not found.' },
    'auth/username-exists': { userName: message },
    'auth/user-error': { userName: message },
    'auth/claim-error': { other: message },
    'auth/claim-failed': { other: message },
  };

  return errs[code as keyof typeof errs] || { other: message };
}
