// Convert e.g. "backgroundColor" to "Background Color".
export function humanizePropertyName(s: string): string {
  // There is no hope for underscore-prefixed properties.
  if (s[0] === '_') {
    return s;
  }

  // Insert a space before all caps.
  const spaced = s.replace(/([A-Z])/g, ' $1');

  // Uppercase the first character.
  return spaced.replace(/^./, (s) => s.toUpperCase());
}
