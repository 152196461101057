import type { StringDictionary } from './util';
import { shouldValidate } from './validateMisc';

export type AuthValues = { email: string; password: string };

export function validateAuth(
  values: StringDictionary,
  type: 'change' | 'blur' | 'submit'
): StringDictionary {
  const { email, password } = values;
  const errors: StringDictionary = {};

  if (shouldValidate(values, type)) {
    _validateEmail(email, errors);
    validatePassword(password, errors);
  }
  return errors;
}

export type RegisterValues = { email: string; password: string; userName: string; code: string };

export function validateRegister(
  values: RegisterValues,
  type: 'change' | 'blur' | 'submit'
): Partial<RegisterValues> & { other?: string } {
  const { email, password, userName } = values;
  const errors: StringDictionary = {};

  if (shouldValidate(values, type)) {
    _validateEmail(email, errors);
    validateNewPassword(password, errors);
    validateUserName(userName, errors);
  }
  return errors;
}

export type EmailValues = { email: string };

export function validateEmail(
  values: EmailValues,
  type: 'change' | 'blur' | 'submit'
): Partial<EmailValues> & { other?: string } {
  const { email } = values;
  const errors: StringDictionary = {};

  if (shouldValidate(values, type)) {
    _validateEmail(email, errors);
  }
  return errors;
}

function _validateEmail(email: string, errors: StringDictionary): void {
  if (!email) {
    errors.email = 'Email required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = 'Invalid email address.';
  }
}

function validatePassword(password: string, errors: StringDictionary): void {
  if (!password) {
    errors.password = 'Password required.';
  }
}

function validateNewPassword(password: string, errors: StringDictionary): void {
  if (!password) {
    errors.password = 'Password required.';
  } else if (password.length < 7) {
    errors.password = 'Passwords must be at least 7 characters long.';
  }
}

const USER_NAME_MIN_LENGTH = 4;
const USER_NAME_MAX_LENGTH = 31;

function validateUserName(name: string, errors: StringDictionary): void {
  if (!name) {
    errors.userName = 'A username is required.';
  } else if (name.length < USER_NAME_MIN_LENGTH) {
    errors.userName = `Username must have at least ${USER_NAME_MIN_LENGTH} characters.`;
  } else if (name.indexOf(' ') !== -1) {
    errors.userName = 'Username cannot include spaces.';
  } else if (/^[0-9].*$/.test(name)) {
    errors.userName = 'Username cannot start with a number.';
  } else if (/^.*-$/.test(name)) {
    errors.userName = 'Username cannot end with a dash.';
  } else if (/^-.*$/.test(name)) {
    errors.userName = 'Username cannot start with a dash.';
  } else if (name.length > USER_NAME_MAX_LENGTH) {
    errors.userName = `Must be less than ${USER_NAME_MAX_LENGTH + 1} characters long.`;
  } else if (!/^[a-zA-Z0-9-]{1,31}$/.test(name)) {
    errors.userName = 'Must only have a-z, A-Z, 0-9, and dash characters.';
  }
}
