import '@fontsource/noto-sans/index.css';

import './App.css';

/* eslint-disable react/no-unescaped-entities */
import { AppBar, CssBaseline, Toolbar } from '@material-ui/core';
import {
  Theme,
  ThemeOptions,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';
import type { ProjectInfo } from '@playful/runtime';
import type firebase from 'firebase/app';
import React, {
  Suspense,
  createContext,
  lazy,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'wouter';

import { AppRoutes } from './AppRoutes';
import { smallScreenThreshold } from './components/componentUtil';
import { Div100vh } from './components/Div100vh';
import { getFirebaseAuth } from './firebase';
import { loadGlobalState } from './globalState';
import Logo from './Play.svg';
import { ProjectStore } from './project/projectStorage';
import { ChooseTemplate } from './TemplateChooser';
import { ThumbnailService } from './thumbnailService';
import { AccountBar } from './user/AccountBar';
import type { User } from './user/user';
import { UserProvider } from './user/UserContext';
import { ConsoleService } from './workbench/console/consoleService';

export const appName = 'Play';

loadGlobalState();

// Export the un-Wouter-monkey-patched history.pushState.
declare global {
  interface Window {
    originalPushState: any;
  }
}

type AppCommander = {
  newProject(): void;
  ToolBarPortal: React.FC;
};

export const pushHistoryState = window.originalPushState?.bind(window.history);

export const consoleService = new ConsoleService();
export const thumbnailService = new ThumbnailService();
export const AppContext = createContext<AppCommander>(undefined as any);

const AppSnacks = lazy(() => import('./AppSnacks' /* webpackChunkName: "AppSnacks" */));

const bodyBackgroundColor = '#ffffff';

const themeOptions: ThemeOptions = {
  palette: {
    // Used by Switch, Slider, Input
    primary: { light: '#f6a5c0', main: '#c5c5c5', dark: '#aa647b' },

    secondary: { light: '#f6a5c0', main: '#f48fb1', dark: '#aa647b' },
    error: { light: '#e57373', main: '#f44336', dark: '#d32f2f' },
    warning: { light: '#ffb74d', main: '#ff9800', dark: '#f57c00' },
    info: { light: '#64b5f6', main: '#2196f3', dark: '#1976d2' },
    success: { light: '#81c784', main: '#4caf50', dark: '#388e3c' },
    text: {
      hint: 'rgb(101, 101, 101)',
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    background: { default: 'rgb(240, 242, 243)', paper: 'rgb(252, 252, 253)' },
    divider: 'rgba(224, 228, 232)', // Also used as DesignerContainer backgroundColor.
    tonalOffset: 0.3,
  },

  overrides: {
    MuiButton: {
      // "default"
      contained: { border: '1px solid', borderRadius: '4px', backgroundColor: 'white' },
    },
    MuiAppBar: { colorPrimary: { backgroundColor: '#ffffff' } },
    MuiCssBaseline: {
      '@global': {
        '.disabled-gestures': {
          overscrollBehaviorX: 'contain',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
      },
    },
    MuiTabs: {
      scrollButtons: {
        width: '20px',
      },
    },
  },

  typography: {
    fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
    body1: {
      fontSize: 14,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: smallScreenThreshold,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application!
    },
  },
};

const defaultTheme = createTheme(themeOptions);

const useStyles = makeStyles((theme) =>
  createStyles({
    logoPlaceholder: {
      width: '36px',
    },
    logo: {
      width: '36px',
      position: 'absolute',
      cursor: 'pointer',
    },
    toolBarContent: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    appContainer: {
      color: '#000',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      userSelect: 'none',
    },
  })
);

export const App: React.FC = () => {
  const [theme, setTheme] = useState(defaultTheme);

  // Apply the theme color to the document body so the iPhone status area
  // takes on that color when Play is launched from the home screen (PWA).
  useEffect(() => {
    document.body.style.backgroundColor = bodyBackgroundColor;
  }, [theme.palette.primary.main]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppContent setTheme={setTheme} />
    </ThemeProvider>
  );
};

export const AppContent: React.FC<{ setTheme: (theme: Theme) => void }> = () => {
  const classes = useStyles();
  const [user, setUser] = useState<User | null>(null);
  const [isApproved, setApproved] = useState(false);
  const [projectStore, setProjectStore] = useState<ProjectStore>();
  const [chooseTemplateOpen, setChooseTemplateOpen] = useState(false);
  const toolBarPortalElementRef = useRef<HTMLDivElement>(null);
  const [location, setLocation] = useLocation();
  const [auth, setAuth] = useState<firebase.auth.Auth>();

  // ProjectInfos are kept at the App level so they don't have to be reloaded e.g. as ProjectExplorer
  // comes and goes.
  const [projectInfos, setProjectInfos] = useState<ProjectInfo[]>([]);

  // Lazy load Firebase auth to get it out of the first bundle.
  useEffect(() => {
    getFirebaseAuth().then((authT) => setAuth(authT));
  }, []);

  const ToolBarPortal: React.FC = useCallback(
    (props) => <Portal parentElement={toolBarPortalElementRef.current}>{props.children}</Portal>,
    []
  );

  useEffect(() => {
    if (user) {
      const store = new ProjectStore(user.id, (projectInfos) => setProjectInfos(projectInfos));

      setProjectStore(store);

      return () => store.close();
    }
  }, [user]);

  const app: AppCommander = {
    newProject(): void {
      setChooseTemplateOpen(true);
    },
    ToolBarPortal,
  };

  return (
    <AppContext.Provider value={app}>
      <UserProvider auth={auth} onUserChange={setUser} onApprovalStatusChange={setApproved}>
        <thumbnailService.RendererPool />

        <Div100vh className={classes.appContainer} style={{ height: '100rvh' }}>
          <AppBar position='relative' elevation={0}>
            <Toolbar variant='dense' disableGutters style={{ marginLeft: '16px' }}>
              <Logo className={classes.logo} alt='Play logo' onClick={() => setLocation('/')} />
              <div className={classes.logoPlaceholder} />
              <div ref={toolBarPortalElementRef} className={classes.toolBarContent} />
            </Toolbar>
          </AppBar>
          <Suspense fallback={null}>
            {user && (
              <>
                {!isApproved && (
                  <app.ToolBarPortal>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                      <AccountBar />
                    </div>
                  </app.ToolBarPortal>
                )}
                <AppRoutes
                  isApproved={isApproved}
                  projectInfos={projectInfos}
                  projectStore={projectStore}
                />
              </>
            )}
            <AppSnacks />
          </Suspense>
          {chooseTemplateOpen && <ChooseTemplate onDone={onChooseTemplateDone} />}
        </Div100vh>
      </UserProvider>
    </AppContext.Provider>
  );

  function onChooseTemplateDone(projectInfo?: ProjectInfo, title?: string): void {
    setChooseTemplateOpen(false);

    if (projectInfo) {
      // TODO: my own setLocation that accepts state?
      setLocation(`/e/${projectInfo.id}?fork&title=${encodeURIComponent(title!)}&from=${location}`);
    }
  }
};

export const Portal: React.FC<{ parentElement?: HTMLElement | null }> = (props) => {
  if (!props.parentElement) {
    return null;
  }
  return ReactDOM.createPortal(props.children, props.parentElement);
};
