// Keep this synced with go/cmd/frontend/config.go
type Config = {
  baseUrl: string;
  apiRoot: string;
  googleProjectId: string;
  firebaseDatabaseUrl: string;
  firebaseAuthDomain: string;
  firebaseApiKey: string;
  firebaseAppId: string;
  firebaseSenderId: string;
};

let cfg: Config | undefined;

export function getConfig() {
  if (!cfg) {
    throw new Error('Please call loadConfig first!');
  }
  return cfg;
}

export async function loadConfig() {
  // Try to load from script tag first
  const elem = document.getElementById('config');
  if (elem) {
    //console.log('Loading config from inline JSON');
    try {
      cfg = JSON.parse(elem.textContent!);
    } catch (err) {
      console.log('Error loading config JSON', err);
    }
  }

  // Nothing yet? Load from server
  if (!cfg) {
    //console.log('Loading config from server');

    // TODO: support a query param for specifying config url
    const res = await fetch('/_/config');
    cfg = await res.json();
  }
}
