import { loadProject, migrateProject } from './project';
import type { Project, ProjectInfo, ProjectState } from './runtime';

export { loadConfig } from './config';
export { deserialize } from './reactor';

export class Player {
  project!: Project;

  private console: Console;
  private container: HTMLElement;
  private resizeObserver: ResizeObserver;

  constructor(
    private state: ProjectState,
    private info: ProjectInfo,
    private resourceRoot: string,
    console?: Console,
    containerOrId?: HTMLElement | string
  ) {
    this.console = console ?? window.console;
    if (typeof containerOrId === 'string') {
      this.container = document.getElementById(containerOrId)!;
    }
    if (containerOrId !== undefined) {
      this.container = containerOrId as HTMLElement;
    } else {
      this.container = document.body;
    }

    // Resize the project as its container resizes.
    // TODO: verify this supports ideal updating on resize

    // Called after layout but before paint.
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === this.container) {
          this.setProjectDimensions(entry.contentRect);
        }
      }
    });
    this.resizeObserver.observe(this.container);
  }

  // Resize project to fill container.
  private setProjectDimensions(rect: DOMRect): void {
    if (!this.project) {
      return;
    }

    const { width, height } = rect;
    if (width !== this.project.playSurfaceWidth || height !== this.project.playSurfaceHeight) {
      this.project.viewportWidth = width;
      this.project.viewportHeight = height;
      this.project.playSurfaceWidth = width;
      this.project.playSurfaceHeight = height;
    }
  }

  async asyncInitialize(): Promise<void> {
    await migrateProject(this.state);
    const project = await loadProject(this.state, this.info, undefined, this.resourceRoot);
    this.project = project;
    this.setProjectDimensions(this.container.getBoundingClientRect());

    // Mount the project inside the Shadow DOM so its styles are encapsulated.
    const shadowRoot = this.container.shadowRoot || this.container.attachShadow({ mode: 'open' });
    try {
      //console.log('mount Player project');
      project.mount?.((shadowRoot as unknown) as HTMLElement);
    } catch (err) {}
  }

  dispose() {
    this.resizeObserver.disconnect();

    try {
      //console.log('dispose Player project');
      this.project?.dispose(); // dispose unmounts
    } catch (err) {}
  }
}
