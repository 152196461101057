import { CircularProgress, Link as MuiLink, Typography } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'wouter';

import { PushButton, Textfield } from '../brand';
import { getFirebaseAuth } from '../firebase';
import { useFormValidation } from '../hooks/useFormValidation';
import { StringDictionary, fromPromise } from '../utils/util';
import { validateEmail } from '../utils/validateAuth';
import { useStyles } from './authStyles';
import { getErrorMsg } from './authutils';

interface ForgotPasswordProps {
  onDone(): void;
  onSubmit(): void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordProps> = (props) => {
  const classes = useStyles();
  const { onDone, onSubmit } = props;
  const [, setLocation] = useLocation();

  const { handleSubmit, handleChange, handleBlur, values, errors, submitting } = useFormValidation(
    { email: '' },
    validateEmail,
    resetPassword
  );

  const sendResetEmail = async () =>
    await (await getFirebaseAuth()).sendPasswordResetEmail(values.email);

  return (
    <div key='forgot-password' className={classes.paper}>
      <Typography component='h1' variant='h5' className={classes.header}>
        Enter your email and we&apos;ll help you reset your password in&nbsp;a&nbsp;jiffy.
      </Typography>
      <form className={classes.form} noValidate onSubmit={handleSubmit}>
        <Textfield
          value={values.email}
          error={!!errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          id='email'
          label='Email'
          type='email'
          name='email'
          autoComplete='email'
          autoCorrect='off'
          autoCapitalize='none'
          spellCheck='false'
          autoFocus
          helperText={errors.email || ''}
        />
        {errors?.other && (
          <Typography variant='body1' color='error' style={{ marginBottom: '1rem' }}>
            {errors.other}
          </Typography>
        )}
        {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
        <div className={classes.dialogActions}>
          <div>
            <PushButton className={classes.submitButton} id='reset-password' disabled={submitting}>
              Reset Password
            </PushButton>
          </div>
          <div className={classes.actionLinks}>
            <MuiLink
              className={classes.link}
              color='inherit'
              onClick={() => setLocation('/signin')}
              variant='body2'
            >
              Sign In
            </MuiLink>
          </div>
        </div>
      </form>
    </div>
  );

  async function resetPassword(): Promise<StringDictionary | undefined> {
    onSubmit();

    const [err] = await fromPromise(sendResetEmail());

    if (err) return getErrorMsg(err);
    onDone();
    setLocation('/signin');
  }
};
