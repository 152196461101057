import { getExpressionScope, isReactor } from '.';

// TODO: disallow access to anything? Globals?

// const fn = compile('(text1.width - width) / 2')
// const fn = compile('`${text1.x} ${text1.y}`')
export function compile(expression: string, additionalScope?: any): (target?: any) => any {
  const code = new Function('scope', `with (scope) { return (${expression}) }`);
  return function (target: any = {}): any {
    let scope = target;
    if (target && isReactor(target)) {
      scope = target[getExpressionScope]();
    }
    if (additionalScope) {
      Reflect.setPrototypeOf(additionalScope, scope);
      return code.call(additionalScope, additionalScope);
    } else {
      return code.call(scope, scope);
    }
  };
}

// Compile a function with the specified scope
export function compileFunction(params: string, script: string, scope?: any): any {
  const funcSrc = `
try {
  with(__scope) {
    return function(${params}){
      ${script}
    }
  }
} catch(e) {
  console.log(e)
}`;

  let func;
  try {
    const createMethod = new Function('__scope', funcSrc);
    func = createMethod(scope ?? {});
  } catch (error) {
    console.log('compileFunction: ', error);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    func = function () {}; // noop
    (func as any).error = error;
  }
  return func;
}
