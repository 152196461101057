import type { ReactorArray } from './reactor';
import type { ReactorFactory } from './reactorFactory';

// Create a ReactorArray that watches a source array for changes and changes itself to match.
export function createMirrorArray(factory: ReactorFactory, array: ReactorArray): ReactorArray {
  const mirror = factory.createReactor(array.slice(), { noId: true }) as ReactorArray;

  Object.defineProperty(mirror, '__mirror', {
    value: true,
    enumerable: false,
    configurable: false,
    writable: false,
  });

  const listener = array.onPropertyChange((reactor, property, newValue, _oldValue, type) => {
    const index = Number(property);
    switch (type) {
      case 'add':
        mirror.splice(index, 0, newValue);
        break;

      case 'remove':
        mirror.splice(index, 1);
        break;

      case 'change':
        mirror[index] = newValue;
        break;
    }
  });

  mirror.dispose = () => listener.dispose();

  return mirror;
}
