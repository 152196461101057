import { getConfig } from '@playful/runtime';
import axios, { AxiosRequestConfig } from 'axios';

import { getFirebaseAuthToken } from './firebase';

async function mergeTokenConfig(config: AxiosRequestConfig & RequestInit) {
  const token = await getFirebaseAuthToken();
  const headerAuth = token ? { Authorization: `Bearer ${token}` } : undefined;

  return {
    ...config,
    headers: {
      ...config.headers,
      ...headerAuth,
    },
  };
}

export async function apiRequest(endpoint: string, config = {}): Promise<any> {
  const mergedConfig = await mergeTokenConfig(config);

  // relative endpoints are relative to the apiRoot
  if (!endpoint.startsWith('/')) {
    endpoint = `${getConfig().apiRoot}/${endpoint}`;
  }
  const res = await fetch(endpoint, mergedConfig);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res;
}

// right now we only augment with a token
axios.interceptors.request.use(mergeTokenConfig);

// we would probably be better served using an axios instance in the future with a base config,
// but because of the asynchronous nature of `getConfig`, this should be fine for now.
// `apiRequest` is still around for backwards compatibility, as functions using it may be
// expecting the error to be res.statusText.
export const axiosRequest = (endpoint: string, config: AxiosRequestConfig = {}) =>
  axios({
    // this should be `baseUrl` of an axios instance, but `getConfig` isn't available until
    // `loadConfig` is first called.
    url: !endpoint.startsWith('/') ? `${getConfig().apiRoot}/${endpoint}` : endpoint,
    ...config,
  });

export async function apiRequestWithRetry(endpoint: string, config = {}, numberOfTries = 5) {
  async function request() {
    return apiRequest(endpoint, config);
  }
  const res = await fetchRetry(request, numberOfTries);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res;
}

// Exponential delay.. Should be 1ms,10ms,100ms,1s,10s,
const delay = (retryCount: number) =>
  new Promise((resolve) => setTimeout(resolve, 10 ** retryCount));

const fetchRetry = async (
  apiRequest: () => Promise<any>,
  numberOfTries: number,
  retryCount = 0,
  lastError?: any
): Promise<any> => {
  if (retryCount > numberOfTries) throw new Error(lastError);
  try {
    const res = await apiRequest();
    // Any request that returns a response, even if that response is 404
    // will not throw an error. However, the response.ok will be false.
    // We want to retry when response.ok is false AND when an exception is thrown.
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res;
  } catch (e) {
    await delay(retryCount);
    return fetchRetry(apiRequest, numberOfTries, retryCount + 1, e);
  }
};

/**
 * Upload an ArrayBuffer to a URL
 * @param uploadUrl
 * @param buf
 * @param mimeType
 * @param onProgress
 */
export async function uploadFile(
  uploadUrl: string,
  buf: ArrayBuffer | Blob,
  mimeType?: string,
  onProgress?: (progress: number) => any
): Promise<any> {
  const xhr = new XMLHttpRequest();
  // relative endpoints are relative to the apiRoot
  if (!uploadUrl.startsWith('/')) {
    uploadUrl = `${getConfig().apiRoot}/${uploadUrl}`;
  }
  xhr.open('PUT', uploadUrl, true);
  xhr.setRequestHeader('Content-Type', mimeType ?? 'application/octet-stream');

  // Add auth token
  const token = await getFirebaseAuthToken();
  if (token) {
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
  }

  const promise = new Promise((resolve, reject) => {
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        const json = JSON.parse(xhr.responseText);
        resolve(json);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
  });

  if (onProgress) {
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        onProgress((e.loaded / e.total) * 100);
      }
    };
  }
  xhr.send(buf);
  return promise;
}
