import { Component, DESCRIPTION, promoteToComponent } from './component';
import type { ComponentDescription, ComponentMetaData } from './descriptions';
import { compile } from './evaluator';
import { getScope } from './expressionScope';
import { createMirrorArray } from './mirrorArray';
import {
  ChangeListener,
  ChangeType,
  ID,
  IDisposable,
  Properties,
  ReactorArray,
  ReactorObject,
  ReactorObjectBase,
  isReactor,
} from './reactor';
import type { CreateReactorOptions, ReactorFactory } from './reactorFactory';
import { isObjectOrArray } from './util';

// Private internals
const _self = Symbol('_self');
const _target = Symbol('_target');
const prototype = Symbol('prototype');
const factory = Symbol('factory');
const dirty = Symbol('dirty');
const changeListeners = Symbol('changeListeners');
const methods = Symbol('methods');
const notifyListeners = Symbol('notifyListeners');
export const updateMethodsObject = Symbol('updateMethodsObject');
const evaluateExpressionProperty = Symbol('evaluateExpressionProperty');
const evaluateExpression = Symbol('evaluateExpression');
export const formulaErrors = Symbol('formulaErrors');
export const scopeRoot = Symbol('scopeRoot');
export const getExpressionScope = Symbol('getExpressionScope');
export const META = Symbol('META');

export interface ReactorObjectInternals extends ReactorObjectBase {
  _isReactor: boolean;
  [_self]: ReactorObjectBase; // For access to the Proxy.
  [_target]: ReactorObjectInternals;
  [prototype]?: any;
  [factory]: ReactorFactory;
  [dirty]?: Properties;
  [methods]?: Properties;
  [changeListeners]: ChangeListener[];
  [formulaErrors]?: Properties;
  [DESCRIPTION]?: ComponentDescription;
  [META]?: ComponentMetaData & ReactorObject;

  [notifyListeners](property: PropertyKey, newValue: any, oldValue: any, type: ChangeType): void;
  [evaluateExpression](property: string, expression: any): any;
  [evaluateExpressionProperty](property: string, onlyFormulas: boolean): void;
  [updateMethodsObject](): void;
  [getExpressionScope](): Properties;
  [scopeRoot]: ReactorObject;
}

// target, traps, receiver
const reactorObjectTraps = {
  // TODO: rewrite as defineProperty trap? More comprehensive and bullet proof?
  // NOTE: When a proxy is on a prototype chain its set trap is called with the
  // inheriting object as the receiver and the proxy as the target.
  set(_: ReactorObjectInternals, property: string | symbol, value: any, receiver: any): boolean {
    if (property === ID) {
      throw `ID is readonly`;
    }

    const oldValue = (_ as any)[property];

    if (typeof property === 'symbol') {
      Reflect.set(_, property, value, receiver);
      if (property === scopeRoot && value !== oldValue) {
        _[updateMethodsObject]();
        receiver.evaluateExpressionProperties(true);
      }
      return true;
    }

    if (property[0] === '$') {
      // Convert expression property objects to Reactors so they'll have ids, change notifications, etc.
      if (isObjectOrArray(value) && !isReactor(value)) {
        value = _[factory].createReactor(value);
      }
    }

    // TODO: ? Reflect.get(_, property, receiver);

    if (value !== oldValue || (value === undefined && !_.hasOwnProperty(property))) {
      if (property[0] === '$') {
        // If the property is being changed from being a formula to not being one then clear out
        // any formula error there might be.
        if (isFormula(oldValue) && !isFormula(value)) {
          const errors = _[formulaErrors];
          if (errors) {
            delete errors[property];
          }
        }

        Reflect.set(_, property, value, receiver);

        // Notify that the expression has changed.
        // TODO: async?
        const changeType = property in _ ? 'change' : 'add';
        _[notifyListeners](property, value, oldValue, changeType);

        if (isMethod(value)) {
          _[updateMethodsObject]();

          // New methods might change how the Reactor updates so force full update.
          receiver.invalidate();
        } else {
          _[evaluateExpressionProperty](property, false);
        }
      } else {
        if (property === 'prototype') {
          _[prototype] = value;
          return Reflect.setPrototypeOf(_[methods] || _, value || ReactorObjectPrototype);
        }

        // Figure out what kind of change it is before we change it!
        const changeType = property in _ ? 'change' : 'add';

        // Use Reflect.defineProperty instead of Relect.set will cause the set trap to be
        // called again the proxy is on a prototype chain.
        Reflect.defineProperty(receiver, property, {
          value,
          writable: true,
          configurable: true,
          enumerable: true,
        });

        // Underscore-prefixed properties and symbols don't cause notifications or invalidation.
        // TODO: just have them live inside a "private" object?
        if (typeof property !== 'symbol' && property[0] !== '_') {
          receiver.invalidate(property);
          // TODO: async?
          _[notifyListeners](property, value, oldValue, changeType);
        }
      }
    }

    return true;
  },

  deleteProperty(_: ReactorObjectInternals, property: string): boolean {
    // TODO: ? Reflect.get(_, property, receiver);
    const oldValue = (_ as any)[property];
    if (property[0] === '$') {
      if (Reflect.deleteProperty(_, property)) {
        // This causes the deleteProperty trap to be called again (case below).
        return delete (_[_self] as any)[property.slice(1)];
      } else {
        return false;
      }
    }

    const success = Reflect.deleteProperty(_, property);
    if (success) {
      // Underscore-prefixed properties and symbols don't cause notifications or invalidation.
      if (typeof property !== 'symbol' && property[0] !== '_') {
        _[_self].invalidate(property);
        // TODO: async?
        _[notifyListeners](property, undefined, oldValue, 'remove');
      }
    }
    return success;
  },

  getOwnPropertyDescriptor(
    _: ReactorObjectInternals,
    property: PropertyKey
  ): PropertyDescriptor | undefined {
    // Reactors don't have any unscopables.
    if (property === Symbol.unscopables) {
      return undefined;
    }

    const descriptor = Reflect.getOwnPropertyDescriptor(_, property);
    if (descriptor) {
      return descriptor;
    }

    // We relocate methods to a prototype object but still want them to appear as
    // part of the instance.
    if (_[methods]) {
      return Reflect.getOwnPropertyDescriptor(_[methods]!, property);
    }
    return undefined;
  },

  // TODO: do this in getOwnPropertyDescriptor trap instead?
  // Filter out properties end-users did not explicitly create (aka "surprise properties").
  // This includes expression ($-prefixed) properties, project, __contextualName, etc.
  ownKeys(_: ReactorObjectInternals): PropertyKey[] {
    let keys = Reflect.ownKeys(_);

    // Methods are on the object's prototype (methods object).
    if (_[methods]) {
      const methodKeys = Reflect.ownKeys(_[methods]!);
      // ownKeys must not return duplicate keys.
      console.assert(!methodKeys.some((method) => keys.includes(method)));
      keys = keys.concat(...methodKeys);
    }

    return keys.filter((key) => {
      if (typeof key === 'symbol' || (typeof key === 'string' && key[0] === '$')) {
        return false;
      }
      return true;
    });
  },
};

// NOTE: using "this", other than for private properties, prevents inheritors from overriding
// that usage of the property. See /(this as any)/, etc.

export const ReactorObjectPrototype: ReactorObjectInternals = {
  // This the prototype so all these live on the instance.
  // We declare them here anyway to make Typescript happy.
  _isReactor: true,
  __contextualName: undefined!,
  [ID]: undefined!,
  [_self]: undefined!,
  [_target]: undefined!,
  [prototype]: undefined!,
  [factory]: undefined!,
  [changeListeners]: undefined!,
  [scopeRoot]: undefined!,

  get id() {
    console.warn('get of deprecated id');
    return this[ID];
  },
  get $id() {
    console.warn('get of deprecated $id');
    return this[ID];
  },

  getState(includeDefaultValues = false): Properties {
    const state: Properties = {
      [ID]: this[ID],
    };
    const expressions = this.getOwnExpressions();

    for (const property in expressions) {
      const value = expressions[property];
      const evaluatedProperty = property.slice(1);

      // Only return Component Object property default values by request.
      if (!includeDefaultValues && this[META]) {
        const defaultValue = this[META]?.properties?.[evaluatedProperty]?.default;
        // TODO: Not smart enough to filter out objects, arrays.
        if (value === defaultValue) {
          continue;
        }
      }

      state[evaluatedProperty] = isReactor(value) ? value.getState(includeDefaultValues) : value;
    }

    return state;
  },

  dispose(): void {
    console.assert(this[ID] !== 0); // Already disposed?

    const expressions = this.getExpressions();
    for (const key in expressions) {
      const value = expressions[key];
      if (isReactor(value)) {
        value.dispose();
      }
    }

    delete this[factory].reactors[this[ID]];
    (this[_target] as any)[ID] = 0;
  },

  onPropertyChange(listener: ChangeListener): IDisposable {
    const listeners = this[changeListeners] || [];
    listeners.push(listener);
    this[changeListeners] = listeners;
    return {
      dispose: () => {
        listeners.splice(listeners.indexOf(listener), 1);
      },
    };
  },

  getExpressions(): Properties {
    let expressions = this.getOwnExpressions();

    let prototype = Reflect.getPrototypeOf(this) as any;
    while (prototype && isReactor(prototype)) {
      // First occurance of a property 'wins'.
      expressions = Object.assign({}, prototype.getOwnExpressions(), expressions);
      prototype = Reflect.getPrototypeOf(prototype);
    }
    return expressions;
  },

  getOwnExpressions(): Properties {
    // TODO: use this or this[_self]?
    const ownExpressionPropertyNames = Object.keys(this[_target] || this).filter(
      (property) => property[0] === '$'
    );
    const ownExpressions: Properties = {};
    for (const property of ownExpressionPropertyNames) {
      // TODO: use this or this[_self]?
      ownExpressions[property] = (this as any)[property];
    }
    return ownExpressions;
  },

  // If the Reactor is dirty call its update method with the dirty properties.
  // Clear the dirty property tracking object.
  validate(): void {
    // We don't want to inherit the dirty properties object.
    const descriptor = Object.getOwnPropertyDescriptor(this, dirty);
    const _dirty = descriptor?.value;

    if (_dirty) {
      if (this[_self].update) {
        this[dirty] = undefined;
        try {
          this[_self].update!(_dirty || {});
        } catch (err) {
          // TODO: make this apparent in the Workbench somehow
          console.error(err);
        }
      }
    }
  },

  // Invalidate all properties or just a specific one.
  invalidate(property?: string): void {
    // We don't want to inherit the dirty properties object.
    const descriptor = Object.getOwnPropertyDescriptor(this, dirty);
    const _dirty = descriptor?.value || {};
    if (property) {
      _dirty[property] = true;
    } else {
      // No property specified. Invalidate all enumerable ones (including inherited).
      for (const property in this) {
        // Underscore prefixed properties, prototype, and id aren't dirty tracked.
        if (property[0] !== '_' && property !== 'prototype') {
          _dirty[property] = true;
        }
      }
    }
    this[dirty] = _dirty;
  },

  clearDirty(): void {
    this[dirty] = undefined;
  },

  evaluateExpressionProperties(onlyFormulas: boolean): void {
    // Want to execute in context of target, not proxy.
    // TODO: true of other public methods as well?
    if (this === this[_self]) {
      this[_target].evaluateExpressionProperties(onlyFormulas);
      return;
    }

    const expressions = this.getOwnExpressions();
    for (const property in expressions) {
      this[evaluateExpressionProperty](property, onlyFormulas);
    }
  },

  [evaluateExpressionProperty](property: string, onlyFormulas: boolean): void {
    const expression = (this as any)[property];
    if (onlyFormulas && !isFormula(expression)) {
      return;
    }
    const valueProperty = property.slice(1);
    if (isMethod(expression)) {
      // Don't evaluate method properties.
      return;
    } else if (Array.isArray(expression)) {
      // Make a copy of the array so changes to it won't be persisted. Changes to its element's
      // expression properties ARE persisted.
      // TODO: only do this if it has changed
      if ((this[_self] as any)[valueProperty] !== undefined) {
        /*
        console.warn(
          `evaluateExpressionProperty of ${(this[_self] as any).name}.${property} replacing array`
        );
        */
        (this[_self] as any)[valueProperty].dispose();
      }
      (this[_self] as any)[valueProperty] = createMirrorArray(
        this[factory],
        expression as ReactorArray
      );
    } else {
      const oldValue = (this[_self] as any)[valueProperty];
      const value = this[evaluateExpression](property, expression);
      if (
        (value !== oldValue && !(Number.isNaN(value) && Number.isNaN(oldValue))) ||
        (value === undefined && !this[_self].hasOwnProperty(valueProperty))
      ) {
        //console.log(`${(this as any).name || this[ID]}.${valueProperty} ${oldValue} -> ${value}`);

        // So ReactorComponent's set trap can invalidate.
        // TODO: do we need this any more (as opposed to values[property] = value)
        // The difference is that this invalidates and causes a change event to be fired.
        (this[_self] as any)[valueProperty] = value;
      }
    }
  },

  [evaluateExpression](property: string, expression: any): any {
    let value = expression;

    if (typeof expression === 'string') {
      switch (expression[0]) {
        // Formula expressions
        case '=':
          try {
            // TODO: Hang on to compiled expressions as a performance optimization
            const formula = expression.slice(1);
            value = undefined;

            // If the formula is empty don't execute it. Treat it as undefined.
            if (formula.length > 0) {
              // TODO: don't compile these very time!
              // Perhaps define the evaluated property as a getter?
              const compiledExpression = compile(formula);
              value = compiledExpression(this);
            }

            // If it's a Promise when it resolves set the property.
            // NOTE: This is useless while everything is being reevaluated every frame.
            if (value instanceof Promise) {
              value.then((v) => ((this[_self] as any)[property.slice(1)] = v));
              // TODO: Is this really what we want? No evaluated promises?
              value = undefined;
            }

            // If we know what type this should be and it didn't eval to such coerce it.
            const propMeta = this[META]?.properties?.[property.slice(1)];
            if (propMeta && propMeta.type !== typeof value) {
              switch (propMeta.type) {
                case 'string':
                  value = String(value);
                  break;

                case 'boolean':
                  value = Boolean(value);
                  break;

                case 'number':
                  value = Number(value);
                  break;

                default:
                  value = undefined;
              }
            }

            // Clear out any error that might be associated with this property's formula.
            const errors = this[formulaErrors];
            if (errors) {
              delete errors[property];
            }
          } catch (err) {
            // Remember this formula error for reporting elsewhere.
            const errors = this[formulaErrors] || {};
            errors[property] = err;
            this[formulaErrors] = errors;
            //console.error(err);
            value = undefined;
          }
          break;

        // Other special expressions
        case '$':
          // TODO:
          break;

        // All others are returned unchanged as the value.
      }
    }
    return value;
  },

  [notifyListeners](property: PropertyKey, newValue: any, oldValue: any, type: ChangeType): void {
    // TODO: batch? asyncify?
    if (this[changeListeners]) {
      for (const listener of this[changeListeners]) {
        listener(this[_self], property, newValue, oldValue, type);
      }
    }
  },

  // return a scope object
  [getExpressionScope](): Properties {
    return getScope(this[scopeRoot]);
  },

  // Create a "methods object" with all the Reactor's methods. Set it as the prototype
  // of the value object. Set the methods object's prototype to be a Proxy that looks up
  // requested properties in the Reactor's prototype.
  // This structure allows methods to use "super" to call methods inherited from a prototype.
  [updateMethodsObject](): void {
    const methodStrings: Array<{
      name: string;
      methodString: string;
    }> = [];
    const expressions = this.getOwnExpressions();
    for (const property in expressions) {
      const expression = expressions[property];
      // Function expressions
      if (isMethod(expression)) {
        const { script, params } = extractScript(expression);
        const valueProperty = property.slice(1);

        delete (this as any)[valueProperty]; // Clear out any errant value props to ensure the method obj wins

        methodStrings.push({
          name: valueProperty,
          methodString: `${valueProperty}(${params}) {
        ${script.replace(/\n/g, '\n        ')}
      }`,
        });
      }
    }

    try {
      // Only create the methods object if there are some methods.
      if (methodStrings.length !== 0) {
        // NOTE: Including reactor id to guarentee uniqueness. Overall, it'd be better to only include it
        // when needed, but that's....more work
        const name = ((this.__contextualName || expressions.$name || 'id') + '_' + expressions.$id)
          .replace(/ /g, '-')
          .replace(/\//g, ':')
          .replace(/\./g, '/');

        try {
          // eslint-disable-next-line no-new-func
          const createMethodsObject = new Function(
            'getExpressionScope',
            // Source map to help with debugging.
            // Let's pause for a moment to appreciate how cool it is to be able to create a
            // dynamic source map for dynamic code.
            `// ${name}
  const __scope = getExpressionScope();
  with(__scope) {
    return {
      ${methodStrings.map((it) => it.methodString).join(',\n\n')}
    }
  }
//# sourceURL=playful:///${name}.js`
          );
          const m = createMethodsObject(this[getExpressionScope].bind(this));
          for (const method in m) {
            m[method] = m[method].bind(this[scopeRoot]);
          }

          this[methods] = m;
        } catch (e) {
          let foundErr = false;
          // If there is an error, take a moment to individually wrap the handlers
          // and determine which ones fail.
          methodStrings.forEach((methodString) => {
            try {
              new Function(`return {${methodString.methodString}}`);
            } catch (error) {
              /* TODO: Method errors are happening constantly as code is edited and in a partial state.
              Don't report them until we have a good way to distinguish edit and runtime errors.
              console.error(expressions.$name, 'method error in ' + methodString.name);
              */
              foundErr = true;
            }
          });
          this[methods] = undefined;
          if (!foundErr) {
            console.log(e);
          }
        }
      } else {
        this[methods] = undefined;
      }

      // Resolve the prototype.
      this[prototype] = undefined;

      const description = ((this as unknown) as Component)[DESCRIPTION];
      if (description) {
        // Adopt the Component's prototype, if defined.
        if (description.prototype) {
          if (typeof description.prototype === 'string') {
            // TODO: resolve if string
            console.error('Component prototype reference by string not implemented yet.');
          } else {
            this[prototype] = description.prototype;
          }
        }
      }

      // Create the prototype chain:
      // Reactor/Component instance -> methods object -> description.prototype -> ReactorObjectPrototype.
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let link: any = this;
      if (this[methods]) {
        Reflect.setPrototypeOf(link, this[methods] || null);
        link = this[methods]!;
      }
      if (this[prototype]) {
        appendPrototype(this[prototype]!, ReactorObjectPrototype);
        Reflect.setPrototypeOf(link, this[prototype] || null);
      } else {
        Reflect.setPrototypeOf(link, ReactorObjectPrototype);
      }
    } catch (err) {
      console.error(
        'ERROR. This should never happen and may leave the component in a non loadable state',
        err
      );
    }
  },
};

// Make all the internal Reactor properties non-enumerable (same behavior as Object).
const descriptors = Object.getOwnPropertyDescriptors(ReactorObjectPrototype);
for (const property in descriptors) {
  descriptors[property].enumerable = false;
  descriptors[property].configurable = false; // Supposedly helps perf.
}
Object.defineProperties(ReactorObjectPrototype, descriptors);

export function createReactorObject(
  _factory: ReactorFactory,
  properties?: Properties,
  options?: CreateReactorOptions
): ReactorObjectBase {
  // Copy properties because we might change them.
  properties = { ...properties };

  // TODO: set up inheritance chain.
  const prototype: ReactorObjectBase = ReactorObjectPrototype;

  // TODO: better name for target
  const target = Object.create(prototype);
  target[_target] = target;

  const self = new Proxy(target, reactorObjectTraps as any);
  target[_self] = self; // TODO: or "proxy"?
  target[factory] = _factory;
  target[scopeRoot] = self; // Default to setting expression scope to the the reactor itself

  // Reactor will already have an id if it is being deserialized or recreated by undo/redo.
  let id = properties?.[ID];

  if (id) {
    // Be sure generated ids don't overlap existing ids.
    // BUGBUG: when id-less Reactors are created before the highest id is found they
    // will be assigned a (potentially) colliding id.
    if (id >= _factory.nextReactorId) {
      _factory.nextReactorId = id + 1;
    }
    delete properties[ID];
  } else {
    id = _factory.nextReactorId++;
  }

  console.assert(
    _factory.reactors[id] === undefined,
    `Reactor id ${id} already in use!\n`,
    _factory.reactors[id]
  );
  _factory.reactors[id] = self;

  // Set here so there is an instance property for evaluateExpressionProperties to set into.
  // Otherwise it will fail in Proxy set trap because id is read-only.
  target[ID] = id;

  // Retain the root Reactor. This is the one assumed to be the Project and that
  // Reactor paths are relative to. Augment the project with ReactorFactory methods.
  if (_factory.rootReactor === undefined) {
    target.createReactor = _factory.createReactor.bind(_factory);
    target.getReactorById = _factory.getReactorById.bind(_factory);
    target.hasReactor = _factory.hasReactor.bind(_factory);
    target.getReactorByPath = _factory.getReactorByPath.bind(_factory);
    target.forEachReactor = _factory.forEachReactor.bind(_factory);
    _factory.rootReactor = self;

    // Initialize Project with a resolved imports object.
    target.imports = _factory.createReactor(
      {},
      { contextualName: (options?.contextualName || '') + '.imports' }
    );

    if (options?.imports) {
      for (const key in options.imports) {
        target.imports[key] = options.imports[key];
      }
    }
  }

  target.project = _factory.rootReactor;
  Object.defineProperty(target, 'project', {
    enumerable: false,
    configurable: true,
    writable: true,
  });

  if (options?.contextualName) {
    Object.defineProperty(target, '__contextualName', { value: options.contextualName });
  }

  // Copy initial properties as expressions and recursively create child objects as Reactors.
  if (properties) {
    for (const property in properties) {
      let value = properties[property];
      if (isObjectOrArray(value)) {
        value = _factory.createReactor(value, {
          contextualName: (target.__contextualName ? target.__contextualName + '.' : '') + property,
          updateMethods: options?.updateMethods,
        });
      }
      target['$' + property] = value;
    }
  }

  if (options?.updateMethods) {
    options.updateMethods(self);
  } else {
    // If the Reactor is a Component hook it up with its ComponentDescription.
    // Do this before updateMethods because it will use ComponentDescription.prototype.
    if (self.$componentType) {
      promoteToComponent(self, target.project);
    }
    target[updateMethodsObject]();
  }

  target.evaluateExpressionProperties();

  // Now that all initial values, methods, inheritance, etc are set let the Component
  // further initialize itself (unless Project loading is going to do it).
  if (!options?.updateMethods && self.$componentType) {
    // init() is a Component, not Reactor concept.
    self.init?.();
  }

  return self;
}

// Returns:
// script: The body of the function, e.g. "whatever = true"
// params: The parameters of the function, e.g. "x, y"
export function extractScript(expression: string): { script: string; params: string } {
  const paramsStart = expression.indexOf('(') + 1;
  const paramsEnd = expression.indexOf(')');
  let bodyStart = expression.indexOf('{') + 1;
  if (expression[bodyStart] === '\n') {
    bodyStart++;
  }
  const bodyEnd = expression.lastIndexOf('}');
  const params = expression.slice(paramsStart, paramsEnd).trim();
  const script = expression.slice(bodyStart, bodyEnd);
  /*
  console.log('expression:');
  console.log(expression);
  console.log('params:');
  console.log(params);
  console.log('script:');
  console.log(script);
  */
  return { params, script };
}

// TODO: reconcile with isFunction in projectModel.ts
export function isMethod(expression: string): boolean {
  return typeof expression === 'string' && expression.startsWith('$function');
}

export function isFormula(expression: any): boolean {
  return typeof expression === 'string' && expression[0] === '=';
}

export function getAllValuesOfProperty(
  reactor: ReactorObject,
  property: string
): {
  values: any[] | undefined;
  templateNames: string[] | undefined;
  templateObjects: ReactorObject[] | undefined;
} {
  const values = [];
  const templateNames = [];
  const templateObjects = [];

  const templateName = 'this';

  if (reactor.hasOwnProperty(property)) {
    values.push(reactor[property]);
    templateNames.push(templateName);
    templateObjects.push(reactor);
  }

  if (values.length === 0) {
    return { values: undefined, templateNames: undefined, templateObjects: undefined };
  }
  return { values, templateNames, templateObjects };
}

// Return the last link of the prototype chain (the one that has Object as its prototype).
// May return the passed-in object.
function getLastNonObjectPrototype(link: any, terminator?: any): any {
  // Even Object has a prototype (it's Object) so this can't loop forever.
  while (true) {
    const proto = Reflect.getPrototypeOf(link);
    if (proto === terminator) {
      return proto;
    }
    if (proto === Object.prototype || proto === null) {
      return link;
    }
    link = proto;
  }
}

// Append the prototype to the chain if it isn't already on it.
export function appendPrototype(chain: any, prototype: any): void {
  console.assert(chain !== undefined);
  const proto = getLastNonObjectPrototype(chain, prototype);

  // If this chain already has this prototype on it, do nothing.
  if (proto === prototype) {
    return;
  }
  Object.setPrototypeOf(proto, prototype);
}
