import type {
  BlurHandlerEventType,
  ChangeHandlerEventType,
  FormValidationChange,
} from '@playful/runtime';
import { FormEvent, useEffect, useRef, useState } from 'react';

function isValidationChange(event: any): event is FormValidationChange {
  return event.target === undefined && event.name !== undefined;
}

// A helper function when given a generic ChangeHandlerEventType
// will return the part you care about (the changed value) in a standardized way.
export function getChange(event: ChangeHandlerEventType): FormValidationChange {
  if (isValidationChange(event)) {
    return event;
  }

  const { name, type, value, checked } = event.target as HTMLInputElement;

  let castValue;
  if (type === 'number') {
    castValue = parseInt(value, 10);
  } else if (type === 'checkbox') {
    castValue = checked;
  } else {
    castValue = value;
  }

  return { name, value: castValue };
}

export type ReturnPromise<T> = Promise<Partial<T> | void | undefined>;

export function useFormValidation<T>(
  initialState: T,
  validate: (values: T, type: 'change' | 'blur' | 'submit') => Partial<T> & { other?: string },
  submit: () => ReturnPromise<T>
) {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState<Partial<T> & { other?: string }>({});
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  // The component calling us can be unmounted as part of submit() or while
  // we're waiting for it to complete. Track mounted state so we know what to
  // do when submit returns.
  const mounted = useRef(true);

  function handleChange(event: ChangeHandlerEventType) {
    const { name, value } = getChange(event);

    const validationErrors = validate({ [name]: value } as any, 'change');
    if ((validationErrors as any)[name]) {
      setErrors({ ...errors, [name]: (validationErrors as any)[name] });
    } else {
      delete (errors as any)[name];
      setErrors({ ...errors });
    }
    setValues({ ...values, [name]: value });
  }

  function handleBlur(event: BlurHandlerEventType) {
    // Only validate the element being blurred (losing focus).
    const { name, value } = getChange(event);
    const validationErrors = validate({ [name]: value } as any, 'blur');
    if ((validationErrors as any)[name])
      setErrors({ ...errors, [name]: (validationErrors as any)[name] });
  }

  function handleSubmit(event: FormEvent) {
    event.preventDefault();
    const validationErrors = validate(values, 'submit');
    setErrors(validationErrors);
    setSubmitting(true);
  }

  // Effect to track whether calling component is still mounted.
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  // Don't forget: this will be called EVERY update of the calling component.
  useEffect(() => {
    // Don't start the submit effect until requested (and errors has changed).
    if (submitted || !submitting) return;

    // If the user still has errors to deal with. Don't submit.
    if (Object.keys(errors).length !== 0) {
      setSubmitting(false);
      return;
    }

    // Tbis is to make sure we only submit once.
    setSubmitted(true);

    submit().then((errors) => {
      // If we're still mounted set the appropriate state.
      if (mounted.current) {
        setSubmitting(false);
        setSubmitted(false);
        if (errors) setErrors(errors);
      }
    });
  }, [mounted, errors, submitting, submit, submitted]);

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    submitting,
    setValues,
  };
}
