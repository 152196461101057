import type { FunctionComponent } from './descriptions';
import type {
  BlurHandlerType,
  ChangeHandlerType,
  Component,
  PropertyDescription,
  PropertyType,
} from '.';

export type PropertyChangeOption = 'intermediate' | 'pending' | 'commit' | 'cancel';

// Pre-defined property editors types.
export type PropertyEditorType =
  | 'Boolean'
  | 'String'
  | 'Number'
  | 'Date'
  | 'MultilineString'
  | 'Color'
  | 'Option'
  | 'NumberOrOption'
  | 'FontFamily'
  | 'ComponentReference'
  | 'ImageSource'
  | 'Script'
  | 'Method'
  | 'Formula'
  | 'OptionList'
  | 'ResourceReference';

type BasePropertyEditorProps = {
  /**
   * Properties added here will be available on all property editors.
   */
  disabled?: boolean;

  /**
   * Allows the editor component to use the full width of the inspector by
   * moving the property label above the editor area instead of the default
   * which is side-by-side
   */
  fullWidthEditor?: boolean;

  /**
   * If true the property is displayed on the Inspector's code tab rather
   * than the properties tab.
   */
  code?: boolean;
};

/**
 * The props that will be sent to all PropertyEditorComponents
 */
export type PropertyEditorComponentProps<EditorType extends PropertyEditorType> = {
  target: Component;
  type: EditorType;

  // The string key of the property prefixed with a $, i.e. $fontSize, $width, etc.
  property: string;

  /**
   * Method provided to all Editor components, it is how a Editor changes the property value
   * @param newValue - New value to be set on the ProjectModel
   * @param options - The type of change to make: 'intermediate' | 'pending' | 'commit' | 'cancel'
   */
  onValueChange: (newValue: any, options?: PropertyChangeOption) => void;

  // Commits any intermediate changes
  commitChange: () => void;

  // Property's current value, including intermediate values
  value: any;

  isSmallScreen: boolean;

  description: PropertyDescription;

  // Human readable name, if propertyDescription.title is set it's used, otherwise
  // use a humanized version of the property.
  title: string;
} & PropertyEditorProps;

export type PropertyEditorProps =
  | NumberEditorProps
  | StringEditorProps
  | BooleanEditorProps
  | MultilineStringEditorProps
  | ColorEditorProps
  | OptionEditorProps
  | NumberOrOptionEditorProps
  | FontFamilyEditorProps
  | ImageSourceEditorProps
  | DateEditorProps
  | ComponentReferenceEditorProps
  | ScriptEditorProps
  | MethodEditorProps
  | FormulaEditorProps
  | OptionListEditorProps
  | ResourceReferenceEditorProps;

export type BooleanPropertyEditorComponent = PropertyEditorComponent<'Boolean'>;
export type StringPropertyEditorComponent = PropertyEditorComponent<'String'>;
export type NumberPropertyEditorComponent = PropertyEditorComponent<'Number'>;
export type DatePropertyEditorComponent = PropertyEditorComponent<'Date'>;
export type MultilineStringPropertyEditorComponent = PropertyEditorComponent<'MultilineString'>;
export type ColorPropertyEditorComponent = PropertyEditorComponent<'Color'>;
export type OptionPropertyEditorComponent = PropertyEditorComponent<'Option'>;
export type NumberOrOptionPropertyEditorComponent = PropertyEditorComponent<'NumberOrOption'>;
export type FontFamilyPropertyEditorComponent = PropertyEditorComponent<'FontFamily'>;
export type ComponentReferencePropertyEditorComponent = PropertyEditorComponent<'ComponentReference'>;
export type ImageSourcePropertyEditorComponent = PropertyEditorComponent<'ImageSource'>;
export type ScriptPropertyEditorComponent = PropertyEditorComponent<'Script'>;
export type MethodPropertyEditorComponent = PropertyEditorComponent<'Method'>;
export type FormulaPropertyEditorComponent = PropertyEditorComponent<'Formula'>;
export type OptionListPropertyEditorComponent = PropertyEditorComponent<'OptionList'>;
export type ResourceReferencePropertyEditorComponent = PropertyEditorComponent<'ResourceReference'>;

//TODO: Explore further for a more specific type than React.ComponentType
export type PropertyEditorComponent<EditorType extends PropertyEditorType> = React.ComponentType<
  PropertyEditorComponentProps<EditorType>
>;

type PropertyEditorParameterEditorComponent<Props extends PropertyEditorProps> = FunctionComponent<{
  values: Partial<Props>;
  errors: { [key in keyof Props]: string };
  onChange: ChangeHandlerType;
  onBlur: BlurHandlerType;
}> & {
  getProperties?: (props: Props) => Partial<Props>;
  validateInput?: (props: Props) => Partial<Props> & { other?: string };
};

export type NumberPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<NumberEditorProps>;
export type StringPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<StringEditorProps>;
export type ComponentReferencePropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<ComponentReferenceEditorProps>;
export type BooleanPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<BooleanEditorProps>;
export type DatePropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<DateEditorProps>;
export type MultilineStringPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<MultilineStringEditorProps>;
export type ColorPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<ColorEditorProps>;
export type OptionPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<OptionEditorProps>;
export type NumberOrOptionPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<NumberOrOptionEditorProps>;
export type FontFamilyPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<FontFamilyEditorProps>;
export type ImageSourcePropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<ImageSourceEditorProps>;
export type ScriptPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<ScriptEditorProps>;
export type FormulaPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<FormulaEditorProps>;
export type MethodPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<MethodEditorProps>;
export type OptionListPropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<OptionListEditorProps>;
export type ResourceReferencePropertyEditorParameterEditorComponent = PropertyEditorParameterEditorComponent<ResourceReferenceEditorProps>;

/**
 * Add additional properties to the EditorProps to expose additional configuration options when the
 * type is chosen
 */
export type NumberEditorProps = BasePropertyEditorProps & {
  type: 'Number';

  component?: NumberPropertyEditorComponent;
  mobileComponent?: NumberPropertyEditorComponent;
  propertyParameterEditor?: NumberPropertyEditorParameterEditorComponent;

  min?: number;
  max?: number;
  step?: number;
};

export type StringEditorProps = BasePropertyEditorProps & {
  type: 'String';

  component?: StringPropertyEditorComponent;
  mobileComponent?: StringPropertyEditorComponent;
  propertyParameterEditor?: StringPropertyEditorParameterEditorComponent;

  maxLength?: number;
  multiLine?: boolean;
  spellCheck?: boolean;
};

export type ComponentReferenceEditorProps = BasePropertyEditorProps & {
  type: 'ComponentReference';

  component?: ComponentReferencePropertyEditorComponent;
  mobileComponent?: ComponentReferencePropertyEditorComponent;
  propertyParameterEditor?: ComponentReferencePropertyEditorParameterEditorComponent;

  category?: string;
};

export type BooleanEditorProps = BasePropertyEditorProps & {
  type: 'Boolean';

  component?: BooleanPropertyEditorComponent;
  mobileComponent?: BooleanPropertyEditorComponent;
  propertyParameterEditor?: BooleanPropertyEditorParameterEditorComponent;
};

export type DateEditorProps = BasePropertyEditorProps & {
  type: 'Date';

  component?: DatePropertyEditorComponent;
  mobileComponent?: DatePropertyEditorComponent;
  propertyParameterEditor?: DatePropertyEditorParameterEditorComponent;
};

export type MultilineStringEditorProps = BasePropertyEditorProps & {
  type: 'MultilineString';

  component?: MultilineStringPropertyEditorComponent;
  mobileComponent?: MultilineStringPropertyEditorComponent;
  propertyParameterEditor?: MultilineStringPropertyEditorParameterEditorComponent;

  rows?: number;
  minRows?: number;
  maxRows?: number;
  spellCheck?: boolean;
};

export type ColorEditorProps = BasePropertyEditorProps & {
  type: 'Color';

  component?: ColorPropertyEditorComponent;
  mobileComponent?: ColorPropertyEditorComponent;
  propertyParameterEditor?: ColorPropertyEditorParameterEditorComponent;
};

export type OptionEditorProps = BasePropertyEditorProps & {
  type: 'Option';

  component?: OptionPropertyEditorComponent;
  mobileComponent?: OptionPropertyEditorComponent;
  propertyParameterEditor?: OptionPropertyEditorParameterEditorComponent;

  options?: Array<string | number | ObjectOption>;
};

export type NumberOrOptionEditorProps = BasePropertyEditorProps & {
  type: 'NumberOrOption';

  component?: NumberOrOptionPropertyEditorComponent;
  mobileComponent?: NumberOrOptionPropertyEditorComponent;
  propertyParameterEditor?: NumberOrOptionPropertyEditorParameterEditorComponent;

  min?: number;
  max?: number;
  step?: number;
  options?: Array<number | ObjectOption<number>>;
};

export type FontFamilyEditorProps = BasePropertyEditorProps & {
  type: 'FontFamily';

  component?: FontFamilyPropertyEditorComponent;
  mobileComponent?: FontFamilyPropertyEditorComponent;
  propertyParameterEditor?: FontFamilyPropertyEditorParameterEditorComponent;
};

export type ImageSourceEditorProps = BasePropertyEditorProps & {
  type: 'ImageSource';
  component?: ImageSourcePropertyEditorComponent;
  mobileComponent?: ImageSourcePropertyEditorComponent;
  propertyParameterEditor?: ImageSourcePropertyEditorParameterEditorComponent;
};

export type ScriptEditorProps = BasePropertyEditorProps & {
  type: 'Script';

  component?: ScriptPropertyEditorComponent;
  mobileComponent?: ScriptPropertyEditorComponent;
  propertyParameterEditor?: ScriptPropertyEditorParameterEditorComponent;

  language?: string;
  rows?: number;
  minRows?: number;
  maxRows?: number;
};

export type FormulaEditorProps = BasePropertyEditorProps & {
  type: 'Formula';

  component?: ScriptPropertyEditorComponent;
  mobileComponent?: ScriptPropertyEditorComponent;
  propertyParameterEditor?: FormulaPropertyEditorParameterEditorComponent;

  language?: string;
  rows?: number;
  minRows?: number;
  maxRows?: number;
};

export type MethodEditorProps = BasePropertyEditorProps & {
  type: 'Method';

  component?: ScriptPropertyEditorComponent;
  mobileComponent?: ScriptPropertyEditorComponent;
  propertyParameterEditor?: MethodPropertyEditorParameterEditorComponent;

  language?: string;
  rows?: number;
  minRows?: number;
  maxRows?: number;
};

export type OptionListEditorProps = BasePropertyEditorProps & {
  type: 'OptionList';
  component?: OptionListPropertyEditorComponent;
  mobileComponent?: OptionListPropertyEditorComponent;
  propertyParameterEditor?: OptionListPropertyEditorParameterEditorComponent;
};

export type ObjectOption<ValueType = string | number> = {
  title: string;
  value: ValueType;
  description?: string;
};

export type ResourceReferenceEditorProps = BasePropertyEditorProps & {
  type: 'ResourceReference';

  component?: ResourceReferencePropertyEditorComponent;
  mobileComponent?: ResourceReferencePropertyEditorComponent;
  propertyParameterEditor?: ResourceReferencePropertyEditorParameterEditorComponent;

  showPreview?: boolean;
  mimeFilter?: string;
};

/**
 * TypeGuard to determine if an option is a flat array or an array of { title, value } objects
 */
export function isObjectOption(option: string | number | ObjectOption): option is ObjectOption {
  return (option as ObjectOption).title !== undefined;
}

/**
 * TypeGuard to determine if the EditorProps are a flat type, i.e. "String" or an object:
 * {
 *     type: 'String',
 *     ...etc
 * }
 */
export function isPropertyEditorProps(editorProps: any): editorProps is PropertyEditorProps {
  return (editorProps as PropertyEditorProps).type !== undefined;
}

/**
 * TypeGuard to determine if a given string (such as the result of `typeof value`) is a valid `PropertyType`
 */
export const isSupportedPropertyType = (
  propertyType: PropertyType | string
): propertyType is PropertyType => {
  //TODO: Expand to include all `PropertyType`s
  return ['boolean', 'string', 'number', 'function', 'array'].includes(propertyType);
};
