import { Dialog, DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import Logo from '../logo.svg';
import { useStyles } from './authStyles';
import type { AuthForm } from './authTypes';
import { ForgotPasswordForm } from './forgotPasswordForm';
import { SignInForm } from './signInForm';
import { SignUpForm } from './signUpForm';
import { useUserContext } from './UserContext';

interface AuthDialogProps {
  allowClose?: boolean;
  onDone?(): void;
  open: boolean;
  form?: AuthForm;
}

export const AuthDialog: React.FC<AuthDialogProps> = (props) => {
  const [, setLocation] = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const { isLoggedIn } = useUserContext();
  const { allowClose = true, open, form, onDone = () => setSubmitting(false) } = props;
  const classes = useStyles();

  const handleSubmit = () => setSubmitting(true);

  // we can't do this at the routing level atm bc of how we subscribe to firebase updates and the logic
  // in the registration function. The moment that auth.createUserWithEmailAndPassword is successful,
  // the useEffect in UserContext that's connected to firebase will run and update the user status to
  // logged in (but not approved, necessarily). If we check isLoggedIn in the routing, it will rerender
  // and redirect from here before the rest of logic in the registration fn can run.
  useEffect(() => {
    if (!submitting && isLoggedIn) setLocation('/');
  }, [isLoggedIn, setLocation, submitting]);

  return (
    <div>
      <Dialog
        maxWidth='xs'
        scroll='body'
        open={open}
        onClose={allowClose ? onDone : undefined}
        aria-labelledby='form-dialog-title'
        PaperProps={{ style: { margin: 8, borderRadius: 0 } }}
      >
        <DialogContent className={classes.dialogContent}>
          <Logo className={classes.dialogLogo} />
          {form === 'signin' && <SignInForm onSubmit={handleSubmit} onDone={onDone} />}
          {form === 'forgot-password' && (
            <ForgotPasswordForm onSubmit={handleSubmit} onDone={onDone} />
          )}
          {form === 'register' && <SignUpForm onSubmit={handleSubmit} onDone={onDone} />}
        </DialogContent>
      </Dialog>
    </div>
  );
};
