import { filterT } from '@playful/playkit/utils';
import { IResource, ResourceData, ResourceId, getResourceUrl } from '@playful/runtime';

import { apiRequest, apiRequestWithRetry, uploadFile } from './apiService';
import { getFirebaseAuthToken } from './firebase';
import { AudioWaveform } from './media/AudioWaveform';
import { ImageThumbnail } from './media/ImageThumbnail';
import { VideoThumbnail } from './media/VideoThumbnail';

export const MAX_RESOURCE_SIZE_IN_BYTES = 1024 * 1024 * 32; // 32 mb

// Well known Paths
export const PROJECT_JSON = 'project.json';
export const PREVIEW = 'preview';
export const ORIG = 'orig';
export const THUMB = 'thumb';

// Resource Types
// TODO: use an enum?
export const USER_UPLOAD = 'user_library';
export const PROJECT_STATE = 'project_state';

export const buildPath = (...args: any[]) => filterT(args).join('/');

export const buildPreviewPath = (view: string, name: string, ...args: any[]) =>
  buildPath(view, name, PREVIEW, ...args);

// Capture the shape of the resource json data
export type ResourceJson = {
  id: string;
  secret: string;
  owner: string;
  type: string;
  name: string;
  created: string;
  modified: string;
  keys: Array<ResourceData>;
};

export type ResourcePermissions = {
  public: boolean;
};

export class Resource implements IResource {
  readonly id: ResourceId;
  secret?: string;
  name?: string;
  keys: Array<ResourceData>;
  created: Date;

  // Local created stores the date a resource *began* being created on the client side.
  // It is not persisted.
  localCreated?: Date;
  modified: Date;

  /**
   * Create a new resource on the server
   * @param type Resource type
   * @param name Optional name for the resource
   */
  static async create(type: string, name?: string): Promise<Resource> {
    const data = { type, name };
    const ret = await apiRequest('resources', {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resourceJson: ResourceJson = await ret.json();
    return this.fromJSON(resourceJson);
  }

  /**
   * Get a resource
   * @param id Resource id
   */
  static async get(id: string): Promise<Resource> {
    const ret = await apiRequest(`resources/${id}`, {
      method: 'GET',
    });
    const resourceJson: ResourceJson = await ret.json();
    return this.fromJSON(resourceJson);
  }

  /**
   * Return an authenticated url to the resource + key
   * @param id Resource id
   * @param key Resource key
   */
  static async getDataUrlAuth(id: string, key: string): Promise<string> {
    let url = getResourceUrl(id, key);
    const token = await getFirebaseAuthToken();
    if (token) {
      url += `?auth=${token}`;
    }
    return url;
  }

  /**
   * Create a Resource from server JSON
   */
  static fromJSON(json: ResourceJson, res?: Resource, mergeKeys?: boolean): Resource {
    json.keys = json.keys || [];
    if (!res) {
      res = new Resource(json.id, json.name);
      res.keys = json.keys;
      res.created = new Date(json.created);
    } else {
      if (mergeKeys) {
        res.keys = Resource.mergeKeys(res.keys, json.keys);
      } else {
        res.keys = json.keys;
      }
    }
    res.modified = new Date(json.modified);
    res.secret = json.secret;
    return res;
  }

  /**
   * Given two arrays of ResourceData, attempt to merge them based on the key.
   * keysB will override keysA if both have the same property.
   */
  static mergeKeys(keysA: ResourceData[], keysB: ResourceData[]): ResourceData[] {
    const merged = keysA.map((aData) => {
      const jsonKeyIndex = keysB.findIndex((bData) => bData.key === aData.key);
      if (jsonKeyIndex !== -1) {
        const d = keysB[jsonKeyIndex];
        keysB.splice(jsonKeyIndex, 1);
        return {
          ...aData,
          ...d,
        };
      } else {
        return aData;
      }
    });
    // Spread the merged keys followed by the keys found in B that were not found in A
    return [...merged, ...keysB];
  }

  /**
   * Constructor
   * @param id ResourceId
   */
  constructor(id: ResourceId, name?: string) {
    this.id = id;
    this.name = name;
    this.created = new Date();
    this.modified = this.created;
    this.keys = [];
  }

  /**
   * Upload to a resource key
   * @param key Resource key
   * @param blob Blob or File to upload
   * @param onUpdateProgress Called when the resource is updated while uploading, like when the progress changes
   */
  async uploadDataBlob(
    key: string,
    blob: Blob | File,
    onUpdateProgress?: (res: Resource) => any
  ): Promise<ResourceData> {
    const uploadUrl = this.getDataUrl(key, 'put');
    if (blob.size > MAX_RESOURCE_SIZE_IN_BYTES) {
      throw new Error('The file is too large');
    }
    // Construct the ResourceData
    const rd: ResourceData = {
      key: key,
      mimeType: blob.type,
      size: blob.size,
      url: URL.createObjectURL(blob),
    };
    this.keys.push(rd);

    const updateProgress = (progress: number | undefined) => {
      rd.progress = progress;
      if (onUpdateProgress) {
        onUpdateProgress(this);
      }
    };

    const resp = await uploadFile(uploadUrl, blob, blob.type, updateProgress);
    const respRD = resp.keys.find((e: ResourceData) => e.key === key);
    // Update the object in-place
    Object.assign(rd, respRD);

    // Upload is totally done, so clear the progress indicator
    updateProgress(undefined);

    return rd;
  }

  /**
   * Upload to a resource key
   * @param key Resource key
   * @param buffer Data to upload
   * @param mimeType MIME type of the data
   * @param onUpdate
   */
  async uploadDataBuffer(
    key: string,
    buffer: ArrayBuffer,
    mimeType: string,
    onUpdate?: (res: Resource) => any
  ): Promise<ResourceData> {
    const blob = new Blob([buffer], { type: mimeType });
    return this.uploadDataBlob(key, blob, onUpdate);
  }

  /**
   * Return ResourceData for a given key
   * @param key Optional key (defaults to the root key)
   */
  getData(key: string): ResourceData | undefined {
    return this.keys.find((e) => e.key === key);
  }

  getDataForHash(hash: string): ResourceData | undefined {
    if (hash.startsWith('sha256:')) {
      hash = hash.split(':')[1];
    }
    return this.keys.find((e) => e.hash === hash);
  }

  /**
   * Return the URL for a resource + key
   * @param key Optional key (defaults to the root key)
   * @param method Optional method (defaults to get)
   */
  getDataUrl(key: string, method: 'get' | 'post' | 'put' = 'get'): string {
    const data = this.getData(key);
    if (data && data.url) {
      // If there's an url (like a blob url), return it
      return data.url;
    } else if (data && data.hash && method.toLowerCase() === 'get') {
      return getResourceUrl(this.id, `sha256:${data.hash}`, this.secret);
    } else {
      return getResourceUrl(this.id, key, this.secret);
    }
  }

  /**
   * Get the mime type for a specific key
   * @param key Optional key (defaults to the root key)
   */
  getDataMimeType(key: string): string | undefined {
    const data = this.getData(key);
    if (data) {
      return data.mimeType;
    } else {
      return undefined;
    }
  }

  /**
   * Get the progress for a specific key
   * @param key Optional key (defaults to the root key)
   */
  getProgress(key: string): number | undefined {
    const data = this.getData(key);
    if (data) {
      return data.progress;
    } else {
      return undefined;
    }
  }

  /**
   * Delete the resource
   * @param ids
   */
  async remove() {
    await apiRequest(`resources/${this.id}`, {
      method: 'DELETE',
    });
  }

  /**
   * Clone a resource into a newly created resource
   */
  async clone(): Promise<Resource> {
    const data = {};
    const ret = await apiRequest(`resources/${this.id}/clone`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resourceJson: ResourceJson = await ret.json();
    return Resource.fromJSON(resourceJson);
  }

  /**
   * Clone from another resource
   * Takes the source resource (optionally just one key) and clones it
   * to this resource under targetKey
   */
  async cloneFrom(targetKey: string, srcId: string, srcKey?: string): Promise<Resource> {
    const data = {
      clone: {
        id: srcId,
        key: srcKey,
      },
    };
    const ret = await apiRequestWithRetry(`resources/${this.id}/data/${targetKey}`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
    const resourceJson: ResourceJson = await ret.json();
    return Resource.fromJSON(resourceJson, this, true);
  }

  async setPermissions(perms: ResourcePermissions): Promise<void> {
    const ret = await apiRequest(`resources/${this.id}/permissions`, {
      method: 'PUT',
      body: JSON.stringify(perms),
    });
    const resourceJson: ResourceJson = await ret.json();
    Resource.fromJSON(resourceJson, this);
  }
}

///////////////////////////////////////////////////////////
/**
 * Given a DataTransfer pull out an array of Files.
 * @param dataTransfer
 */
export function getDataTransferFiles(dataTransfer: DataTransfer): File[] {
  const files: File[] = [];
  if (dataTransfer.items) {
    const items = dataTransfer.items;
    for (let i = 0; i < items.length; i++) {
      // If dropped items aren't files, reject them
      if (items[i].kind === 'file') {
        const file = items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }
  } else {
    for (let i = 0; i < dataTransfer.files.length; i++) {
      files.push(dataTransfer.files[i]);
    }
  }
  return files;
}

/**
 * @param mimeType: string
 */
export function validateLibraryMimeType(mimeType: string) {
  // TODO: Comprehensive list of valid mimeTypes
  if (
    mimeType.startsWith('image/') ||
    mimeType.startsWith('audio/') ||
    mimeType.startsWith('video/')
  ) {
    return true;
  }
  return false;
}

/**
 * Fetch a URL as a blob, possibly using a CORS proxy
 * @param url URL to fetch
 */
export async function fetchUrlData(url: string): Promise<Blob> {
  let response;
  try {
    response = await fetch(url);
  } catch (err) {
    // Maybe a Cross Origin problem? Try with our CORS proxy.
    response = await fetch(`/cors?url=${url}`);
  }
  return response.blob();
}

/**
 * Create a thumbnail
 * @param url
 * @param arrayBuffer
 * @param mimeType
 */
export async function createResourceThumbnail(
  url: string,
  arrayBuffer: ArrayBuffer,
  mimeType: string
): Promise<Blob | undefined> {
  let blob;
  try {
    if (mimeType.startsWith('image/')) {
      blob = await createImageThumbnail(url, mimeType);
    }
    if (mimeType.startsWith('audio/')) {
      blob = await createAudioThumbnail(arrayBuffer.slice(0));
    }
    if (mimeType.startsWith('video/')) {
      blob = await createVideoThumbnail(url);
    }
  } catch (error) {
    // TODO: Track or log these errors.
    // Thumbnails aren't technically required so don't kill the whole process.
    console.error(error);
  }
  return blob;
}

export async function createVideoThumbnail(videoUrl: string): Promise<Blob> {
  const videoThumbnail = new VideoThumbnail(videoUrl);
  return videoThumbnail.getThumbnail({ quality: 1, scale: 1, start: 1 });
}

export async function createAudioThumbnail(audioArrayBuffer: ArrayBuffer): Promise<Blob> {
  const audioWaveform = new AudioWaveform(audioArrayBuffer);
  return audioWaveform.getThumbnail({
    width: 150,
    height: 125,
    channel: 0,
    canvasWidth: 150,
    canvasHeight: 125,
    fillStyle: '#a9a9a9',
    waveStrokeStyle: '#fff',
  });
}

export async function createImageThumbnail(imageUrl: string, mimeType: string): Promise<Blob> {
  const imageThumbnail = new ImageThumbnail(imageUrl);
  return imageThumbnail.getThumbnail({ width: 150, quality: 1, mimeType });
}
