import { Tooltip, TooltipProps } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';
import React, { AnchorHTMLAttributes, FunctionComponent } from 'react';

type MarkdownTooltipProps = Omit<TooltipProps, 'children'> & {
  arrow?: boolean;
  title: string;
  children?: React.ReactNode;
};

export function MarkdownTooltip(props: MarkdownTooltipProps) {
  const { arrow, title, children, placement = 'left', ...tooltipProps } = props;

  return (
    <Tooltip
      arrow={arrow}
      title={
        <Markdown options={{ overrides: { a: { component: BlankTargetAnchor } } }}>
          {title}
        </Markdown>
      }
      placement={placement}
      interactive
      {...tooltipProps}
    >
      <span>{children}</span>
    </Tooltip>
  );
}

type BlankTargetAnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export const BlankTargetAnchor: FunctionComponent<BlankTargetAnchorProps> = ({
  children,
  ...props
}) => {
  return (
    <a style={{ color: 'lightblue' }} target='_blank' {...props}>
      {children}
    </a>
  );
};
