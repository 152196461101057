import type { Component } from './component';
import type { IProject, IProjectModel, IWorkbench } from './designRuntime';
import type { Properties } from './reactor';
import type { PropertyEditorProps, PropertyEditorType } from '.';

// Pre-defined property types.
export type PropertyType = 'boolean' | 'string' | 'number' | 'date' | 'array' | 'rectangle'; // TODO: object, regex, function

export type FunctionComponent<T = any> = React.FC<T>;
export type ComponentType<T = any> = React.ComponentType<T>;

// TODO:
export enum Designer {}

export type DesignerProps = {
  component: Component; // TODO: ViewComponent?
};

export type RootDesignerProps = {
  workbench: IWorkbench;
  projectModel: IProjectModel;
  project: IProject;
  viewportRect: DOMRect;
  playing: boolean; // TODO: playing should be designer-internal thing
  zoomTo?: number;
  setZoom: (zoom: number) => void;
} & DesignerProps;

/**
 * KitDescription describes a Kit (set) of Play Components. Kit Descriptions may be
 * defined in code or in .json files so all types must be JSON compatbile.
 */
export type KitDescription = {
  // Human readable title of the kit.
  title?: string;

  // Human readable description of the kit. TODO: markdown?
  description?: string;

  author?: string; // TODO: userId or unique username?

  // The list of components in the kit.
  components?: ComponentDescription[];
};

/**
 * ComponentMetaData describes the core interaction points contained both
 * in ComponentDescriptions and within component instances themselves.
 */
export type ComponentMetaData = {
  // Human readable title of the component.
  title?: string;

  // Human readable description of the component. TODO: markdown?
  description?: string;

  // Determines whether a component shows up in the components list
  exported?: boolean;

  // If set to false, a generated preview will be used
  customPreview?: boolean;

  author?: string; // TODO: userId or unique username?
  icon?: string;
  preview?: string;
  collection?: string; // TODO: necessary?

  // The set of properties this component has, keyed by property name.
  properties?: { [property: string]: PropertyDescription };

  // The set of events this component can fire, keyed by event name.
  events?: { [event: string]: EventDescription };

  commands?: { [command: string]: CommandDescription };

  actions?: { [action: string]: ActionDescription };

  // Tells the designer that this component supports going into `componentDesignMode` which allows
  // the component to be interacted with directly in the designer when a user doubleClicks/taps on a component.
  supportComponentDesignMode?: boolean;
};

/**
 * ComponentDescription describes a Play Component. Everything users, the design time environment,
 * and the runtime environment need to be able to make use of the component. ComponentDescriptions
 * may be defined in code or in .json files so all types must be JSON compatbile.
 */
export type ComponentDescription = {
  // Computer readable name of the component.
  name: string;

  // Component metadata
  _meta: ComponentMetaData;

  // A component that this component extends (inherits ComponentDescription and prototype from).
  extends?: string;

  // Reference to a React component that renders this component.
  renderer?: React.FC<any>; // TODO: React.ComponentType?

  // Reference to the component that provides the Inspector UI for this component.
  // It may be a pre-defined PropertyEditor or a React Component conforming to the ReactInspector interface.
  inspector?: PropertyEditorType | { type: PropertyEditorType; category?: string } | ComponentType;

  // Reference to the component that provides the Designer UI for this component.
  // It may be a pre-defined Designer or a React Component conforming to the ReactDesigner interface.
  designer?: ComponentType<DesignerProps>;

  // Reference to the component's Javascript prototype. Prototypes may provide
  // properties, methods, and events.
  prototype?: any;
};

/**
 * PropertyDescription describes a property of a Play Component. Everything users,
 * the design time environment, and the runtime environment need to make use of the
 * property. PropertyDescriptions may be defined in code or in .json files so all
 * types must be JSON compatbile.
 */
export type PropertyDescription = {
  /**
   * One of the pre-defined property types.
   * The PropertyType describes the kind of data the property tracks.
   */
  type: PropertyType;

  // Human readable title of the property.
  title?: string;

  // Human readable description of the property. TODO: markdown?
  description?: string;

  tip?: string; // TODO: ?

  // The default value of the property.
  default?: any;

  // Is this property visible in the Inspector UI?
  hidden?: boolean | ((properties: Properties) => boolean);

  // This property should not be edited.
  readonly?: boolean;

  // This property should not force re-rendering.
  noRender?: boolean;

  // Reference to the property editor the Inspector should display for this property.
  // May reference a pre-defined property editor or a custom React component conforming
  // to the ReactPropertyEditor interface.
  editor?: PropertyEditorType | PropertyEditorProps;

  // Used by Composite to create two-way bindings to wrapped component properties.
  // E.g. "button1.text".
  binding?: string;

  // When true this is the component's "primary" property used when a default is needed.
  primary?: boolean;
};

/**
 * EventDescription describes an event that a Play Component can fire. Everything users,
 * the design time environment, and the runtime environment need to establish event
 * handlers. EventDescriptions may be defined in code or in .json files so all types
 * must be JSON compatible.
 */
export type EventDescription = {
  // Human readable title of the property.
  title?: string;

  // Human readable description of the property. TODO: markdown?
  description?: string;

  tip?: string; // TODO: ?

  // Is this event visible in the Inspector UI?
  hidden?: boolean | ((properties: Properties) => boolean);

  // Used by Composite to create way bindings to wrapped component events.
  // E.g. "button1.click".
  binding?: string;

  // When true this is the component's "primary" event used when a default is needed.
  primary?: boolean;
};

export type CommandDescription = {
  // Human readable title of the command.
  title?: string;

  // Human readable description of the command.
  description?: string;

  // Is this a command that wants to participate in the undo stack during design time?
  undoable?: boolean;
};

export type ActionDescription = {
  // Human readable title of the action.
  title?: string;

  // Human readable description of the action.
  description?: string;

  template?: string;

  parameters?: {
    [parameter: string]: ParameterDescription;
  };

  returnValue?: ParameterDescription;

  // When true this is the component's "primary" action used when a default is needed.
  primary?: boolean;
};

export type ParameterType =
  | 'any'
  | 'boolean'
  | 'string'
  | 'number'
  | 'date'
  | 'array'
  | 'object'
  | 'color'
  | 'property'
  | 'property-value'
  | 'animation'
  | 'longstring'
  | 'actions';

export type ParameterDescription = {
  // Human readable title of the parameter.
  title?: string;

  // Human readable description of the parameter.
  description?: string;

  // Type of the parameter.
  type?: ParameterType;

  // Default value of the parameter.
  default?: any;

  // Is the parameter optional? (default: false)
  optional?: boolean;
};
